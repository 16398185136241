import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from "react-router-dom";
import { Grid } from '@material-ui/core';
import { API_URL } from '../constants';
import { useTranslation } from 'react-i18next';
import LoggedInTopMenu from '../components/LoggedInTopMenu';
import SideMenu from '../components/SideMenu';
import Hidden from '@material-ui/core/Hidden';
import axios from 'axios';
import moment from 'moment';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import 'react-toastify/dist/ReactToastify.css';
import TextField from '@material-ui/core/TextField';
import { useHistory, useParams } from "react-router-dom";

import AddIcon from '@material-ui/icons/Add';
import DataTable from 'react-data-table-component';

import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import RefreshIcon from '@material-ui/icons/Refresh';
import ReceiptIcon from '@material-ui/icons/Receipt';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/FilterList';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from 'react-select';

import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import { ToastContainer, toast } from 'react-toastify';
import { withStyles } from '@material-ui/core/styles';
import 'react-toastify/dist/ReactToastify.css';
import LZString from 'lz-string';

const IOSSwitch = withStyles((theme) => ({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: '#52d869',
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#52d869',
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  }))(({ classes, ...props }) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    );
  });

const useStyles = makeStyles(theme => ({
    content: {
        paddingTop: 130,
        marginLeft: '-6%'
    },
    sideMenu: {
        backgroundColor: '#9F0000',
        width: 256,
        height: `calc(100vh - 130px)`,
        borderTopRightRadius: 75,
        color: '#fff',
        paddingTop: 70
    },
    mainContent: {
        width: `calc(100% - 256px)`,
        textAlign: 'left',
        ['@media (max-width:959)']: { 
          width: '100%',
          paddingLeft: 0,
          paddingRight: 0
        },
        ['@media (max-width:768px)']: { 
            width: '100%',
            textAlign: 'center',
            marginRight: 0
        },
    },
    article: {
        paddingTop: 20,
        paddingLeft: 50
    },
    activePageHeader: {
        fontSize: 20,
        fontWeight: 'bold',
        paddingRight: 40,
        color: '#000',
        textDecoration: 'none',
    },
    pageHeader: {
        fontSize: 20,
        fontWeight: 'bold',
        textTransform: 'uppercase',
        marginBottom: 20
    },
    statementHeader: {
        paddingTop: 20,
        borderBottomWidth: 1,
        borderBottomColor: '#d3d3d3',
        borderBottomStyle: 'solid'
    },
    indentedHeader: {
        color: '#000',
        opacity: 0.25,
        fontSize: 16,
        textAlign: 'left',
        paddingLeft: '3%',
        ['@media (max-width:959px)']: { 
          paddingLeft: 0
        },
        ['@media (min-width:959px)']: { 
          visibility: 'hidden'
        }
    },
    leftHeader: {
        color: '#000',
        opacity: 0.25,
        fontSize: 16,
        textAlign: 'left',
        ['@media (min-width:959px)']: { 
          visibility: 'hidden'
        }
    },
    header: {
        color: '#000',
        opacity: 0.25,
        fontSize: 16,
        textAlign: 'left',
    },
    history: {
        width: '100%'
    },
    left: {
        textAlign: 'left',
        height: 80,
        borderBottomWidth: 1,
        borderBottomColor: '#d3d3d3',
        borderBottomStyle: 'solid'
    },
    center: {
        textAlign: 'center',
        height: 80,
        borderBottomWidth: 1,
        borderBottomColor: '#d3d3d3',
        borderBottomStyle: 'solid',
        fontSize: 14
    },
    saveContainer: {
        marginLeft: 65,
        marginTop: 10
    },
    saveText: {
          color: '#9F0000',
          fontSize: 16,
          fontWeight: 500,
          display: 'inline-block',
          position: 'absolute',
          marginTop: 7,
          marginLeft: -62,
          width: 44
    },
    saveBtnContainer: {
        width: 36,
        height: 36,
        borderRadius: 12,
        backgroundColor: '#9F0000',
        display: 'inline-block',
        "&:hover": {
          cursor: 'pointer'
        },
    },
    saveBtn: {
        width: 21,
        height: 21,
        paddingLeft: 3,
        position: 'absolute',
        marginLeft: 7,
        marginTop: 6
    },
    filterContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginBottom: 20,
        // width: '75%',
        justifyContent: 'space-between',
        ['@media (max-width:959px)']: { 
            width: '100%',
        }
    },
    exportContainer: {
        paddingTop: 1,
        backgroundColor: '#F7F7F7',
        marginTop: 11,
        borderRadius: 8,
        border: `solid 1px #d3d3d3`,
        marginBottom: 10,
        paddingLeft: 10,
        paddingRight: 10
    },
    input: {
        width: 280,
        fontSize: 16
    },
    viewDetails: {
        fontSize: 12,
        color: '#f1a542',
        border: 'solid 1px #f1a542',
        fontWeight: 'bold',
        width: 80,
        margin: '0 auto',
        borderRadius: 10,
        "&:hover": {
            cursor: "pointer"
        },
        ['@media (max-width:768px)']: { 
          width: 'initial',
          textAlign: 'center'
        }
    },
    addManualRating: {
        fontSize: 12,
        color: '#9f0000',
        border: 'solid 1px #9f0000',
        fontWeight: 'bold',
        width: 145,
        margin: '0 auto',
        marginTop: 10,
        borderRadius: 10,
        "&:hover": {
            cursor: "pointer"
        },
        ['@media (max-width:768px)']: { 
          width: 'initial',
          textAlign: 'center'
        }
    },
    titleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 7
    },
    dialogTitle: {
        fontSize: 20,
        fontWeight: 'bold'
    },
    close: {
        fontSize: 24,
        "&:hover": {
            cursor: "pointer"
        }
    },
    dialogContent: {
        width: 480,
        marginTop: 0,
        ['@media (max-width:959px)']: { 
            width: '100%'
        }
    },
    dialogUserContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        width: '70%',
        marginLeft: 'auto',
        marginRight: 'auto',
        ['@media (max-width:959px)']: { 
            width: '100%'
        }
    },
    dialogUserImage: {
        width: 80,
        height: 80,
        borderRadius: 18,
        objectFit: 'cover',
        marginBottom: 25
    },
    dialogCarImage: {
        width: 80,
        height: 80,
        borderRadius: 18,
        objectFit: 'cover',
        marginBottom: 25
    },
    dialogUser: {
        textAlign: 'center'
    },
    dialogCar: {
        textAlign: 'center'
    },
    dialogFlexContainer: {
        display: 'flex'
    },
    dialogRow: {
        marginTop: 20
    },
    modalIconImage: {
        width: 36,
        height: 36
    },
    dialogRightText: {
        marginLeft: 20,
        paddingTop: 6,
        marginLeft: 20,
        paddingTop: 6,
        width: '85%'
    },
    dataRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    leftColumn: {
        flex: '30%',
    },
    rightColumn: {
        width: '70%',
    },
    dialogFlexEndContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    dialogRight: {
        position: 'absolute',
        right: 64,
        fontWeight: 'bold'
    },
    dialogRightLast: {
        position: 'absolute',
        right: 64,
        marginTop: 25,
        fontWeight: 'bold'
    },
    dialogBottomRow: {
        marginTop: 25
    },
    mapContainer: {
        height: 319, 
        width: '100%', 
        borderRadius: 40,
        marginTop: 20
    },
    mapIcon: {
        color: '#9F0000',
        marginBottom: -5,
        marginRight: 5,
        marginLeft: 5
    },
    whatsappIcon: {
        color: '#075e54',
        marginBottom: -5,
        marginRight: 5,
        marginLeft: 5
    },
    emailIcon: {
        color: '#9F0000',
        marginBottom: -5,
        marginRight: 5,
        marginLeft: 5
    },
    close: {
        fontSize: 24,
        "&:hover": {
            cursor: "pointer"
        }
    },
    textCenter: {
        textAlign: 'center'
    },
    sectionTitleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 40,
        marginBottom: 20
    },
    serviceImage: {
        width: '100%',
        height: 250,
        marginBottom: 20,
        borderRadius: 10,
        objectFit: 'cover'
    },
    selectRating: {
        width: 315,
        ['@media (max-width:959px)']: { 
            width: 310,
            marginBottom: 20
        }
    },
    addService: {
        textDecoration: 'none',
        color: '#9F0000'
    },
    addIcon: {
        position: 'absolute'
    },
    addServiceText: {
        marginLeft: 30,
    },
    filterContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 15,
        marginBottom: 20,
        // width: '30%',
        justifyContent: 'space-between',
        ['@media (max-width:959px)']: { 
            width: '100%',
        }
    },
    input: {
        width: 280,
        fontSize: 16
    },
    serviceLink: {
        color: '#9F0000',
        "&:hover": {
            cursor: "pointer"
        },
    },
    icon: {
        "&:hover": {
            cursor: "pointer"
        },
    },
    cancelled: {
        color: '#fff',
        fontSize: 9,
        paddingTop: 5,
        paddingLeft: 10,
        borderRadius: 18,
        paddingRight: 10,
        paddingBottom: 5,
        backgroundColor: '#9F0000',
        marginTop: 5,
    },
    paymentSource: {
        marginTop: 5,
        textTransform: 'uppercase',
        fontSize: 10,
        color: '#9f0000',
        borderColor: '#9f0000',
        borderWidth: 1,
        borderRadius: 12,
        borderStyle: 'solid',
        paddingTop: 2,
        paddingBottom: 2,
        paddingLeft: 10,
        paddingRight: 10,
    }
}));

const Bookings = () => {
    const classes = useStyles();
    const userId = localStorage.getItem('userId');
    const roleId = localStorage.getItem('roleId');
    const history = useHistory();
    const { t, i18n } = useTranslation();
    let [language, setLanguage] = useState('');
    let [searchKeyword, setSearchKeyword] = useState('');
    let [columns, setColumns] = useState([]);
    let [bookings, setBookings] = useState([]);
    let [allBookings, setAllBookings] = useState([]);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [openconfirm, setOpenconfirm] = React.useState(false);
    let [selectedbooking, setSelectedbooking] = useState([]);
    let [buildings, setBuildings] = useState([]);
    let [selectedBuilding, setSelectedBuilding] = useState(null);
    let [selectedBuildingObject, setSelectedBuildingObject] = useState(null);
    let [paymentStatus, setPaymentStatus] = useState([]);
    let [selectedPaymentStatus, setSelectedPaymentStatus] = useState(null);
    let [selectedPaymentStatusObject, setSelectedPaymentStatusObject] = useState(null);
    let [activeFilter, setActiveFilter] = useState(true);
    const [open, setOpen] = React.useState(false);
    let [paidOptionValues, setPaidOptionValues] = useState([]);
    let [paidOption, setPaidOption] = useState({});
    let [selectedPaidOption, setSelectedPaidOption] = useState('');
    let [processing, setProcessing] = useState(false);
    const [openDelete, setOpenDelete] = React.useState(false);
    let [packageStatus, setPackageStatus] = useState([]);
    let [selectedPackageStatus, setSelectedPackageStatus] = useState(null);
    let [selectedPackageStatusObject, setSelectedPackageStatusObject] = useState(null);
    const [openResume, setOpenResume] = React.useState(false);
    const [openPaymentReceived, setOpenPaymentReceived] = React.useState(false);

    const CustomTitle = ({ row }) => (
        <div>
            {}
            <div>
                <div
                    data-tag="allowRowEvents"
                    style={{ color: 'grey', overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses', display: 'flex', flexWrap: 'wrap', flexDirection: 'column' }}
                >
                    <a className={classes.serviceLink} style={{fontSize:12}} target="_blank" onClick={() => editBooking(row)}>{row.transaction_no}</a>
                    {row?.invoice_no ? (<Tooltip title="View invoice">
                            <ReceiptIcon
                                className={classes.icon}
                                style={{
                                    color: '#9f0000',
                                    fontSize: 16,
                                }}
                                onClick={() => viewInvoice(row)}
                            />
                        </Tooltip>) : null
                    }
                    {row.cancelled &&
                        <div style={{textTransform:'uppercase',fontWeight:'bold',opacity:0.75,fontSize:10,marginTop:5}}>{t('booking.cancelled')}</div>
                    }
                    {row.paused &&
                        <div style={{textTransform:'uppercase',fontWeight:'bold',opacity:0.75,fontSize:10,marginTop:5}}>{t('booking.paused')}</div>
                    }
                </div>
            </div>
        </div>
    );

    const CustomAction = ({ row }) => (
        <div>
            {}
            <div>
                <div
                    data-tag="allowRowEvents"
                    style={{ color: 'grey', overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
                >
                    <div>
                        {!row.cancelled &&
                            <>
                                <Tooltip title="Edit booking">
                                    <EditIcon 
                                        className={classes.icon}
                                        style={{
                                            color: '#9f0000',
                                            fontSize: 16,
                                        }}
                                        onClick={() => editBooking(row)}
                                    />
                                </Tooltip>
                                {row.paused == 1 ?
                                    (<>
                                        <Tooltip title="Resume">
                                            <PlayArrowIcon 
                                                className={classes.icon}
                                                style={{
                                                    color: '#9f0000',
                                                    fontSize: 16,
                                                    marginLeft: 10,
                                                }}
                                                onClick={() => confirmResume(row)}
                                            />
                                        </Tooltip>
                                    </>) : (
                                        <>
                                            <Tooltip title="Pause">
                                                <PauseIcon 
                                                    className={classes.icon}
                                                    style={{
                                                        color: '#9f0000',
                                                        fontSize: 15,
                                                        marginLeft: 10,
                                                    }}
                                                    onClick={() => handlePause(row)}
                                                />
                                            </Tooltip>
                                        </>
                                    )
                                }
                                {(row?.payment_method_id == 1 && row?.payment_received != 1) &&
                                    <Tooltip title="Payment Received">
                                        <CheckBoxIcon 
                                            className={classes.icon}
                                            style={{
                                                color: '#9f0000',
                                                fontSize: 15,
                                                marginLeft: 10,
                                            }}
                                            onClick={() => handleMarkPaymentReceived(row)}
                                        />
                                    </Tooltip>
                                }
                                <Tooltip title="Renew booking">
                                    <RefreshIcon
                                        className={classes.icon}
                                        style={{
                                            color: '#9f0000',
                                            fontSize: 16,
                                            marginLeft: 10,
                                        }}
                                        onClick={() => renewBooking(row)}
                                    />
                                </Tooltip>
                                <Tooltip title="Cancel booking">
                                    <CancelRoundedIcon 
                                        className={classes.icon}
                                        style={{
                                            color: '#9f0000',
                                            fontSize: 16,
                                            marginLeft: 10,
                                            marginRight: 10,
                                        }}
                                        onClick={() => handleCancelBooking(row)}
                                    />
                                </Tooltip>
                            </>
                        }
                         <Tooltip title="Delete booking">
                            <DeleteIcon 
                                className={classes.icon}
                                style={{
                                    color: '#9f0000',
                                    fontSize: 16,
                                }}
                                onClick={() => confirmDelete(row)}
                            />
                        </Tooltip>
                    </div>
                </div>
            </div>
        </div>
    );

    const CustomBuilding = ({ row }) => (
        <div>
            {}
            <div>
                <div
                    data-tag="allowRowEvents"
                    style={{ overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses', display: 'flex', flexWrap: 'wrap', flexDirection: 'column' }}
                >
                    <div>{language == 'ar' ? row.property_name_ar : row.property_name}</div>
                    <div>{row.flat_number}</div>
                    <div>{row.plate_number}</div>
                </div>
            </div>
        </div>
    );

    const CustomService = ({ row }) => (
        <div>
            {}
            <div>
                <div
                    data-tag="allowRowEvents"
                    style={{ overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses', display: 'flex', flexWrap: 'wrap', flexDirection: 'column' }}
                >
                    <div>{language == 'ar' ? row.name_ar : row.name}</div>
                    <div>{row?.car_type}</div>
                    <div>{'BHD ' + row.amount}</div>
                    <div style={{color: 'grey', display: 'flex', flexDirection: 'row'}}>
                        <div>{getPaidStatus(row)}</div>
                    </div>
                </div>
            </div>
        </div>
    );

    const CustomAutoRenew = ({ row }) => (
        <div>
            {}
            <div>
                <div
                    data-tag="allowRowEvents"
                    style={{ overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses', display: 'flex', flexWrap: 'wrap', flexDirection: 'column', paddingLeft: 3 }}
                >
                    <FormGroup>
                        <FormControlLabel
                            control={<IOSSwitch checked={row?.auto_renew} onChange={(e) => handleAutoRenewChange(e, row)} name="autoRenew" />}
                        />
                    </FormGroup>
                </div>
            </div>
        </div>
    );

    useEffect(() => {
        const language = localStorage.getItem('language');
        if(language) {
            i18n.changeLanguage(language);
        }
        setLanguage(language);

        getBookings();
        let table_columns = [
            {
                name: t('booking.transaction'),
                selector: row => row.transaction_no,
                sortable: true,
                cell: row => <CustomTitle row={row} />,
            },
            {
                name: t('history.date'),
                selector: row => getBookingDate(row),
                sortable: true,
            },
            {
                name: t('statement.customer'),
                selector: row => (row.customer_first_name + ' ' + row.customer_last_name),
                sortable: true,
            },
            {
                name: t('services.building'),
                selector: row => row.property_name,
                sortable: true,
                cell: row => <CustomBuilding row={row} />,
            },
            {
                name: t('packages.package'),
                selector: row => (language == 'ar' ? row.name_ar : row.name),
                sortable: true,
                cell: row => <CustomService row={row} />,
            },
            {
                name: t('booking.assignedstaff'),
                selector: row => (row.staff_first_name + ' ' + row.staff_last_name),
                sortable: true,
            },
            {
                name: t('packages.autorenew'),
                cell: row => <CustomAutoRenew row={row} />,
                sortable: true,
            },
            {
                name: t('statement.action'),
                selector: row => row,
                sortable: true,
                cell: row => <CustomAction row={row} />,
                width: '200px'
            },
        ];
        setColumns(table_columns);

        let payment_status = [
            {
                value: '',
                label: t('metrics.all')
            },
            {
                value: 'Paid',
                label: t('addbooking.paid')
            },
            {
                value: 'Not paid',
                label: t('addbooking.notpaid')
            }
        ]
        setPaymentStatus(payment_status);

        let package_status = [
            {
                value: '',
                label: t('metrics.all')
            },
            {
                value: 'Active',
                label: t('dashboard.active')
            },
            {
                value: 'Expiring Soon',
                label: t('addbooking.expiringsoon')
            },
            {
                value: 'Expired/Completed',
                label: t('addbooking.expiredcompleted')
            },
            {
                value: 'Paused',
                label: t('booking.paused')
            }
        ]
        setPackageStatus(package_status);

        let paidOptionValues = [
            {
                'type_en': 'Cash',
                'type_ar': 'نقدي',
                'type_value': 'cash'
            },
            {
                'type_en': 'Credit Card',
                'type_ar': 'بطاقة إئتمان',
                'type_value': 'creditcard'
            },
            {
                'type_en': 'Bank transfer',
                'type_ar': 'التحويل المصرفي',
                'type_value': 'banktransfer'
            }
        ]
        setPaidOptionValues(paidOptionValues);
    }, [])

    if (!userId) {
        return <Redirect to={'/login'} />;
    }

    const getPaidStatus = (row) => {
        let text = ''
        let payment = language == 'ar' ? row.payment_method_name_ar : row.payment_method_name
        if(
            (payment?.toLowerCase()?.indexOf('card') >= 0 && row.tap_charge_id) ||
            (row?.paid_option?.toLowerCase()?.indexOf('cash') >=  0 || 
                row?.paid_option?.toLowerCase()?.indexOf('card') >= 0 || 
                row?.paid_option?.toLowerCase()?.indexOf('bank') >= 0 || 
                row?.paid_option?.toLowerCase()?.indexOf('apple') >= 0 ||
                row?.paid_option?.toLowerCase()?.indexOf('google') >= 0
            ) ||
            row?.paid == 1 || 
            ((row?.payment_method_id == 1 || row?.payment_method_id == 6) && row?.payment_received == 1) ||
            (row?.payment_method_id > 0 && row.tap_charge_id && (row?.payment_method_id != 1 && row?.payment_method_id != 6))
        ) {
            text = t('addbooking.paid') + ' - ' + (row?.payment_method_name?.replace('(Benefit, Visa, MasterCard)', '') || row?.paid_option)
        } else {
            text = t('addbooking.notpaid') + (payment ? (' - ' + payment) : '')
        }
        return text
    }

    const getBookingDate = (row) => {
        let start_date = row?.schedules?.length > 0 ? row?.schedules[0].booking_date : ''
        let end_date = row?.schedules?.length > 1 ? row?.schedules[row?.schedules?.length - 1].booking_date : ''
        return `${start_date ? moment(start_date).format("MM/DD") : ''} ${end_date ? (' - ' + (end_date ? moment(end_date).format("MM/DD") : '')) : ''}` 
    }

    const viewInvoice = (row) => {
        if(row?.invoice_no) {
            setTimeout(function () {
                window.open(
                    `${API_URL}/invoice/${row.invoice_no}.pdf`,
                    '_blank'
                );
            }, 2000);
        } else {
            axios.get(API_URL + `/invoice/generate/${row.booking_id}`)
            .then(response => {
                setTimeout(function () {
                    window.open(
                        `${API_URL}/invoice/${row.transaction_no}.pdf`,
                        '_blank'
                    );
                }, 2000);
            })
        }
    }

    const handleMarkAsPaid = (row) => {
        setSelectedbooking(row);
        handleClickOpen();
    }

    const handleMarkPaymentReceived = (row) => {
        setSelectedbooking(row);
        setOpenPaymentReceived(true);
    }

    const handleClosePaymentReceived = () => {
        setOpenPaymentReceived(false);
    };

    const handlePaymentReceived = () => {
        let payment_method_id = selectedbooking.payment_method_id;
        let payment_received = 1;
        let paidOption = selectedbooking.paid_option;

        let params = {
            booking_id: selectedbooking.booking_id,
            paid_option: paidOption,
            payment_method_id,
            payment_received,
        }
        axios.post(API_URL + `/booking-payment-status/edit`, params)
        .then(res => {
            if(
                (payment_method_id > 0 && payment_method_id != 1 && payment_method_id != 6) ||
                (payment_received == 1 && (payment_method_id == 1 || payment_method_id == 6))
            ) {
                setProcessing(true);
                let payment_option_value = language == 'ar' ? paidOption?.type_ar : paidOption?.type_en
                let payload = {
                    booking_id: selectedbooking.booking_id,
                    property_id: selectedbooking.property_id,
                    property_name: selectedbooking.property_name,
                    invoice_id: selectedbooking.zoho_invoice_id,
                    invoice_number: selectedbooking.invoice_no,
                    payment_mode: paidOption?.type_value?.replace('_', ''),
                    payment_mode_value: payment_option_value,
                    zoho_customer_id: selectedbooking.zoho_customer_id,
                    amount: selectedbooking.amount,
                    customer_first_name: selectedbooking?.customer?.first_name,
                    customer_last_name: selectedbooking?.customer?.last_name,
                    customer_email: selectedbooking?.customer?.email,
                    customer_phone_number: selectedbooking?.customer?.phone_number,
                    staff_first_name: selectedbooking.staff_first_name,
                    staff_last_name: selectedbooking.staff_last_name,
                    payment_received: (payment_received == 1 && (payment_method_id == 1 || payment_method_id == 6)) ? payment_received : null,
                    payment_method_id,
                }
                console.log(payload)
                axios.post(API_URL + `/invoice/zoho/mark-paid`, payload)
                .then(res => {
                    setProcessing(false);
                    toast.success(t("users.successsave"), {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setOpenPaymentReceived(false);
                    window.location.reload(true);
                })
            } else {
                setOpenPaymentReceived(false);
                window.location.reload(true);
            }
        })
    };

    const handlePaidOptionChange = (event) => {
        if(paidOptionValues) {
            paidOptionValues.forEach(p => {
                if(p.type_value == event.target.value) {
                    setPaidOption(p)
                    let translation_key = 'addbooking.' + event.target.value.replace('_', '')
                    setSelectedPaidOption(t(translation_key))
                }
            })
        }
    }

    const handlePaid = () => {
        setProcessing(true);
        let payment_option_value = language == 'ar' ? paidOption?.type_ar : paidOption?.type_en
        let payload = {
            booking_id: selectedbooking.booking_id,
            property_id: selectedbooking.property_id,
            property_name: selectedbooking.property_name,
            invoice_id: selectedbooking.zoho_invoice_id,
            invoice_number: selectedbooking.invoice_no,
            payment_mode: paidOption?.type_value?.replace('_', ''),
            payment_mode_value: payment_option_value,
            zoho_customer_id: selectedbooking.zoho_customer_id,
            amount: selectedbooking.amount,
            customer_first_name: selectedbooking.customer_first_name,
            customer_last_name: selectedbooking.customer_last_name,
            customer_email: selectedbooking.customer_email,
            customer_phone_number: selectedbooking.customer_phone_number,
            staff_first_name: selectedbooking.staff_first_name,
            staff_last_name: selectedbooking.staff_last_name,
        }

        axios.post(API_URL + `/invoice/zoho/mark-paid`, payload)
        .then(res => {
            setProcessing(false);
            setOpen(false);
            window.location.reload(true);
        })
    }

    const editBooking = (row) => {
        axios.get(API_URL + `/booking/${row.booking_id}`)
        .then(response => {
            let bkng = response.data.booking;
            if(bkng.booking_items?.length > 0) {
                setTimeout(function () {
                    history.push(`/editbooking/${row.booking_id}/${row.id}`);
                }, 500);
            }
        })
    }

    const renewBooking = (row) => {
        setTimeout(function () {
            history.push(`/renewbooking/${row.id}`);
        }, 500);
    }

    const handlePause = (row) => {
        history.push(`/pause/${row.booking_id}/${row.id}`);
    }

    const confirmResume = (row) => {
        setSelectedbooking(row);
        setOpenResume(true);
    }

    const handleCloseResume = () => {
        setOpenResume(false);
    };

    const handleResume = () => {
        axios.post(API_URL + `/subscription/resume/${selectedbooking.id}`, {})
        .then(res => {
            setOpenResume(false);
            window.location.reload(true);
        })
    };

    const getBookings = () => {
        axios.get(API_URL + `/all-bookings`)
        .then(response => {
            let bkngs = response.data.bookings;
            console.log(bkngs)

            axios.get(API_URL + `/properties`)
            .then(response1 => {
                if(roleId == 5) {
                    axios.get(API_URL + `/manager-properties`)
                    .then(resp => {
                        let bldgs = response1.data.properties;
                        let all_bldgs = bldgs;

                        let manager_properties = resp.data.manager_properties;
                        let filtered_bldgs = []
                        if(all_bldgs?.length > 0) {
                            filtered_bldgs = all_bldgs.filter(mp => {
                                return manager_properties?.some(a => a.property_id == mp.id && a.user_id == userId)
                            })
                        } else {
                            filtered_bldgs = all_bldgs
                        }
                        let bldng = filtered_bldgs;
                        bldng = formatSelectData(bldng, 'properties');
                        
                        let filtered_bookings = []
                        if(bkngs?.length > 0) {
                            filtered_bookings = bkngs.filter(mp => {
                                return manager_properties?.some(a => a.property_id == mp.property_id && a.user_id == userId)
                            })
                        } else {
                            filtered_bookings = bkngs
                        }

                        var compressed = LZString.compressToUTF16(JSON.stringify(filtered_bookings));
                        localStorage.setItem('bookings', compressed)
                        setBookings(filtered_bookings);
                        setAllBookings(filtered_bookings); 
                    })
                } else {
                    let bldng = response1.data.properties;
                    bldng = formatSelectData(bldng, 'properties');
                    var compressed = LZString.compressToUTF16(JSON.stringify(bkngs));
                    localStorage.setItem('bookings', compressed)
                    setBookings(bkngs);
                    setAllBookings(bkngs); 
                }
            })
        })
    }

    const handleBuildingChange = (data) => {
        setSelectedBuildingObject(data);
        setSelectedBuilding(data);

        filterBookings(data, selectedPaymentStatus, selectedPackageStatus);
    };

    const handlePaymentStatusChange = (data) => {
        setSelectedPaymentStatusObject(data);
        setSelectedPaymentStatus(data);

        filterBookings(selectedBuilding, data, selectedPackageStatus);
    };

    const handlePackageStatusChange = (data) => {
        setSelectedPackageStatusObject(data);
        setSelectedPackageStatus(data);

        filterBookings(selectedBuilding, selectedPaymentStatus, data);
    };

    const filterBookings = (selected_building, selected_payment_status, selected_package_status) => {
        handleFilter(searchKeyword, selected_building, selected_payment_status, selected_package_status);
    }

    const handleFieldChange = (event, type) => {
        switch(type) {
            case 'searchKeyword': 
                setSearchKeyword(event.target.value);
                handleFilter(event.target.value, '', '')
                break;
        }
    };

    function handleFilter(search, building, payment_status, package_status) {
        let bkk = allBookings
    
        if(bkk.length > 0) {
            if(search.length > 0) {
                bkk = bkk.filter(s => {
                    let include = false
            
                    if(
                        (s.customer_first_name && search &&
                            s.customer_first_name.toLowerCase().indexOf(search.toLowerCase()) >= 0
                        ) || 
                        (s.customer_last_name && search &&
                            s.customer_last_name.toLowerCase().indexOf(search.toLowerCase()) >= 0
                        ) ||
                        (s.transaction_no && search &&
                            s.transaction_no.toLowerCase().indexOf(search.toLowerCase()) >= 0
                        ) ||
                        (s.package_name && search &&
                            s.package_name.toLowerCase().indexOf(search.toLowerCase()) >= 0
                        ) ||
                        (s.package_name_ar && search &&
                            s.package_name_ar.toLowerCase().indexOf(search.toLowerCase()) >= 0
                        ) ||
                        (s.property_name && search &&
                            s.property_name.toLowerCase().indexOf(search.toLowerCase()) >= 0
                        ) ||
                        (s.property_name_ar && search &&
                            s.property_name_ar.toLowerCase().indexOf(search.toLowerCase()) >= 0
                        ) ||
                        (s.staff_first_name && search &&
                            s.staff_first_name.toLowerCase().indexOf(search.toLowerCase()) >= 0
                        ) ||
                        (s.car_type && search &&
                            s.car_type.toLowerCase().indexOf(search.toLowerCase()) >= 0
                        ) ||
                        (s.payment_method_name && search &&
                            s.payment_method_name.toLowerCase().indexOf(search.toLowerCase()) >= 0
                        ) ||
                        (s.flat_number && search &&
                            s.flat_number.toLowerCase().indexOf(search.toLowerCase()) >= 0
                        ) ||
                        (s.payment_method_name_ar && search &&
                            s.payment_method_name_ar.toLowerCase().indexOf(search.toLowerCase()) >= 0
                        )
                    ) {
                    include = true
                    }
            
                    return include
                })
            }

            if(building) {
                bkk = bkk.filter(s => {
                    return s.property_id == building.value
                })
            }

            if(payment_status?.value) {
                bkk = bkk.filter(row => {
                    let include = false
                    
                    let text = ''
                    let payment = language == 'ar' ? row.payment_method_name_ar : row.payment_method_name
                    if(
                        (payment?.indexOf('Card') >= 0 && row.tap_charge_id) ||
                        (row?.paid_option == 'Cash' || row?.paid_option == 'Card' || row?.paid_option == 'Bank transfer') ||
                        row?.paid == 1 ||
                        ((row?.payment_method_id == 1 || row?.payment_method_id == 6) && row?.payment_received == 1) ||
                        (row?.payment_method_id > 0 && (row?.payment_method_id != 1 && row?.payment_method_id != 6))
                    ) {
                        text = 'Paid'
                    } else {
                        text = 'Not paid'
                    }

                    if(text == payment_status.value) {
                        include = true
                    }

                    return include
                })
            }

            if(package_status?.value) {
                bkk = bkk.filter(row => {
                    let include = false
                    
                    let active
                    let expiring
                    let completed
                    let paused

                    let completed_schedules = row?.schedules?.filter(item => {
                        return item?.status_id == 3
                    })
                    let schedules_count = row?.schedules?.length || 0

                    completed = schedules_count == completed_schedules?.length ? true : false
                    expiring = completed_schedules?.length  == (schedules_count - 1) ? true : false
                    active = !expiring && !row?.renew && completed_schedules?.length < schedules_count
                    paused = row?.paused == 1 ? true : false

                    if(package_status?.value == 'Active' && active) {
                        include = true
                    }
                    if(package_status?.value == 'Expiring Soon' && expiring) {
                        include = true
                    }
                    if(package_status?.value == 'Expired/Completed' && completed) {
                        include = true
                    }
                    if(package_status?.value == 'Paused' && paused) {
                        include = true
                    }

                    return include
                })
            }
        }
    
        var compressed = LZString.compressToUTF16(JSON.stringify(bkk));
        localStorage.setItem('bookings', compressed)
        setBookings(bkk)
    }

    const handleCancelBooking = (row) => {
        setSelectedbooking(row);
        setOpenconfirm(true);
    }

    function renderOtherDialog () {
        return (
            <Dialog
                open={openconfirm}
                onRequestClose={() => setOpenconfirm(false)}
                id="confirm-dialog"
            >
                <DialogTitle>{t('dashboard.areyousurecancelbooking')}</DialogTitle>
                <DialogActions id="confirm-buttons-container">
                    <Button onClick={() => setOpenconfirm(false)} primary>{t('booking.goback')}</Button>
                    <Button onClick={cancelBooking} primary>{t('dashboard.yescancel')}</Button>
                </DialogActions>
            </Dialog>
        )
    }

    function cancelBooking() {
        axios.post(API_URL + `/subscription/cancel/${selectedbooking.id}`, {})
        .then(res => {
            window.location.reload(true);
        })
    }

    const valueFromId = (opts, id) => opts.find(o => o.value === id);

    const formatSelectData = (array, mode) => {
        let new_array = []
        if(mode == 'days' || mode == 'shifts' || mode == 'booking_days') {
            if(array.length > 0) {
                array.forEach(item => {
                    new_array.push({
                        value: item.id,
                        label: mode == 'shifts' ? (language == 'ar' ? `${(item.name_ar || item.day_ar)} ${moment(moment().format('YYYY-MM-DD') + ' ' + item.start_time).format('h A')}-${moment(moment().format('YYYY-MM-DD') + ' ' + item.end_time).format('h A')}` : `${(item.name || item.day)} ${moment(moment().format('YYYY-MM-DD') + ' ' + item.start_time).format('h A')}-${moment(moment().format('YYYY-MM-DD') + ' ' + item.end_time).format('h A')}`) 
                            : (language == 'ar' ? ((item.name_ar) ? (item.name_ar || item.day_ar) : (item.name || item.day)) : (item.name || item.day))
                    })
                })
            }
        } else {
            if(array.length > 0) {
                array.forEach(item => {
                    new_array.push({
                        value: item.id,
                        label: language == 'ar' ? ((item.name_ar || item.type || (item.first_name + ' ' + item.last_name)) ? (item.name_ar || item.type || (item.first_name + ' ' + item.last_name)) : (item.name || item.type || (item.first_name + ' ' + item.last_name))) : (item.name || item.type || (item.first_name + ' ' + item.last_name))
                    })
                })
            }
        }

        if(mode == 'properties') { setBuildings(new_array); }
        return new_array;
    }

    const toggleFilter = (status) => {
        setActiveFilter(status);
        if(!status) {
            setSelectedBuilding('');
            setSelectedBuildingObject('');
            setSelectedPaymentStatus('');
            setSelectedPaymentStatusObject('');
            setSelectedPackageStatus('');
            setSelectedPackageStatusObject('');
            filterBookings('');
        }
    }

    const handleClickOpen = () => {
        setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false);
    };

    const confirmDelete = (row) => {
        setSelectedbooking(row);
        setOpenDelete(true);
    };
    
    const handleCloseDelete = () => {
        setOpenDelete(false);
    };

    const handleDelete = () => {
        axios.post(API_URL + `/booking/delete/${selectedbooking.booking_id}`, {})
        .then(res => {
            setOpenDelete(false);
            window.location.reload(true);
        })
    };

    const handleAutoRenewChange = (event, row) => {
        let url = `${API_URL}/v2/booking-auto-renew/edit`
        let params = {
            booking_id: row.booking_id,
            auto_renew: event.target.checked ? 1 : 0,
        }

        var decompressed = LZString.decompressFromUTF16(localStorage.getItem("bookings"));
        const bkk = decompressed ? JSON.parse(decompressed) : []

        // Update booking
        const new_bookings = []
        if(bkk?.length > 0) {
            bkk.forEach(bkng => {
                if(bkng.booking_id == row?.booking_id) {
                    bkng.auto_renew = event.target.checked ? 1 : 0
                }
                new_bookings.push(bkng)
            }) 
        }
       
        setBookings(new_bookings);

        axios.post(url, params)
        .then(res => {
            toast.success(t("users.successsave"), {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        })
    };

    return (
        <div className="inner">
            <LoggedInTopMenu />
            <div className={classes.content}>
                <Grid
                    justify="space-between"
                    container
                >
                    <Hidden smDown>
                        <div className={classes.sideMenu}>
                            <SideMenu />
                        </div>
                    </Hidden>
                    <div className={classes.mainContent}>
                        <div className={classes.article}>
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <a key="1" href="#" className={classes.activePageHeader}>{t('side.bookings')}</a>  
                                <a className={classes.addService} href={'/booking/0/'}>
                                    <AddIcon className={classes.addIcon} />
                                    <div className={classes.addServiceText}>{t('side.addbooking')}</div>
                                </a>
                            </div>
                            <div style={{display:'flex',alignItems:'center'}}>
                                <div className={classes.filterContainer} style={{marginRight:10}}>
                                    <div style={{marginRight:10}}>
                                        <TextField 
                                            id="searchKeyword"
                                            label={t('dashboard.search')} 
                                            className={classes.input}
                                            placeholder={t('dashboard.search')}
                                            value={searchKeyword || ''}
                                            onChange={(event) => handleFieldChange(event, 'searchKeyword')}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <FilterListIcon
                                        className={classes.icon}
                                        style={{
                                            color: activeFilter ? '#9f0000' : '#000',
                                            fontSize: 30,
                                            marginRight: 15,
                                            marginTop: 18,
                                        }}
                                        onClick={() => toggleFilter(activeFilter ? false : true)}
                                    />
                                </div>
                                {activeFilter &&
                                    <>
                                        <div style={{width:180,marginTop:5,marginRight:10}}>
                                            <Select
                                                placeholder={t('services.building')}
                                                value={selectedBuildingObject}
                                                onChange={handleBuildingChange}
                                                options={buildings}
                                                name="building"
                                                required
                                            />
                                        </div>
                                        <div style={{width:180,marginTop:5,marginRight:10}}>
                                            <Select
                                                placeholder={t('addbooking.packagestatus')}
                                                value={selectedPackageStatusObject}
                                                onChange={handlePackageStatusChange}
                                                options={packageStatus}
                                                name="packagestatus"
                                                required
                                            />
                                        </div>
                                        <div style={{width:180,marginTop:5}}>
                                            <Select
                                                placeholder={t('addbooking.paymentstatus')}
                                                value={selectedPaymentStatusObject}
                                                onChange={handlePaymentStatusChange}
                                                options={paymentStatus}
                                                name="paymentstatus"
                                                required
                                            />
                                        </div>
                                    </>
                                }
                            </div>
                            <ToastContainer />
                            <DataTable
                                columns={columns}
                                data={bookings}
                                pagination
                                paginationResetDefaultPage={resetPaginationToggle}
                                persistTableHead
                                fixedHeader
                            />
                        </div>
                    </div>
                    {renderOtherDialog()}
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Mark as paid?"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <div style={{marginBottom:15}}>
                                    <>
                                        <div style={{marginTop:15}}>{t('addbooking.paidoptions')}: </div>
                                        <div>
                                            {paidOptionValues && paidOptionValues.map((pd) => (
                                                <div>
                                                    <FormControlLabel
                                                        value="end" 
                                                        control={<Radio 
                                                            checked={paidOption.type_value == pd.type_value}
                                                            onChange={handlePaidOptionChange}
                                                            value={pd.type_value}
                                                            name="radio-button-demo"
                                                            inputProps={{ 'aria-label': pd.type_en }} />} 
                                                        label={localStorage.getItem('language') == 'ar' ? (pd.type_ar ? pd.type_ar : pd.type_en) : pd.type_en} />
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                </div>
                                <div>{t('statement.markaspaid')}</div>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            {!processing &&
                                <>
                                    <Button onClick={handleClose} color="primary">
                                        {t('services.cancel')}
                                    </Button>
                                    <Button onClick={handlePaid} color="primary" autoFocus>
                                        OK
                                    </Button>
                                </>
                            }
                            {processing &&
                                <div>Processing, please wait...</div>
                            }
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={openDelete}
                        onClose={handleCloseDelete}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Confirm delete"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <div>Are you sure you want to delete this booking? This operation is irreversible.</div>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <>
                                <Button onClick={handleCloseDelete} color="primary">
                                    {t('services.cancel')}
                                </Button>
                                <Button onClick={handleDelete} color="primary" autoFocus>
                                    OK
                                </Button>
                            </>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={openPaymentReceived}
                        onClose={handleClosePaymentReceived}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Confirm Payment Received"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <div>Are you sure you want to mark this cash payment as received?</div>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            {!processing &&
                                <>
                                    <Button onClick={handleClosePaymentReceived} color="primary">
                                        {t('services.cancel')}
                                    </Button>
                                    <Button onClick={handlePaymentReceived} color="primary" autoFocus>
                                        OK
                                    </Button>
                                </>
                            }
                            {processing &&
                                <div>Processing, please wait...</div>
                            }
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={openResume}
                        onClose={handleCloseResume}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Confirm resume"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <div>Are you sure you want to resume this booking?</div>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <>
                                <Button onClick={handleCloseResume} color="primary">
                                    {t('services.cancel')}
                                </Button>
                                <Button onClick={handleResume} color="primary" autoFocus>
                                    OK
                                </Button>
                            </>
                        </DialogActions>
                    </Dialog>
                </Grid>
            </div>
        </div>
    );
};

export default Bookings;
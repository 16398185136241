import React, { useRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { API_URL } from '../constants';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import LoggedInTopMenu from '../components/LoggedInTopMenu';
import SideMenu from '../components/SideMenu';
import Hidden from '@material-ui/core/Hidden';
import axios from 'axios';
import washmodalimage from '../images/wash-modal.png'; 
import pinmodalimage from '../images/pin-modal.png'; 
import calendarmodalimage from '../images/calendar-modal.png'; 
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import CancelIcon from '@material-ui/icons/Cancel';
import MapIcon from '@material-ui/icons/Map';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import EmailIcon from '@material-ui/icons/Email';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ReceiptIcon from '@material-ui/icons/Receipt';
import useSound from 'use-sound';
import soundFile from '../assets/alert.mp3';
import starImage from '../images/star.png';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DataTable from 'react-data-table-component';
import Checkbox from '@material-ui/core/Checkbox';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles(theme => ({
    ratingsContainer: {
      display: 'flex',
      flexDirection: 'row',
      margin: '0 auto',
      marginTop: 15,
      justifyContent: 'flex-start',
      ['@media (max-width:768px)']: { 
        justifyContent: 'flex-start'
      }
    },
    placeholderImage: {
      width: 160
    },
    toggleACWButton: {
      backgroundColor: '#30acdc',
      color: '#fff',
      borderColor: '#9f0000',
      borderWidth: 1
    },
    content: {
      paddingTop: 130,
      marginLeft: '-6%'
    },
    sideMenu: {
      backgroundColor: '#9F0000',
      width: 256,
      height: `calc(200vh - 130px)`,
      borderTopRightRadius: 75,
      color: '#fff',
      paddingTop: 70
    },
    mainContent: {
      width: `calc(100% - 256px)`,
      textAlign: 'left',
      ['@media (max-width:959px)']: { 
        width: '92%',
        paddingLeft: 0,
        paddingRight: 0
      }
    },
    article: {
      paddingTop: 20,
      paddingLeft: 50
    },
    pageHeader: {
      fontSize: 20,
      fontWeight: 'bold',
      paddingRight: 40,
      color: '#a7a7a7',
      textDecoration: 'none'
    },
    activePageHeader: {
      fontSize: 20,
      fontWeight: 'bold',
      paddingRight: 40,
      color: '#000',
      textDecoration: 'none',
      marginBottom: 15,
    },
    userContainer: {
      display: 'flex',
      marginBottom: 10
    },
    customer: {
      width: 36,
      height: 36,
      borderRadius: 12,
      objectFit: 'cover'
    },
    customerDetails: {
      position: 'absolute',
      marginLeft: 50,
      marginTop: 5,
      fontSize: 14,
      fontWeight: 'bold',
      wordBreak: 'break-word',
      width: 162
    },
    carContainer: {
      display: 'flex'
    },
    car: {
      width: 36,
      height: 36,
      borderRadius: 12,
      objectFit: 'cover'
    },
    carDetails: {
      position: 'absolute',
      marginLeft: 50,
      marginTop: 5,
      fontSize: 14,
      fontWeight: 'bold'
    },
    cardContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexWrap: 'wrap'
    },
    card: {
      backgroundColor: '#f7f7f7',
      borderRadius: 20,
      width: 290,
      marginRight: 10,
      marginBottom: 20
    },
    top: {
      borderBottomWidth: 1,
      borderBottomColor: '#d3d3d3',
      borderBottom: 'solid',
      padding: 20
    },
    bottom: {
      padding: 20
    },
    serviceContainer: {
      display: 'flex'
    },
    locationContainer: {
      display: 'flex'
    },
    calendarContainer: {
      display: 'flex'
    },
    progressContainer: {
      display: 'flex',
      position: 'absolute',
      float: 'right',
      marginLeft: 185,
      border: 'solid 1px #1d7846',
      paddingLeft: 10,
      paddingRight: 10,
      borderRadius: 8
    },
    wash: {
      backgroundColor: '#fff',
      width: 25,
      height: 25,
      borderRadius: 10
    },
    washImage: {
      width: 18,
      height: 18
    },
    wash: {
      backgroundColor: '#fff',
      width: 28,
      height: 28,
      borderRadius: 10,
      marginLeft: 4,
      marginRight: 4,
      marginBottom: 10
    },
    washImage: {
      width: 18,
      height: 18,
      margin: 5
    },
    pin: {
      backgroundColor: '#fff',
      width: 28,
      height: 28,
      borderRadius: 10,
      marginLeft: 4,
      marginRight: 4,
      marginBottom: 10
    },
    pinImage: {
      width: 17,
      height: 20,
      margin: '4px 5px'
    },
    calendar: {
      backgroundColor: '#fff',
      width: 28,
      height: 28,
      borderRadius: 10,
      marginLeft: 4,
      marginRight: 4,
      marginBottom: 10
    },
    calendarImage: {
      width: 18,
      height: 18,
      margin: 5
    },
    service: {
      fontSize: 14,
      marginTop: 5,
      marginLeft: 15,
      wordBreak: 'break-word'
    },
    address: {
      fontSize: 14,
      marginTop: 5,
      marginLeft: 15
    },
    next: {
      fontSize: 14,
      marginTop: 5,
      marginLeft: 15
    },
    progress: {
      fontSize: 14,
      marginTop: 2,
      color: '#1d7846'
    },
    checkIcon: {
      color: '#1d7846',
      fontSize: 16
    },
    check: {
      marginTop: 2,
      marginRight: 7
    },
    statusContainer: {
      textAlign: 'center',
      height: 36,
      backgroundColor: '#9F0000',
      borderRadius: 12,
      paddingTop: 6,
      marginTop: 10,
      "&:hover": {
          cursor: "pointer"
      }
    },
    status: {
      color: '#fff',
      textDecoration: 'none',
      fontSize: 14
    },
    customerNameLink: {
      color: '#000',
      textDecoration: 'none'
    },
    titleContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 7
    },
    dialogTitle: {
      fontSize: 20,
      fontWeight: 'bold'
    },
    close: {
      fontSize: 24,
      "&:hover": {
          cursor: "pointer"
      }
    },
    dialogContent: {
      width: 480,
      overflowX: 'hidden',
      marginTop: 50,
      ['@media (max-width:959px)']: { 
        width: '100%'
      }
    },
    serviceInfoDate: {
      fontSize: 14,
      color: '#000',
      opacity: 0.25,
      marginTop: -10
    },
    dialogUserContainer: {
      display: 'flex',
      justifyContent: 'space-around',
      width: '70%',
      marginLeft: 'auto',
      marginRight: 'auto',
      ['@media (max-width:959px)']: { 
        width: '100%'
      }
    },
    dialogUserImage: {
      width: 80,
      height: 80,
      borderRadius: 18,
      objectFit: 'cover',
      marginBottom: 25
    },
    dialogCarImage: {
      width: 80,
      height: 80,
      borderRadius: 18,
      objectFit: 'cover',
      marginBottom: 25
    },
    dialogUser: {
      textAlign: 'center'
    },
    dialogCar: {
      textAlign: 'center'
    },
    dialogFlexContainer: {
      display: 'flex'
    },
    dialogRow: {
      marginTop: 30
    },
    modalIconImage: {
      width: 36,
      height: 36
    },
    dialogRightText: {
      marginLeft: 20,
      paddingTop: 6,
      marginLeft: 20,
      paddingTop: 6,
      width: '85%'
    },
    dialogFlexEndContainer: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    dialogRight: {
      position: 'absolute',
      right: 64,
      fontWeight: 'bold'
    },
    dialogRightLast: {
      position: 'absolute',
      right: 64,
      marginTop: 25,
      fontWeight: 'bold'
    },
    dialogBottomRow: {
      marginTop: 25
    },
    mapContainer: {
      height: 319, 
      width: '100%', 
      borderRadius: 40,
      marginTop: 20
    },
    editIcon: {
      marginRight: 10,
      color: '#9f0000',
      fontSize: 18,
      "&:hover": {
          cursor: "pointer"
      }
    },
    editButton: {
      marginTop: 20,
      width: 158,
      color: '#9f0000',
      backgroundColor: '#fff',
      borderRadius: 5,
      marginLeft: -15,
      "&:hover": {
        cursor: "pointer",
        backgroundColor: '#fff',
        color: '#9f0000'
      },
      ['@media (max-width:959px)']: { 
        width: '100%'
      }
    },
    cancelIcon: {
      marginRight: 10,
      color: '#fff',
      fontSize: 18,
      "&:hover": {
          cursor: "pointer"
      }
    },
    cancelButton: {
      marginTop: 20,
      color: '#fff',
      backgroundColor: '#9f0000',
      borderRadius: 5,
      "&:hover": {
        cursor: "pointer",
        backgroundColor: '#9f0000',
        color: '#fff'
      }
    },
    input: {
      width: '100%',
      fontSize: 16
    },
    input3rd: {
      width: '30%',
      fontSize: 16,
      ['@media (max-width:959px)']: { 
        width: '100%'
      }
    },
    saveContainer: {
      float: 'right',
      marginRight: 0,
      marginTop: 12,
      marginLeft: 58
    },
    saveText: {
      color: '#9F0000',
      fontSize: 16,
      fontWeight: 500,
      display: 'inline-block',
      position: 'absolute',
      marginTop: 7,
      marginLeft: -45,
      width: 44
    },
    saveBtnContainer: {
      width: 36,
      height: 36,
      borderRadius: 12,
      backgroundColor: '#9F0000',
      display: 'inline-block',
      marginLeft: 10,
      "&:hover": {
          cursor: 'pointer'
      },
    },
    saveBtn: {
      width: 21,
      height: 21,
      paddingLeft: 3,
      position: 'absolute',
      marginLeft: 7,
      marginTop: 6
    },
    buttonContainerFlex: {
      display: 'flex',
      justifyContent: 'space-between',
      ['@media (max-width:959px)']: { 
        justifyContent: 'center',
        flexWrap: 'wrap'
      }
    },
    timeslot: {
      marginTop: -35
    },
    mapIcon: {
      color: '#9F0000',
      marginBottom: -5,
      marginRight: 5,
      marginLeft: 5
    },
    whatsappIcon: {
      color: '#075e54',
      marginBottom: -5,
      marginRight: 5,
      marginLeft: 5
    },
    emailIcon: {
      color: '#9F0000',
      marginBottom: -5,
      marginRight: 5,
      marginLeft: 5
    },
    share: {
      "&:hover": {
          cursor: "pointer"
      }
    },
    cardWrapper: {
      backgroundColor: '#f7f7f7',
      borderRadius: 15,
      border: 'none',
    },
    arrowIcon: {
      fontSize: 23,
      color: '#9F0000',
      "&:hover": {
        cursor: "pointer"
      }
    },
    cardSummaryWrapper: {
      backgroundColor: '#fff',
      borderRadius: 15,
      border: 'none',
      shadowColor: "#000",
      shadowOffset: { width: 0, height: 1 },
      shadowOpacity: 0.8,
      shadowRadius: 2,
      elevation: 5,
      "&:hover": {
        cursor: "pointer"
      }
    },
    cardSummary: {
      backgroundColor: '#f8f5f5',
      paddingBottom: '0px !important',
    },
    cardSummaryTitle: {
      fontSize: 35,
      fontWeight: 900,
      marginTop: -8,
      color: '#000',
    }
}));

const NewDashboard = () => {
  const classes = useStyles();
  const id = localStorage.getItem('id');
  const userId = localStorage.getItem('userId');
  const roleId = localStorage.getItem('roleId');
  let [role, setRole] = useState('');
  let [dashboardevents, setDashboardevents] = useState('');
  let [events, setEvents] = useState('');
  let [eventCount, setEventCount] = useState('');
  let [selecteddayevents, setSelecteddayevents] = useState('');
  let [allselecteddayevents, setAllSelecteddayevents] = useState('');
  let [selectedday, setSelectedday] = useState('');
  let [servicedays, setServicedays] = useState('');
  let [servicephotos, setServicephotos] = useState('');
  let [edit, setEdit] = useState(false);
  let [selectedevent, setSelectedevent] = useState([]);
  let [timeslot, setTimeslot] = useState('');
  let [customerlocation, setCustomerlocation] = useState('');
  let [whatsapplink, setWhatsapplink] = useState('');
  let [mailto, setMailto] = useState('');
  const [open, setOpen] = React.useState(false);
  const [openconfirm, setOpenconfirm] = React.useState(false);
  const [scroll, setScroll] = React.useState('body');
  const { t, i18n } = useTranslation();
  const [play] = useSound(soundFile);
  const inputRef = React.useRef(null);
  let [selectedDate, setSelectedDate] = useState('');
  let [selectedFilter, setSelectedFilter] = useState('All');
  let [managerProperties, setManagerProperties] = useState([]);
  let [all, setAll] = useState(0);
  let [notStarted, setNotStarted] = useState(0);
  let [completed, setCompleted] = useState(0);
  let [notFound, setNotFound] = useState(0);
  let [booking, setBooking] = useState(null);
  let [columns, setColumns] = useState([]);
  let [tableSchedules, setTableSchedules] = useState([]);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  useEffect(() => {
    setRole(roleId);
    const language = localStorage.getItem('language');
    if(language) {
      i18n.changeLanguage(language);
    }
    
    if(roleId == 5) {
      axios.get(API_URL + `/manager-properties`)
      .then(resp => {
        let manager_properties = resp.data.manager_properties;
        setManagerProperties(manager_properties)
        loadData(id, manager_properties)
      })
    } else {
      loadData(id, [])
    }

    let table_columns= [
      {
          name: t('history.date'),
          selector: row => row.booking_date, //getBookingDate(row),
          sortable: true,
      },
      {
          name: t('feedback.time'),
          selector: row => row.time, //getBookingTime(row, sh),
          sortable: true,
      },
      {
          name: t('booking.status'),
          selector: row => row.status_id == 3 ? 1 : 0, //getAssigned(row, sc),
          sortable: true,
          cell: row => <CustomStatus row={row} />,
      }
    ];
    setColumns(table_columns);
  }, [])

  const CustomStatus = ({ row }) => (
    <div>
        {}
        <div>
            <div
                data-tag="allowRowEvents"
                style={{ color: 'grey', overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
            >
                {}
                <Checkbox
                    checked={row.complete}
                    onChange={(event) => handleChangeStatus(event, row)}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />
            </div>
        </div>
    </div>
  );

  const handleChangeStatus = (event, row) => {
    axios.post(API_URL + `/schedule/complete/${row.booking_schedule_id}`, {})
    .then(res => {
        if(res.status == 200) {
            toast.success(t("users.successsave"), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            
            getBookingSchedules(row.booking_id)
        }
    })
  };

  function loadData(buildingId, manager_properties) {
    axios.get(API_URL + `/dashboard/schedules/web/${buildingId}/${roleId}`)
    .then(response => {
      let dbevts = response.data.schedules;

      let filtered_events = dbevts;
      if(roleId == 5) {
        if(filtered_events?.length > 0) {
          filtered_events.forEach(event => {
            let include = false

            let match = manager_properties.some(a => a.property_id == event.property_id && a.user_id == userId)
            if(match) {
              include = true
            }

            return include
          })
        }
      }

      let evts = [];
      setDashboardevents(filtered_events);

      if(filtered_events) {
        filtered_events.forEach(e => {
          let title = t('dashboard.flat') + ' ' + e.flat_number;
          title = title + "," + e.quantity + "x " + e.car_type + "," + e.customer_first_name + " " + e.customer_last_name
          let dt = moment(e.dt).utc().format("YYYY-MM-DD HH:mm:ss").toString();
          let end_dt;
          if(e.end_dt) {
            let dt_end = e.end_dt.replace(" ", "T").replace(":00:00", ":00:00.000Z");
            end_dt = moment(dt_end).utc().format("YYYY-MM-DD HH:mm:ss").toString();
          }
          if(end_dt) {
            evts.push({
              title: title,
              date: e.date,
              id: e.random_id,
              start: dt,
              end: end_dt,
            })
          } else {
            evts.push({
              title: title,
              date: e.date,
              id: e.random_id,
              start: dt
            })
          }
        });

        if(evts) {
          setEvents(evts);

          // Load today
          let timezoneOffset = new Date().getTimezoneOffset() * -1;
          let tz = moment(new Date()).utc().utcOffset(timezoneOffset);
          let today = moment(new Date()).utc().utcOffset(timezoneOffset).format("YYYY-MM-DD").toString();
          if(dbevts) {
            let db = dbevts.filter(e => {
              return e.date == today
            })
            setSelectedday('Today');
            setSelectedDate(moment().format('YYYY-MM-DD'));
            setSelecteddayevents(db);
            setAllSelecteddayevents(db);
            setEventCount(db.length)

            updateSummaryTotals(db);
          }
        }
      }
    })
  }

  const updateSummaryTotals = (db) => {
    // if(db?.length > 0) {
      setAll(db?.length || 0)

      let c =  db?.filter(fe => {
        return fe.status == 'Completed'
      })
      setCompleted(c?.length || 0)

      let nf =  db?.filter(fe => {
        return fe.not_found == 1 && fe.status != 'Completed'
      })
      setNotFound(nf?.length || 0)

      let ns =  db?.filter(fe => {
        return fe.status != 'Completed' && fe.not_found != 1
      })
      setNotStarted(ns?.length || 0)
    // }
  }

  const handleClose = () => {
    setSelectedevent([]);
    setOpen(false);
  };

  const handleClickOpen = (scrollType, event) => () => {
    selectEvent(scrollType, event);
  };

  function selectEvent(scrollType, event) {
    if(event.id) {
      console.log('event')
      console.log(event)
      setSelectedevent(event);
      setEdit(false)

      getBookingSchedules(event.booking_id);

      let utc_time = '';
      let timezoneOffset = new Date().getTimezoneOffset();
      let tz = moment(event.start_time_slot).utc().utcOffset(timezoneOffset);

      let timeslot;
      if(event.start_time_slot) {
        timeslot = moment(event.start_time_slot).utc().format("h A");
        let end_timeslot = moment(event.end_time_slot).utc().format("h A");
        if(event.times > 0) {
          timeslot += '-' + end_timeslot
        }
        setTimeslot(timeslot);
      }

      setServicedays([event.date]);

      axios.get(API_URL + `/service/uploads/${event.id}`)
      .then(res => {
        setServicephotos(res.data.photos);
      })
        
      setOpen(true);
      setScroll(scrollType);

      let mapLink = `https://www.google.com.sa/maps/search/${encodeURI(event.address)}`;
      let whatsappLink = 'https://api.whatsapp.com/send?text=' + 'Customer Location: ' + event.address + ' ' + encodeURI(mapLink);
      let mailLink = `mailto:?Subject=Customer Location&body=` + 'Customer Location: ' + event.address + ' ' + encodeURI(mapLink);
      setCustomerlocation(mapLink);
      setWhatsapplink(whatsappLink);
      setMailto(mailLink);
    } else {
      setOpen(true);
      setScroll(scrollType);
    }
  }

  function getBookingSchedules(booking_id) {
    axios.get(API_URL + `/shifts`)
    .then(resp2 => {
      let shf = resp2.data.shifts;
      
      axios.get(API_URL + `/booking/${booking_id}`)
      .then(response3 => {
        let bkng = response3.data.booking;
        if(bkng) {
          setBooking(bkng);

          axios.get(API_URL + `/building-staffs/${bkng.property_id}`)
          .then(response => {
            let schedules = response.data?.schedules
            
            let list = []
            if(bkng?.booking_schedules?.length > 0) {
              bkng.booking_schedules.forEach(bs => {
                let shift = shf?.filter(sh => {
                  return sh.value == bs?.booking_shift_id || sh.id == bs?.booking_shift_id
                })

                let sched = schedules?.filter(sc => {
                  return sc?.booking_date == bs?.booking_date && sc?.assigned_staff_id == bkng.assigned_staff_id
                })

                list.push({
                  booking_schedule_id: bs.id,
                  booking_id: bs.booking_id,
                  booking_item_id: bs.booking_item_id,
                  booking_date: bs.booking_date,
                  time: `${shift?.length > 0 ? (shift[0]?.label || shift[0].name) : ''} ${moment(moment().format('YYYY-MM-DD') + ' ' + shift[0]?.start_time).format('h A')}-${moment(moment().format('YYYY-MM-DD') + ' ' + shift[0]?.end_time).format('h A')}`,
                  assigned: sched?.length > 0 ? sched[0].assigned : '',
                  complete: bs.status_id == 3 ? true : false
                })
              })
            }
            if(list?.length > 0) {
                list = list.sort((a, b) => {
                    const oldDate = new Date(a.booking_date)
                    const newDate = new Date(b.booking_date)
              
                    return oldDate - newDate
                })
            }
            setTableSchedules(list)
          })
        }
      })
    })
  } 

  function handleCancelBooking() {
    if(selectedevent) {
      setOpenconfirm(true);
    }
  }

  function renderOtherDialog () {
    return (
      <Dialog
        open={openconfirm}
        onRequestClose={() => setOpenconfirm(false)}
        id="confirm-dialog"
      >
        <DialogTitle>{t('dashboard.areyousurecancelbooking')}</DialogTitle>
        <DialogActions id="confirm-buttons-container">
          <Button onClick={() => setOpenconfirm(false)} primary>{t('dashboard.cancel')}</Button>
          <Button onClick={cancelBooking} primary>{t('dashboard.yescancel')}</Button>
        </DialogActions>
      </Dialog>
    )
  }

  function cancelBooking() {
    axios.post(API_URL + `/subscription/cancel/${selectedevent.booking_item_id}`, {})
    .then(res => {
      window.location.reload(true);
    })
  }

  function clickNotification(requestId, requestDateId) {
    let event;
    if(selecteddayevents) {
      selecteddayevents.forEach(e => {
        if(e.id == requestId) {
          if(requestDateId) {
            if(e.request_date_id == requestDateId) {
              event = e;
            }
          } else {
            event = e;
          }
        }
      });
      if(event) {
        selectEvent('body', event);
      }
    }
  }

  const viewInvoice = (event) => {
    window.open(
      `${API_URL}/invoice/${event.reference_number}.pdf`,
      '_blank'
    );
  }

  function renderStarRatings(feedback) {
    var ratings = [];
    if(feedback) {
      for(let i = 0; i < parseInt(feedback); i++){
        ratings.push(<div>
          <img
            src={starImage}
            className="rating-star"
          />
        </div>)
      }
    }
    return ratings;
  }

  const getPreviousDay = () => {
    let date = moment(selectedDate).subtract(1, 'days').format('YYYY-MM-DD');

    let db = []
    if(dashboardevents?.length > 0) {
      db = dashboardevents?.filter(e => {
        return e.date == date
      })
    }
    setSelectedDate(date);
    setSelecteddayevents(db);
    updateSummaryTotals(db);
  }

  const getNextDay = () => {
    let date = moment(selectedDate).add(1, 'days').format('YYYY-MM-DD');

    let db = []
    if(dashboardevents?.length > 0) {
      db = dashboardevents?.filter(e => {
        return e.date == date
      })
    }
    setSelectedDate(date);
    setSelecteddayevents(db);
    updateSummaryTotals(db);
  }

  const getSelectedDate = () => {
    return selectedDate ? moment(selectedDate).format('MMM D') : ''
  }

  const filterStatus = (mode) => {
    setSelectedFilter(mode);
    if(mode == 'All') {
      let db = []
      if(dashboardevents?.length > 0) {
        db = dashboardevents?.filter(e => {
          return e.date == selectedDate
        })
      }
      setSelecteddayevents(db);
      setEventCount(db?.length)
  
      updateSummaryTotals(db);
    } else {
      let db = []
      if(dashboardevents?.length > 0) {
        db = dashboardevents?.filter(e => {
          return e.date == selectedDate
        })
      }

      let filtered = db?.filter(item => {
        let include = false

        if(mode == 'Completed' && item.status == 'Completed') {
          include = true
        }

        if(mode == 'Not Found' && item.not_found == 1 && item.status != 'Completed') {
          include = true
        }

        if(mode == 'Not Started' && item.status != 'Completed' && item.not_found != 1) {
          include = true
        }

        return include
      })

      setSelecteddayevents(filtered);
      setEventCount(filtered?.length)
  
      updateSummaryTotals(filtered);
    }
  }

  return (
    <div className="inner">
      <LoggedInTopMenu notificationClick={clickNotification} />
      <div ref={inputRef} onClick={play}>CarCare</div>
      <div className={classes.content}>
        <Grid
          justify="space-between"
          container
        >
          <Hidden smDown>
            <div className={classes.sideMenu}>
              <SideMenu />
            </div>
          </Hidden>
          <div className={classes.mainContent}>
            <div className={classes.article} style={{paddingTop:0}}>
              <div>
                <Grid container spacing={3}>
                  <Grid item xs={3} sm={3} md={3} lg={3}>
                    <Card className={classes.cardSummaryWrapper} 
                      style={{
                        borderColor: selectedFilter == 'All' ? '#9f0000' : '#fffff7',
                        borderWidth: 1,
                        borderStyle:'solid'
                      }} variant="outlined" onClick={() => filterStatus('All')}>
                      <CardContent className={classes.cardSummary} 
                        style={{
                          backgroundColor: '#fffff7',
                          paddingBottom: 16,
                          color: '#1e1e1e'
                        }}>
                        <Typography variant="body2" component="p">
                          <span style={{textTransform:'uppercase'}}>{t('metrics.all')}</span>
                        </Typography>
                        <Typography variant="h5" component="h1" className={classes.cardSummaryTitle}>
                          {all}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={3} sm={3} md={3} lg={3}>
                    <Card className={classes.cardSummaryWrapper} 
                      style={{
                        borderColor: selectedFilter == 'Not Started' ? '#9f0000' : 'transparent',
                        borderWidth: 1,
                        borderStyle:'solid'
                      }} variant="outlined" onClick={() => filterStatus('Not Started')}>
                      <CardContent className={classes.cardSummary} style={{paddingBottom:16}}>
                        <Typography variant="body2" component="p">
                          <span style={{textTransform:'uppercase'}}>{t('booking.notstarted')}</span>
                        </Typography>
                        <Typography variant="h5" component="h1" className={classes.cardSummaryTitle}>
                          {notStarted}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={3} sm={3} md={3} lg={3}>
                    <Card className={classes.cardSummaryWrapper} 
                      style={{
                        borderColor: selectedFilter == 'Completed' ? '#9f0000' : 'transparent',
                        borderWidth: 1,
                        borderStyle:'solid'
                      }} variant="outlined" onClick={() => filterStatus('Completed')}>
                      <CardContent className={classes.cardSummary} style={{paddingBottom:16,backgroundColor:'#defade'}}>
                        <Typography variant="body2" component="p">
                          <span style={{textTransform:'uppercase'}}>{t('booking.completed')}</span>
                        </Typography>
                        <Typography variant="h5" component="h1" className={classes.cardSummaryTitle}>
                          {completed}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={3} sm={3} md={3} lg={3}>
                    <Card className={classes.cardSummaryWrapper} 
                      style={{
                        borderColor: selectedFilter == 'Not Found' ? '#9f0000' : 'transparent',
                        borderWidth: 1,
                        borderStyle:'solid'
                      }} variant="outlined" onClick={() => filterStatus('Not Found')}>
                      <CardContent className={classes.cardSummary} style={{paddingBottom:16,backgroundColor:'#ffffe0'}}>
                        <Typography variant="body2" component="p">
                          <span style={{textTransform:'uppercase'}}>{t('booking.notfound')}</span>
                        </Typography>
                        <Typography variant="h5" component="h1" className={classes.cardSummaryTitle}>
                          {notFound}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </div>
              <div style={{
                marginTop:25,
                marginBottom:15,
                display: 'flex'
              }}>
                <div style={{fontSize:20,fontWeight:600}}><span style={{textTransform:'uppercase'}}>{getSelectedDate()}</span></div>
                <div style={{marginLeft:10}}>
                  <ArrowBackIcon className={classes.arrowIcon} onClick={(e) => getPreviousDay()} />
                </div>
                <div style={{marginLeft:10}}>
                  <ArrowForwardIcon className={classes.arrowIcon} onClick={(e) => getNextDay()} />
                </div>
              </div>
              <div>
                <Grid container spacing={4}>
                  {selecteddayevents && selecteddayevents.map((item, index) => (
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                      <Card className={classes.cardWrapper} variant="outlined">
                        <CardContent style={{backgroundColor:item.status == 'Completed' ? '#defade' : (item?.not_found == 1 ? '#ffffe0' : '#f8f5f5')}}>
                          <Typography className={classes.title} color="textSecondary" gutterBottom>
                            <span style={{opacity:1,color:'#000'}}>{item.outcall} • {item?.quantity}x {item?.car_type} {item?.times > 1 ? (' • ' + item?.subscription_number + '/' + item?.times) : ''}</span>
                          </Typography>
                          <Typography variant="h5" component="h2">
                            {item?.customer_first_name} {item?.customer_last_name}
                          </Typography>
                          <Typography className={classes.pos} color="textSecondary">
                            <span style={{opacity:1,color:'#000'}}>{item?.business_name}</span>
                            <br/>
                            {item?.start_time_slot &&
                              <span style={{opacity:1,color:'#000'}}>{moment(item?.start_time_slot).utc().format('h A')} - {moment(item?.end_time_slot).utc().format('h A')}</span>
                            }
                          </Typography>
                          <Typography variant="body2" component="p">
                            <span style={{opacity:0.7}}>{t('dashboard.flatno')}:</span> {item?.flat_number} | <span style={{opacity:0.7}}>{t('addbooking.plateno')}:</span> {item?.plate_number}{item?.parking_level_name ? (' | ' + item?.parking_level_name) : ''}
                            <br />
                            <span style={{opacity:0.7}}>{t('booking.assignedstaff')}:</span> {item?.staff_first_name} {item?.staff_last_name}
                          </Typography>
                        </CardContent>
                        <CardActions style={{backgroundColor:item?.status == 'Completed' ? '#defade' : (item?.not_found == 1 ? '#ffffe0' : '#f8f5f5')}}>
                          <Button 
                            size="small" 
                            style={{color:'#9f0000'}}
                            onClick={handleClickOpen('body', item)}
                          >
                            {t('feedback.viewdetails')}
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>
                  ))}
                  {selecteddayevents?.length == 0 &&
                    <div style={{
                      marginLeft: 20,
                      marginTop: 20,
                      opacity: 0.7
                    }}>{t('dashboard.noschedule')}</div>
                  }
                </Grid>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  scroll={scroll}
                  aria-labelledby="scroll-dialog-title"
                  aria-describedby="scroll-dialog-description"
                  maxWidth = {'sm'}
                >
                  <DialogTitle id="scroll-dialog-title">
                    <div className={classes.titleContainer}>
                      <div className={classes.dialogTitle}>{selectedevent.outcall}</div>
                      <div><CloseIcon className={classes.close} onClick={handleClose} /></div>
                    </div>
                    <div className={classes.serviceInfoDate}>
                      {selectedevent.request_date}, {selectedevent.time_slot}
                    </div>
                    <div className={classes.buttonContainerFlex}>
                      <>
                        {selectedevent.status &&
                          <Button 
                            aria-label="cancel" 
                            onClick={handleCancelBooking}
                            className={classes.cancelButton}
                          >
                            <CancelIcon className={classes.cancelIcon} />
                            {t('dashboard.cancelbooking')}
                          </Button>
                        }
                      </>
                    </div>
                  </DialogTitle>
                  <DialogContent dividers={scroll === 'paper'}>
                    <div className={classes.dialogContent}>
                      <div className={classes.dialogUserContainer}>
                        <div className={classes.dialogUser}>
                          <div>
                            <img
                              className={classes.dialogUserImage}
                              src={API_URL + '/profile/image/' + (selectedevent.created_by_image ? selectedevent.created_by_image : 'default.png')} 
                            />
                          </div>  
                          <div>
                            {selectedevent.customer_first_name} {selectedevent.customer_last_name}
                          </div>
                          <div>
                            {selectedevent.phone_number}
                          </div>
                        </div>
                        <div className={classes.dialogCar}>
                          <div>
                            <img
                              className={classes.dialogCarImage}
                              src={API_URL + '/car/type/image/' + selectedevent.car_type_image} 
                            />
                          </div>
                          <div>
                            {selectedevent.car_type}
                          </div>
                          <div>
                            {t('dashboard.carnumber')} {selectedevent.plate_number}
                          </div>
                        </div>
                      </div>
                      <div className={classes.dialogRow}>
                        <div className={classes.dialogFlexContainer}>
                          <img
                            className={classes.modalIconImage}
                            src={washmodalimage} 
                          />
                          <div className={classes.dialogRightText}> 
                            {selectedevent.outcall}
                          </div>
                        </div>
                      </div>
                      <div className={classes.dialogRow}>
                        <div className={classes.dialogFlexContainer}>
                          <div
                            style={{
                              width: 36,
                              height: 36,
                              borderRadius: 10,
                              backgroundColor: '#F7F7F7'
                            }}
                          >
                              <AttachMoneyIcon style={{
                              fontSize: 23,
                              color: '#9F0000',
                              marginLeft: 7,
                              marginTop: 6
                            }} />
                          </div>
                          <div className={classes.dialogRightText}> 
                            {selectedevent.currency} {selectedevent.amount} {selectedevent.payment ? ('(' + selectedevent.payment.substr(0, 4) + ')') : ''} 
                            <div
                              style={{
                                width: '100%',
                                marginTop: 10
                              }}
                            ><b>{t('dashboard.referencenumber')}: </b>{selectedevent.reference_number}</div>
                          </div>
                        </div>
                        <div style={{marginLeft:70}}>
                          <Button 
                            aria-label="cancel"
                            className={classes.cancelButton}
                            onClick={(e) => viewInvoice(selectedevent)}
                          >
                            <ReceiptIcon className={classes.cancelIcon} />
                            {t('statement.viewinvoice')}
                          </Button>
                        </div>
                      </div>
                      <div className={classes.dialogRow}>
                        <div className={classes.dialogFlexContainer}>
                          <img
                            className={classes.modalIconImage}
                            src={pinmodalimage} 
                          />
                          <div className={classes.dialogRightText}> 
                            <>
                              <span>{selectedevent.business_name}, {t('dashboard.flat')} {selectedevent.flat_number}</span>
                              <div>{selectedevent.parking_details}</div>
                              <div
                                style={{
                                  marginTop: 10,
                                  marginLeft: -5
                                }}
                              >
                                <a href={customerlocation} target="_blank">
                                  <MapIcon className={classes.mapIcon} />
                                </a>
                                <a href={whatsapplink} target="_blank">
                                  <WhatsAppIcon className={classes.whatsappIcon} />
                                </a>
                                <a href={mailto}>
                                  <EmailIcon className={classes.emailIcon} />
                                </a>
                              </div>
                              <div className={classes.ratingsContainer}>{renderStarRatings(selectedevent.rating)}</div>
                              <div style={{display:'flex',justifyContent:'space-between',marginTop:20,flexWrap:'wrap'}}>
                                {servicephotos && servicephotos.map((item, index) => (
                                  <img 
                                    className={classes.placeholderImage} 
                                    src={API_URL + '/service/image/' + item.image} 
                                    alt="Carcare" />
                                ))}
                              </div>
                            </>
                          </div>
                        </div>
                      </div>
                      <div className={classes.dialogRow} style={{marginBottom:30}}>
                        <div className={classes.dialogFlexContainer}>
                          <img
                            className={classes.modalIconImage}
                            src={calendarmodalimage} 
                          />
                          <div className={classes.dialogRightText}>{t('dashboard.schedule')}</div>
                        </div>
                        <div>
                          <DataTable
                              columns={columns}
                              data={tableSchedules}
                              pagination
                              paginationResetDefaultPage={resetPaginationToggle}
                              persistTableHead
                              fixedHeader
                          />
                        </div>
                      </div>
                    </div>
                  </DialogContent>
                </Dialog>
                {renderOtherDialog()}
              </div>
            </div>
          </div>
        </Grid>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};
  
export default NewDashboard;
import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useHistory, useParams } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { Grid } from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';
import { API_URL } from '../constants';
import { useTranslation } from 'react-i18next';
import LoggedInTopMenu from '../components/LoggedInTopMenu';
import SideMenu from '../components/SideMenu';
import Hidden from '@material-ui/core/Hidden';
import arrowrightwhiteimage from '../images/arrow-right-white.png'; 
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import CloseIcon from '@material-ui/icons/Close';
import Select from 'react-select';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ApartmentIcon from '@material-ui/icons/Apartment';
import LocalParkingIcon from '@material-ui/icons/LocalParking';
import AddIcon from '@material-ui/icons/Add';
import DataTable from 'react-data-table-component';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import 'react-toastify/dist/ReactToastify.css';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-force-tabpanel-${index}`}
        aria-labelledby={`scrollable-force-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
  
function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    content: {
      paddingTop: 130,
      marginLeft: '-6%'
    },
    sideMenu: {
      backgroundColor: '#9F0000',
      width: 256,
      height: `calc(100vh - 130px)`,
      borderTopRightRadius: 75,
      color: '#fff',
      paddingTop: 70
    },
    mainContent: {
      width: `calc(100% - 256px)`,
      textAlign: 'left',
      ['@media (max-width:959px)']: { 
        width: '95%'
      }
    },
    article: {
      paddingTop: 20,
      paddingLeft: 50,
      ['@media (max-width:959px)']: { 
        paddingLeft: 0
      }
    },
    center: {
        position: 'absolute',
        top: '50%',
        left: '55%',
        width: 580,
        transform: 'translateX(-50%) translateY(-50%)',
        ['@media (max-width:959px)']: { 
            top: '50%',
            left: '0%',
            position: 'initial',
            transform: 'none',
            width: '100%'
        }
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        ['@media (max-width:959px)']: { 
            width: '90%',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        paddingBottom: 25
    },
    close: {
        fontSize: 24,
        "&:hover": {
            cursor: "pointer"
        }
    },
    fieldsContainer: {
        paddingTop: 0,
        width: 580,
        marginLeft: 'auto',
        marginRight: 'auto',
        ['@media (max-width:959px)']: { 
          width: '90%'
        }
    },
    inputContainer: {
        width: '100%',
        height: 72
    },
    inputContainerDesc: {
        width: '100%',
        marginTop: 15,
    },
    inputFlexContainer: {
        height: 72,
        display: 'flex',
        justifyContent: 'space-between',
        ['@media (max-width:959px)']: { 
            width: '100%',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    },
    input: {
        width: '100%',
        fontSize: 16
    },
    flexLastInput: {
        width: '100%',
        fontSize: 16,
        marginLeft: 20
    },
    saveContainer: {
        paddingTop: 16,
        float: 'right',
        marginTop: 20,
        ['@media (max-width:959px)']: { 
            marginRight: '3%',
            marginTop: '20%'
        }
    },
    saveText: {
        color: '#9F0000',
        fontSize: 16,
        fontWeight: 500,
        display: 'inline-block',
        position: 'absolute',
        marginTop: 7,
        marginLeft: -45,
        width: 44
    },
    saveBtnContainer: {
        width: 36,
        height: 36,
        borderRadius: 12,
        backgroundColor: '#9F0000',
        display: 'inline-block',
        "&:hover": {
            cursor: 'pointer'
        },
    },
    saveBtn: {
        width: 21,
        height: 21,
        position: 'absolute',
        marginTop: 6,
        marginLeft: 8
    },
    flexInput: {
        fontSize: 16,
        width: '25%',
        paddingRight: 20
    },
    flexInputLast: {
        fontSize: 16,
        width: '25%'
    },
    selectService: {
        width: '100%',
        fontFamily: 'Nunito',
        ['@media (max-width:959px)']: { 
            width: 310,
            marginBottom: 20
        }
    },
    serviceLabel: {
        paddingBottom: 20,
        fontSize: 16,
        fontFamily: 'Nunito',
        ['@media (max-width:959px)']: { 
            paddingTop: 65
        }
    },
    deleteContainer: {
        marginTop: 45,
        float: 'left',
        color: '#9F0000',
        "&:hover": {
            cursor: 'pointer'
        },
        ['@media (max-width:959px)']: { 
            marginTop: '20%',
            marginLeft: 15
        }
    },
    formFields: {
        marginLeft: -24,
        marginRight: 24,
    },
    icon: {
        "&:hover": {
            cursor: "pointer"
        },
    },
}));

const ManageBuilding = () => {
    const classes = useStyles();
    const userId = localStorage.getItem('userId');
    const roleId = localStorage.getItem('roleId');
    const history = useHistory();
    const params = useParams();
    const { t, i18n } = useTranslation();
    let [language, setLanguage] = useState('');
    let [mode, setMode] = useState('');
    let [name, setName] = useState('');
    let [nameAr, setNameAr] = useState('');
    let [address, setAddress] = useState('');
    let [addressAr, setAddressAr] = useState('');
    let [building, setBuilding] = useState(null);
    let [washCenters, setWashCenters] = useState([]);
    let [selectedWashCenter, setSelectedWashCenter] = useState(null);
    let [selectedWashCenterObject, setSelectedWashCenterObject] = useState(null);
    let [days, setDays] = useState([]);
    let [selectedDay, setSelectedDay] = useState(null);
    let [selectedDayObject, setSelectedDayObject] = useState(null);
    let [shifts, setShifts] = useState([]);
    let [selectedBuildingShift, setSelectedBuildingShift] = useState(null);
    let [selectedBuildingShiftObject, setSelectedBuildingShiftObject] = useState(null);
    let [paymentMethods, setPaymentMethods] = useState([]);
    let [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
    let [selectedPaymentMethodObject, setSelectedPaymentMethodObject] = useState(null);
    let [formSubmitted, setFormSubmitted] = useState(false);
    const [value, setValue] = React.useState(0);
    let [columns, setColumns] = useState([]);
    let [parkingLevels, setParkingLevels] = useState([]);
    let [tableParkingLevels, setTableParkingLevels] = useState([]);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    let [parkingLevelMode, setParkingLevelMode] = useState('');
    let [parkingLevelName, setParkingLevelName] = useState('');
    let [parkingLevelNameAr, setParkingLevelNameAr] = useState('');
    let [parkingLevelFormSubmitted, setParkingLevelFormSubmitted] = useState(false);
    let [parkingLevelId, setParkingLevelId] = useState(null);
    
    useEffect(() => {
        const language = localStorage.getItem('language');
        if(language) {
            i18n.changeLanguage(language);
        }
        setLanguage(language);

        initializeData();
        setMode(params.id > 0 ? 'edit' : 'add');
    }, [])

    if (!userId) {
        return <Redirect to={'/'} />;
    } 

    const initializeData = () => {
        axios.get(API_URL + `/admin-dashboard/wash-centers`)
        .then(response => {
            let wash_centers = response.data.wash_centers;
            wash_centers = formatSelectData(wash_centers, 'wash_centers');

            axios.get(API_URL + `/shifts`)
            .then(response0 => {
                let shf = response0.data.shifts;
                shf = formatSelectData(shf, 'shifts');
            
                axios.get(API_URL + `/days`)
                .then(response1 => {
                    let dys = response1.data.days;
                    dys = formatSelectData(dys, 'days');


                    axios.get(API_URL + `/payment/methods`)
                    .then(response3 => {
                        let pms = response3.data.payments;
                        pms = formatSelectData(pms, 'payments');

                        if(params.id > 0) {
                            axios.get(API_URL + `/v2/property/parking-levels/${params.id}`)
                            .then(response4 => {
                                let pls = response4.data.parking_levels;
                                initializeParkingLevelsTable(pls);
                                
                                axios.get(API_URL + `/property/${params.id}`)
                                .then(response2 => {
                                    let bldg = response2.data.property;
                                    if(bldg) {
                                        setBuilding(bldg);
                                        setName(bldg.name);
                                        setNameAr(bldg.name_ar);
                                        setAddress(bldg.address);
                                        setAddressAr(bldg.address_ar);
                                        if(bldg.wash_center_id) { setSelectedWashCenterObject(valueFromId(wash_centers, bldg.wash_center_id)); }
                                        if(bldg.property_days && bldg.property_days.length > 0) {
                                            let property_dys = formatSelectData(bldg.property_days, 'property_days');
                                            setSelectedDayObject(property_dys);
                                        }
                                        if(bldg.property_shifts && bldg.property_shifts.length > 0) {
                                            let property_shifts = formatSelectData(bldg.property_shifts, 'property_shifts');
                                            setSelectedBuildingShiftObject(property_shifts);
                                        }
                                        if(bldg.payment_methods && bldg.payment_methods.length > 0) {
                                            let payment_methods = formatSelectData(bldg.payment_methods, 'payment_methods');
                                            setSelectedPaymentMethodObject(payment_methods);
                                        }
                                    }
                                })
                            })
                        }
                    })
                })
            })
        })
    }

    const valueFromId = (opts, id) => opts.find(o => o.value === id);

    const formatSelectData = (array, mode) => {
        let new_array = []
        if(array.length > 0) {
            array.forEach(item => {
                new_array.push({
                    value: item.id,
                    label: language == 'ar' ? ((item.business_name_ar || item.day_ar || item.name_ar) ? (item.business_name_ar || item.day_ar || item.name_ar) : (item.business_name || item.day || item.name)) : (item.business_name || item.day || item.name)
                })
            })
        }

        if(mode == 'wash_centers') { setWashCenters(new_array); }
        if(mode == 'days') { setDays(new_array); }
        if(mode == 'shifts') { setShifts(new_array); }
        if(mode == 'payments') { setPaymentMethods(new_array); }
        return new_array;
    }

    const handleFieldChange = (event, type) => {
        switch(type) {
            case 'name': 
                setName(event.target.value);
                break;
            case 'nameAr': 
                setNameAr(event.target.value);
                break;
            case 'address': 
                setAddress(event.target.value);
                break;
            case 'addressAr': 
                setAddressAr(event.target.value);
                break;
        }
    };

    const handleWashCenterChange = (data) => {
        setSelectedWashCenterObject(data);
        setSelectedWashCenter(data.value);
    };

    const handleShiftChange = (data) => {
        setSelectedBuildingShift(data);
        setSelectedBuildingShiftObject(data);
    };

    const handlePaymentMethodChange = (data) => {
        setSelectedPaymentMethod(data);
        setSelectedPaymentMethodObject(data);
    };

    const handleDayChange = (data) => {
        setSelectedDay(data);
        setSelectedDayObject(data);
    };

    function handleClick(e) {
        setFormSubmitted(true);
        if(!name || !nameAr || !address || !addressAr) {
            showError(t('promo-codes.fillup'))
        } else {
            if(mode == 'add') { 
                addBuilding(); 
            } else {
                editBuilding();
            }
        }
    }

    function addBuilding() {
        axios.post(API_URL + `/property/add`, { 
            name,
            name_ar: nameAr,
            address,
            address_ar: addressAr,
            wash_center_id: selectedWashCenter,
            property_days: selectedDay,
            building_shift: selectedBuildingShift,
            payment_method: selectedPaymentMethod,
        })
        .then(res => {
            if(res.status == 200) {
                toast.success(t("users.successadd"), {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setTimeout(function () {
                    history.push("/buildings");
                }, 2000);
            }
        })
    }

    function editBuilding() {
        axios.post(API_URL + `/property/edit/${params.id}`, {
            name,
            name_ar: nameAr,
            address,
            address_ar: addressAr,
            wash_center_id: selectedWashCenter,
            property_days: selectedDay,
            building_shift: selectedBuildingShift,
            payment_method: selectedPaymentMethod,
        })
        .then(res => {
            if(res.status == 200) {
                toast.success(t("users.successsave"), {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setTimeout(function () {
                    history.push("/buildings");
                }, 2000);
            }
        })
    }

    function showError(message) {
        toast.error(message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function handleClose(e) {
        history.push("/buildings")
    }

    function handleDelete(e) {
        if(params.id) {
            axios.post(API_URL + `/property/delete/${params.id}`, {})
            .then(res => {
                if(res.status == 200) {
                    toast.success(t("users.successdelete"), {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setTimeout(function () {
                        history.push("/buildings");
                    }, 2000);
                }
            })
        } else {
            toast.error(t('promo-codes.deleteerror'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const handleChange = async(event, newValue) => {
        setValue(newValue);
    };

    const handleParkingLevelFieldChange = (event, type) => {
        switch(type) {
            case 'name': 
                setParkingLevelName(event.target.value);
                break;
            case 'nameAr': 
                setParkingLevelNameAr(event.target.value);
                break;
        }
    };

    function handleParkingLevelSave(e) {
        setParkingLevelFormSubmitted(true);
        if(!parkingLevelName || !parkingLevelNameAr) {
            showError(t('promo-codes.fillup'))
        } else {
            if(parkingLevelMode == 'add') { 
                handleAddParkingLevel(); 
            } else {
                handleEditParkingLevel();
            }
        }
    }

    function handleAddParkingLevel() {
        axios.post(API_URL + `/v2/property/parking-level/add`, { 
            name: parkingLevelName,
            name_ar: parkingLevelNameAr,
            property_id: params.id,
        })
        .then(res => {
            if(res.status == 200) {
                toast.success(t("users.successadd"), {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setTimeout(function () {
                    setParkingLevels(res.data.parking_levels);
                    setTableParkingLevels(res.data.parking_levels);
                    setParkingLevelMode('');
                    setParkingLevelName('');
                    setParkingLevelId('');
                    setParkingLevelNameAr('');
                }, 2000);
            }
        })
    }

    function handleEditParkingLevel() {
        axios.post(API_URL + `/v2/property/parking-level/edit/${parkingLevelId}`, {
            name: parkingLevelName,
            name_ar: parkingLevelNameAr,
            property_id: params.id,
        })
        .then(res => {
            if(res.status == 200) {
                toast.success(t("users.successsave"), {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setTimeout(function () {
                    setParkingLevelMode('');
                    setParkingLevels(res.data.parking_levels);
                    setTableParkingLevels(res.data.parking_levels);
                    setParkingLevelMode('');
                    setParkingLevelName('');
                    setParkingLevelId('');
                    setParkingLevelNameAr('');
                }, 2000);
            }
        })
    }

    const addParkingLevel = () => {
        setParkingLevelMode('add');
        setParkingLevelName('');
        setParkingLevelId('');
        setParkingLevelNameAr('');
    }

    const editParkingLevel = (row) => {
        setParkingLevelName(row.name);
        setParkingLevelNameAr(row.name_ar);
        setParkingLevelId(row.id);
        setParkingLevelMode('edit');
    }

    const cancelParkingLevel = () => {
        setParkingLevelMode('');
        setParkingLevelName('');
        setParkingLevelId('');
        setParkingLevelNameAr('');
    }

    const handleDeleteParkingLevel = (row) => {
        if(params.id) {
            axios.post(API_URL + `/v2/property/parking-level/delete/${row.id}`, {})
            .then(res => {
                if(res.status == 200) {
                    toast.success(t("users.successdelete"), {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setTimeout(function () {
                        setParkingLevelMode('');
                        setParkingLevels(res.data.parking_levels);
                        setTableParkingLevels(res.data.parking_levels);
                    }, 2000);
                }
            })
        } else {
            toast.error(t('promo-codes.deleteerror'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const CustomAction = ({ row }) => (
        <div>
            {}
            <div>
                <div
                    data-tag="allowRowEvents"
                    style={{ color: 'grey', overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
                >
                    <div>
                        <Tooltip title="Edit">
                            <EditIcon 
                                className={classes.icon}
                                style={{
                                    color: '#9f0000',
                                    fontSize: 16,
                                }}
                                onClick={() => editParkingLevel(row)}
                            />
                        </Tooltip>
                        <Tooltip title="Cancel booking">
                            <DeleteIcon 
                                className={classes.icon}
                                style={{
                                    color: '#9f0000',
                                    fontSize: 16,
                                    marginLeft: 10,
                                }}
                                onClick={() => handleDeleteParkingLevel(row)}
                            />
                        </Tooltip>
                    </div>
                </div>
            </div>
        </div>
    );

    function initializeParkingLevelsTable(parking_levels) {
        setParkingLevels(parking_levels);
        setTableParkingLevels(parking_levels);
        let table_columns = [
            {
                name: t('buildings.name'),
                selector: row => row.name,
                sortable: true,
            },
            {
                name: t('buildings.namear'),
                selector: row => row.name_ar,
                sortable: true,
            },
            {
                name: t('statement.action'),
                selector: row => row,
                sortable: true,
                cell: row => <CustomAction row={row} />,
            },
        ];
        setColumns(table_columns);
    }

    return (
        <div className="inner">
            <LoggedInTopMenu />
            <div className={classes.content}>
                <Grid
                    justify="space-between"
                    container
                >
                    <Hidden smDown>
                        <div className={classes.sideMenu}>
                            <SideMenu />
                        </div>
                    </Hidden>
                    <ToastContainer />
                    <div className={classes.mainContent}>
                        <div className={classes.article}>
                            <div className={classes.fieldsContainer}>
                                <div className={classes.header}>
                                    <div className={classes.title}>{mode == 'edit' ? t('buildings.editabuilding') : t('buildings.addabuilding')}</div>
                                    <div><CloseIcon className={classes.close} onClick={handleClose} /></div>
                                </div>
                                <AppBar position="static" color="default">
                                    <Tabs 
                                        value={value} 
                                        onChange={handleChange}
                                        variant="scrollable"
                                        scrollButtons="on"
                                        indicatorColor="primary"
                                        textColor="primary"
                                        aria-label="scrollable force tabs example"
                                    >
                                        <Tab label={t('notifications.details')} icon={ 
                                            <ApartmentIcon
                                                style={{
                                                    color: '#9f0000',
                                                    fontSize: 16,
                                                    marginLeft: 10,
                                                    width: 35,
                                                    height: 30,
                                                    objectFit: 'cover'
                                                }}
                                            /> } {...a11yProps(0)} 
                                        />
                                        <Tab label={t('buildings.parkinglevels')} icon={ 
                                            <LocalParkingIcon
                                                style={{
                                                    color: '#9f0000',
                                                    fontSize: 16,
                                                    marginLeft: 10,
                                                    width: 35,
                                                    height: 30,
                                                    objectFit: 'cover'
                                                }}
                                            /> } {...a11yProps(1)} 
                                        />
                                    </Tabs>
                                </AppBar>
                                <TabPanel value={value} index={0}>
                                    <div style={{padding:0}}>
                                        <div className={classes.fieldsContainer}>
                                            <form className={classes.formFields}>
                                                <div className={classes.inputContainerDesc}>
                                                    <TextField 
                                                        id="name"
                                                        label={t('buildings.name')} 
                                                        className={classes.input}
                                                        placeholder={t('buildings.name')}
                                                        required
                                                        error={formSubmitted && !name ? true : false}
                                                        value={name || ''}
                                                        onChange={(event) => handleFieldChange(event, 'name')}
                                                    />
                                                </div>
                                                <div className={classes.inputContainerDesc}>
                                                    <TextField 
                                                        id="nameAr"
                                                        label={t('buildings.namear')}
                                                        className={classes.input}
                                                        placeholder={t('buildings.namear')}
                                                        required
                                                        error={formSubmitted && !nameAr ? true : false}
                                                        value={nameAr || ''}
                                                        onChange={(event) => handleFieldChange(event, 'nameAr')}
                                                    />
                                                </div>
                                                <div className={classes.inputContainerDesc}>
                                                    <TextField 
                                                        id="address"
                                                        label={t('buildings.address')} 
                                                        multiline
                                                        className={classes.input}
                                                        placeholder={t('buildings.address')}
                                                        required
                                                        error={formSubmitted && !address ? true : false}
                                                        value={address || ''}
                                                        onChange={(event) => handleFieldChange(event, 'address')}
                                                    />
                                                </div>
                                                <div className={classes.inputContainerDesc}>
                                                    <TextField 
                                                        id="addressAr"
                                                        label={t('buildings.addressar')} 
                                                        multiline
                                                        className={classes.input}
                                                        placeholder={t('buildings.addressar')}
                                                        required
                                                        error={formSubmitted && !addressAr ? true : false}
                                                        value={addressAr || ''}
                                                        onChange={(event) => handleFieldChange(event, 'addressAr')}
                                                    />
                                                </div>
                                                <div style={{marginTop:25}}>
                                                    <Select
                                                        placeholder={t('buildings.selectdays')}
                                                        value={selectedDayObject}
                                                        onChange={handleDayChange}
                                                        options={days}
                                                        isMulti
                                                    />
                                                </div>
                                                <div style={{marginTop:25}}>
                                                    <Select
                                                        placeholder={t('users.selectshift')}
                                                        value={selectedBuildingShiftObject}
                                                        onChange={handleShiftChange}
                                                        options={shifts}
                                                        required
                                                        isMulti
                                                    />
                                                </div>
                                                <div style={{marginTop:25}}>
                                                    <Select
                                                        placeholder={t('buildings.selectpaymentmethod')}
                                                        value={selectedPaymentMethodObject}
                                                        onChange={handlePaymentMethodChange}
                                                        options={paymentMethods}
                                                        required
                                                        isMulti
                                                    />
                                                </div>
                                                <div style={{marginTop:25}}>
                                                    <Select
                                                        placeholder={t('addbooking.selectwashcenter')}
                                                        value={selectedWashCenterObject}
                                                        onChange={handleWashCenterChange}
                                                        options={washCenters}
                                                        name="washcenter"
                                                        required
                                                    />
                                                </div>
                                            </form>
                                        </div>
                                        <div>
                                            {mode == 'edit' &&
                                                <div 
                                                    className={classes.deleteContainer}
                                                    onClick={handleDelete}
                                                >
                                                    {t('promo-codes.delete')}
                                                </div>
                                            }
                                            <div className={classes.saveContainer}>
                                                <div className={classes.saveText}>{t('promo-codes.save')}</div>
                                                <div 
                                                    onClick={handleClick}
                                                    className={classes.saveBtnContainer} 
                                                >
                                                    <img 
                                                        className={classes.saveBtn} 
                                                        src={arrowrightwhiteimage} 
                                                        alt="Carcare" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <div>
                                        {(parkingLevelMode != 'add' && parkingLevelMode != 'edit') &&
                                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                                <a style={{display:'flex',flexDirection:'row',alignItems:'center',textDecoration:'none',color:'#9F0000'}} href="#" onClick={() => addParkingLevel()}>
                                                    <AddIcon className={{position:'absolute'}} />
                                                    <div className={{marginLeft:30}}>{t('buildings.addparkinglevel')}</div>
                                                </a>
                                            </div>
                                        }
                                        {(parkingLevelMode == 'add' || parkingLevelMode == 'edit') &&
                                            <>
                                                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                                    <a style={{display:'flex',flexDirection:'row',alignItems:'center',textDecoration:'none',color:'#9F0000'}} href="#" onClick={() => cancelParkingLevel()}>
                                                        <div className={{marginLeft:30}}>{t('services.cancel')}</div>
                                                    </a>
                                                </div>
                                                <div style={{marginTop:20,marginBottom:20}}>
                                                    <div className={classes.fieldsContainer}>
                                                        <form className={classes.formFields}>
                                                            <div className={classes.inputContainerDesc}>
                                                                <TextField 
                                                                    id="name"
                                                                    label={t('buildings.name')} 
                                                                    className={classes.input}
                                                                    placeholder={t('buildings.name')}
                                                                    required
                                                                    error={parkingLevelFormSubmitted && !parkingLevelName ? true : false}
                                                                    value={parkingLevelName || ''}
                                                                    onChange={(event) => handleParkingLevelFieldChange(event, 'name')}
                                                                />
                                                            </div>
                                                            <div className={classes.inputContainerDesc}>
                                                                <TextField 
                                                                    id="nameAr"
                                                                    label={t('buildings.namear')}
                                                                    className={classes.input}
                                                                    placeholder={t('buildings.namear')}
                                                                    required
                                                                    error={parkingLevelFormSubmitted && !parkingLevelNameAr ? true : false}
                                                                    value={parkingLevelNameAr || ''}
                                                                    onChange={(event) => handleParkingLevelFieldChange(event, 'nameAr')}
                                                                />
                                                            </div>
                                                        </form>
                                                    </div>
                                                    <div className={classes.saveContainer}>
                                                        <div className={classes.saveText}>{t('promo-codes.save')}</div>
                                                        <div 
                                                            onClick={handleParkingLevelSave}
                                                            className={classes.saveBtnContainer} 
                                                        >
                                                            <img 
                                                                className={classes.saveBtn} 
                                                                src={arrowrightwhiteimage} 
                                                                alt="Carcare" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        <div style={{marginTop:20}}>
                                            <div>
                                                <DataTable
                                                    columns={columns}
                                                    data={tableParkingLevels}
                                                    pagination
                                                    paginationResetDefaultPage={resetPaginationToggle}
                                                    persistTableHead
                                                    fixedHeader
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                            </div>
                        </div>
                    </div>
                </Grid>
            </div>
        </div>
    );
};
  
export default ManageBuilding;
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useParams } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { Grid } from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';
import { API_URL } from '../constants';
import { useTranslation } from 'react-i18next';
import LoggedInTopMenu from '../components/LoggedInTopMenu';
import SideMenu from '../components/SideMenu';
import Hidden from '@material-ui/core/Hidden';
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import DataTable from 'react-data-table-component';
import Select from 'react-select';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  content: {
    paddingTop: 130,
    marginLeft: '-6%'
  },
  sideMenu: {
    backgroundColor: '#9F0000',
    width: 256,
    height: `calc(100vh - 130px)`,
    borderTopRightRadius: 75,
    color: '#fff',
    paddingTop: 70
  },
  mainContent: {
    width: `calc(100% - 256px)`,
    textAlign: 'left',
    ['@media (max-width:959)']: { 
      width: '100%',
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  article: {
    paddingTop: 20,
    paddingLeft: 50
  },
  activePageHeader: {
    fontSize: 20,
    fontWeight: 'bold',
    paddingRight: 40,
    color: '#000',
    textDecoration: 'none',
  },
  pageHeader: {
    fontSize: 20,
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  serviceHeader: {
    paddingTop: 20,
    borderBottomWidth: 1,
    borderBottomColor: '#d3d3d3',
    borderBottomStyle: 'solid'
  },
  indentedHeader: {
    color: '#000',
    opacity: 0.25,
    fontSize: 16,
    textAlign: 'left',
    paddingLeft: '3%',
    ['@media (max-width:959px)']: { 
      paddingLeft: 0
    },
    ['@media (min-width:959px)']: { 
      visibility: 'hidden'
    }
  },
  leftHeader: {
    color: '#000',
    opacity: 0.25,
    fontSize: 16,
    textAlign: 'left',
    ['@media (min-width:959px)']: { 
      visibility: 'hidden'
    }
  },
  header: {
    color: '#000',
    opacity: 0.25,
    fontSize: 16,
    textAlign: 'center',
  },
  col1: {
    textAlign: 'left',
    height: 60,
    borderBottomWidth: 1,
    borderBottomColor: '#d3d3d3',
    borderBottomStyle: 'solid',
    width: '25%',
    paddingLeft: '3%',
    verticalAlign: 'baseline',
    ['@media (max-width:768px)']: { 
      width: '100%',
      paddingLeft: 0
    }
  },
  col2: {
    textAlign: 'left',
    height: 60,
    borderBottomWidth: 1,
    borderBottomColor: '#d3d3d3',
    borderBottomStyle: 'solid',
    width: '5%',
    verticalAlign: 'baseline',
    ['@media (max-width:768px)']: { 
      width: '100%'
    }
  },
  col3: {
    textAlign: 'center',
    height: 60,
    borderBottomWidth: 1,
    borderBottomColor: '#d3d3d3',
    borderBottomStyle: 'solid',
    width: '10%',
    verticalAlign: 'baseline',
    ['@media (max-width:768px)']: { 
      width: '100%'
    }
  },
  col4: {
    textAlign: 'center',
    height: 60,
    borderBottomWidth: 1,
    borderBottomColor: '#d3d3d3',
    borderBottomStyle: 'solid',
    width: '10%',
    verticalAlign: 'baseline',
    ['@media (max-width:768px)']: { 
      width: '100%'
    }
  },
  col5: {
    textAlign: 'center',
    height: 60,
    borderBottomWidth: 1,
    borderBottomColor: '#d3d3d3',
    borderBottomStyle: 'solid',
    width: '10%',
    verticalAlign: 'baseline',
    ['@media (max-width:768px)']: { 
      width: '100%'
    }
  },
  col6: {
    textAlign: 'left',
    height: 60,
    borderBottomWidth: 1,
    borderBottomColor: '#d3d3d3',
    borderBottomStyle: 'solid',
    width: '25%',
    verticalAlign: 'baseline',
    ['@media (max-width:768px)']: { 
      width: '100%',
      height: 'auto'
    }
  },
  col7: {
    textAlign: 'center',
    height: 60,
    borderBottomWidth: 1,
    borderBottomColor: '#d3d3d3',
    borderBottomStyle: 'solid',
    width: '15%',
    verticalAlign: 'baseline',
    ['@media (max-width:768px)']: { 
      width: '100%'
    }
  },
  colAdd: {
    paddingLeft: '3%',
    height: 60,
    borderBottomWidth: 1,
    borderBottomColor: '#d3d3d3',
    borderBottomStyle: 'solid',
    ['@media (max-width:768px)']: { 
      width: '100%',
      paddingLeft: 0
    }
  },
  addService: {
    textDecoration: 'none',
    color: '#9F0000'
  },
  addIcon: {
    position: 'absolute'
  },
  addServiceText: {
    marginLeft: 30,
  },
  descriptionText: {
    color: '#a7a7a7',
    fontSize: 14,
    whiteSpace: 'pre-wrap'
  },
  serviceLink: {
    color: '#9F0000'
  },
  fieldsContainer: {
    marginTop: 20,
    width: 110,
    ['@media (max-width:959px)']: { 
      width: '100%',
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  timerContainer: {
    marginTop: 20,
    width: 110,
    ['@media (max-width:959px)']: { 
      width: '100%',
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  inputContainer: {
    width: '100%',
    height: 65
  },
  input: {
    width: '100%',
    fontSize: 16
  },
  saveContainer: {
    marginLeft: 65,
    marginTop: 10
  },
  saveText: {
      color: '#9F0000',
      fontSize: 16,
      fontWeight: 500,
      display: 'inline-block',
      position: 'absolute',
      marginTop: 7,
      marginLeft: -62,
      width: 44
  },
  saveBtnContainer: {
    width: 36,
    height: 36,
    borderRadius: 12,
    backgroundColor: '#9F0000',
    display: 'inline-block',
    "&:hover": {
      cursor: 'pointer'
    },
  },
  saveBtn: {
    width: 21,
    height: 21,
    paddingLeft: 3,
    position: 'absolute',
    marginLeft: 7,
    marginTop: 6
  },
  updateInfo: {
    display: 'flex',
    position: 'absolute',
    right: 200,
    ['@media (max-width:959px)']: { 
      position: 'initial',
      width: '90%',
      display: 'block',
      right: 'initial',
      marginLeft: -10
    }
  },
  flexContainer: {
    display: 'flex',
    ['@media (max-width:959px)']: { 
      width: '100%',
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  flexWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    ['@media (max-width:959px)']: { 
      width: '100%',
      display: 'block'
    }
  },
  save1Container: {
    marginLeft: 65,
    marginTop: 10
  },
  formControl: {
    width: 250,
    "&:focus": {
      boxShadow: "none",
      outline: "none"
    },
    "&:active": {
      boxShadow: "none",
      outline: "none"
    }
  },
  applyContainer: {
    display: 'inline-block',
    marginTop: 0,
    marginLeft: 80
  },
  ratingsContainer: {
    display: 'flex',
    flexDirection: 'row',
    margin: '0 auto',
    justifyContent: 'flex-start',
    ['@media (max-width:768px)']: { 
      justifyContent: 'flex-start'
    }
  },
  tabContainer: {
    marginTop: 40,
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  buildingInput: {
    marginRight: 15
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 15,
    marginBottom: 20,
    width: '100%',
    ['@media (max-width:959px)']: { 
        width: '100%',
    }
  },
  input: {
    width: 280,
    fontSize: 16,
    marginRight: 15,
    ['@media (max-width:959px)']: { 
      width: '100%',
      marginBottom: 15,
    }
  },
  filterDiv: {
    marginTop: 10,
    float: 'left',
    backgroundColor: '#9F0000',
    color: '#fff',
    borderRadius: 10,
    marginRight: 15,
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 6,
    height: 34,
    "&:hover": {
        cursor: 'pointer'
    }
},
  resetDiv: {
    marginTop: 10,
    float: 'left',
    color: '#9F0000',
    borderColor: '#9F0000',
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 10,
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 6,
    height: 34,
    "&:hover": {
        cursor: 'pointer'
    }
  },
}));

const Packages = () => {
  const classes = useStyles();
  const userId = localStorage.getItem('userId');
  const roleId = localStorage.getItem('roleId');
  const history = useHistory();
  let [language, setLanguage] = useState('');
  let [searchKeyword, setSearchKeyword] = useState('');
  let [columns, setColumns] = useState([]);
  let [packageTypes, setPackageTypes] = useState([]);
  let [selectedPackageType, setSelectedPackageType] = useState('');
  let [packageSubscriptionTypes, setPackageSubscriptionTypes] = useState([]);
  let [selectedPackageSubscriptionType, setSelectedPackageSubscriptionType] = useState('');
  let [selectedPackageSubscriptionTypeObject, setSelectedPackageSubscriptionTypeObject] = useState(null);
  let [buildings, setBuildings] = useState(null);
  let [selectedBuilding, setSelectedBuilding] = useState(null);
  let [selectedBuildingObject, setSelectedBuildingObject] = useState(null);
  let [packages, setPackages] = useState([]);
  let [allPackages, setAllPackages] = useState([]);
  let [services, updateServices] = useState('');
  let [allServices, setAllServices] = useState('');
  let [managerProperties, setManagerProperties] = useState([]);
  const [value, setValue] = React.useState(0);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const { t, i18n } = useTranslation();

  const CustomTitle = ({ row }) => (
    <div>
        {}
        <div>
            <div
                data-tag="allowRowEvents"
                style={{ color: 'grey', overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
            >
                {}
                <a className={classes.serviceLink} href={`/package/${row.id}/${(selectedPackageType?.id || 1)}`}>{language == 'ar' ? row.name_ar : row.name}</a>
            </div>
        </div>
    </div>
  );

  const CustomMove = ({ row }) => (
    <div>
        {}
        <div>
            <div
                data-tag="allowRowEvents"
                style={{ color: 'grey', overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
            >
                {}
                <div style={{display:'flex', flexDirection: 'column'}}>
                  <KeyboardArrowUpIcon 
                        className={classes.icon}
                        style={{
                            color: '#9f0000',
                            fontSize: 24
                        }}
                        onClick={() => moveUp(row)}
                    />
                  <KeyboardArrowDownIcon 
                      className={classes.icon}
                      style={{
                          color: '#9f0000',
                          fontSize: 24
                      }}
                      onClick={() => moveDown(row)}
                  />
                </div>
            </div>
        </div>
    </div>
  );

  useEffect(() => {
    const language = localStorage.getItem('language');
    if(language) {
      i18n.changeLanguage(language);
    }
    setLanguage(language);

    getPackages();
      let table_columns = [
        {
          name: '',
          selector: row => row.order,
          cell: (row, index) => <CustomMove row={row} />
        },
        {
          name: t('services.type'),
          selector: row => (language == 'ar' ? row.package_subscription_type_name_ar : row.package_subscription_type_name),
          sortable: true,
        },
        {
          name: t('buildings.name'),
          selector: row => row.name,
          sortable: true,
          cell: row => <CustomTitle row={row} />,
        },
        {
          name: t('services.duration'),
          selector: row => (row.duration + (row.duration == 1 ? (row.unit == row.unit_to ? '' : row.unit) : (row.unit + 's')) + (row.duration_to ? ('-' + row.duration_to + (row.duration_to == 1 ? row.unit_to : (row.unit_to + 's'))) : '')),
          sortable: true,
        },
        {
          name: 'S',
          selector: row => row.small_price,
          sortable: true,
        },
        {
          name: 'M',
          selector: row => row.medium_price,
          sortable: true,
        },
        {
          name: 'L',
          selector: row => row.large_price,
          sortable: true,
        },
      ];
      setColumns(table_columns);
  }, [])
  
  if (!userId) {
    return <Redirect to={'/login'} />;
  } 

  const moveUp = (row) => {
    let all_packages = localStorage.getItem('packages') ? JSON.parse(localStorage.getItem('packages')) : [];
    if (row.index >= 1) {
      swap(all_packages, row.index, row.index - 1);
      updatePackageOrder(all_packages);
    }
  }

  const moveDown = (row) => {
    let all_packages = localStorage.getItem('packages') ? JSON.parse(localStorage.getItem('packages')) : [];
    if (row.index < all_packages.length - 1) {
      swap(all_packages, row.index, row.index + 1);
      updatePackageOrder(all_packages);
    }
  }

  const swap = (array, x, y) => {
    var b = array[x];
    array[x] = array[y];
    array[y] = b;
  }

  const updatePackageOrder = (array) => {
    let new_array = array?.filter(item => {
      return item?.id
    })
    axios.post(API_URL + `/package/order/update`, { 
      list: new_array
    })
    .then(res => {
      if(res.status == 200) {
        toast.success(t("users.successsave"), {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(function () {
          window.location.reload()
        }, 2000);
      }
    })
  }

  const getPackages = () => {
    axios.get(API_URL + `/package-types`)
    .then(response => {
      let pts = response.data.package_types
      setPackageTypes(pts);

      axios.get(API_URL + `/package-subscription-types`)
      .then(response0 => {
        let pst = response0.data.package_subscription_types
        pst = formatSelectData(pst, 'package_subscription_types');

        axios.get(API_URL + `/properties`)
        .then(resp => {
          let all_bldgs = resp.data.properties;
          if(roleId == 5) {
            axios.get(API_URL + `/manager-properties`)
            .then(resp => {
              let manager_properties = resp.data.manager_properties;
              setManagerProperties(manager_properties)
              let filtered_bldgs = []
              if(all_bldgs?.length > 0) {
                filtered_bldgs = all_bldgs.filter(mp => {
                  return manager_properties?.some(a => a.property_id == mp.id && a.user_id == userId)
                })
              } else {
                filtered_bldgs = all_bldgs
              }
              loadPackages(filtered_bldgs, pts, manager_properties)
            })
          } else {
            loadPackages(all_bldgs, pts, [])
          }
        })
      })
    })
  }

  const loadPackages = (all_bldgs, pts, manager_properties) => {
    let bldng = formatSelectData(all_bldgs, 'properties');
    axios.get(API_URL + `/packages`)
      .then(response1 => {
        let pck = response1.data.packages;
        if(pck?.length > 0) {
          pck = pck.sort((a, b) => {
              return a.id - b.id
          })
      }
        let filtered_manager_properties = manager_properties?.filter(mp => {
          return mp.user_id == userId
        })

        if(roleId == 5 && filtered_manager_properties?.length > 0 && pck?.length > 0) {
          let filtered_packages = []
          pck.forEach(p => {
            if(p.properties?.length > 0) {
              p.properties.forEach(pr => {
                let match = filtered_manager_properties.some(a => a.property_id == pr.property_id)
                if(match) {
                  filtered_packages.push(p)
                }
              })
            }
          })
          pck = filtered_packages
        }

        setPackages(pck);
        setAllPackages(pck);
        localStorage.setItem('packages', JSON.stringify(packages))

        if(pts && pts.length > 0) {
          loadTabsData(0, pck, pts[0])
        }
      })
  }

  const formatSelectData = (array, mode) => {
    let new_array = []
    if(array.length > 0) {
        array.forEach(item => {
            new_array.push({
                value: item.id,
                label: language == 'ar' ? ((item.name_ar) ? (item.name_ar) : (item.name)) : (item.name)
            })
        })
    }

    if(mode == 'package_subscription_types') { setPackageSubscriptionTypes(new_array); }
    if(mode == 'properties') { setBuildings(new_array); }
    return new_array;
  }

  function showSelectedServices(array, selected) {
    if(array.length > 0) {
      let svcs = array && array.filter(arr => {
        return arr.package_type_id == selected.id
      })
      let new_svcs = [...svcs].sort((a, b) => a.order - b.order || a.package_subscription_type_id - b.package_subscription_type_id);
      localStorage.setItem('packages', JSON.stringify(new_svcs))
      new_svcs = new_svcs?.map((p, index) => {
        return {
          ...p,
          index: index,
        };
      });
      setAllServices(new_svcs);
      updateServices(new_svcs);
    }
  }

  const handleFieldChange = (event, type) => {
    switch(type) {
      case 'searchKeyword': 
        setSearchKeyword(event.target.value)
        handleFilter(event.target.value)
        break;
    }
  };

  const handleChange = async(event, newValue) => {
    setValue(newValue);

    if(packageTypes && packageTypes.length > 0) {
      setSelectedPackageType(packageTypes[newValue]);
      showSelectedServices(allPackages, packageTypes[newValue]);
    }
  };

  function handleFilter(search) {
    let svcs = allServices

    if(svcs.length > 0 && search.length > 0) {
      svcs = svcs.filter(s => {
        let include = false

        if(s.name && search && s.name.toLowerCase().indexOf(search.toLowerCase()) >= 0) {
          include = true
        }

        return include
      })
    }

    let new_svcs = [...svcs].sort((a, b) => a.order - b.order || a.package_subscription_type_id - b.package_subscription_type_id);
    localStorage.setItem('packages', JSON.stringify(new_svcs))
    new_svcs = new_svcs?.map((p, index) => {
      return {
        ...p,
        index: index,
      };
    });
    localStorage.setItem('packages', JSON.stringify(new_svcs))
    updateServices(new_svcs)
  }

  const handlePackageSubscriptionTypeChange = (data) => {
    setSelectedPackageSubscriptionTypeObject(data);
    setSelectedPackageSubscriptionType(data.value);
  };

  const handleBuildingChange = (data) => {
    setSelectedBuildingObject(data);
    setSelectedBuilding(data);
  };

  function handleReset(e) {
    setSelectedPackageSubscriptionTypeObject('');
    setSelectedPackageSubscriptionType('');
    setSelectedBuildingObject('');
    setSelectedBuilding('');
    setSearchKeyword('');
    loadTabsData(0, allPackages, packageTypes[0]);
  }

  function handleFilterPackages(e) {
    let svcs = allServices

    if(searchKeyword.length > 0) {
      svcs = svcs.filter(s => {
        let include = false

        if(s.name && searchKeyword && s.name.toLowerCase().indexOf(searchKeyword.toLowerCase()) >= 0) {
          include = true
        }

        return include
      })
    }

    if(selectedPackageSubscriptionType) {
      svcs = svcs && svcs.filter(s => {
        return s.package_subscription_type_id == selectedPackageSubscriptionType
      })
    }

    if(selectedBuilding && selectedBuilding.length > 0) {
      svcs = svcs && svcs.filter(s => {
        let include = false
        selectedBuilding.forEach(b => {
          let found = s.properties && s.properties.some(a => a.property_id == b.value)
          if(found) {
            include = true
          }
        })

        return include
      })
    }

    let new_svcs = [...svcs].sort((a, b) => a.order - b.order || a.package_subscription_type_id - b.package_subscription_type_id);
    localStorage.setItem('packages', JSON.stringify(new_svcs))
    new_svcs = new_svcs?.map((p, index) => {
      return {
        ...p,
        index: index,
      };
    });
    localStorage.setItem('packages', JSON.stringify(new_svcs))
    updateServices(new_svcs)
  }

  const loadTabsData = (tabIndex, packages_array, package_type) => {
    setValue(tabIndex);
    setSelectedPackageType(package_type);
    showSelectedServices(packages_array, package_type);
  }

  return (
    <div className="inner">
      <LoggedInTopMenu />
      <div className={classes.content}>
        <Grid
          justify="space-between"
          container
        >
          <Hidden smDown>
            <div className={classes.sideMenu}>
              <SideMenu />
            </div>
          </Hidden>
          <div id="services-maincontent" className={classes.mainContent}>
            <div className={classes.article}>
                <ToastContainer />
                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <a key="1" href="#" className={classes.activePageHeader}>{t('side.packages')}</a>  
                    <a className={classes.addService} href={'/package/0/' + (selectedPackageType?.id || 1)}>
                      <AddIcon className={classes.addIcon} />
                      <div className={classes.addServiceText}>{t('packages.addapackage')}</div>
                    </a>
                </div>
                <div className={classes.filterContainer}>
                  <div>
                    <TextField 
                      id="searchKeyword"
                      label={t('dashboard.search')} 
                      className={classes.input}
                      placeholder={t('dashboard.search')}
                      value={searchKeyword || ''}
                      onChange={(event) => handleFieldChange(event, 'searchKeyword')}
                    />
                  </div>
                  <div style={{marginTop:8}}>
                    <Select
                      className={classes.input}
                      placeholder={t('packages.selecttype')}
                      value={selectedPackageSubscriptionTypeObject}
                      onChange={handlePackageSubscriptionTypeChange}
                      options={packageSubscriptionTypes}
                      name="packagetype"
                      required
                    />
                  </div>
                  <div style={{marginTop:8}}>
                    <Select
                      placeholder={t('users.selectbuilding')}
                      value={selectedBuildingObject}
                      onChange={handleBuildingChange}
                      options={buildings}
                      name="building"
                      required
                      className={classes.input}
                      isMulti
                    />
                  </div>
                  <div 
                    className={classes.filterDiv}
                    onClick={handleFilterPackages}
                  >
                    {t('statement.filter')}
                  </div>
                  <div 
                    className={classes.resetDiv}
                    onClick={handleReset}
                  >
                    {t('dashboard.reset')}
                  </div>
                </div>
                <div id="tab-container" className={classes.tabContainer}>
                  {packageTypes && packageTypes.length > 0 &&
                  <AppBar position="static" color="default">
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      variant="scrollable"
                      scrollButtons="on"
                      indicatorColor="primary"
                      textColor="primary"
                      aria-label="scrollable force tabs example"
                    >
                      {packageTypes && packageTypes.length > 0 && packageTypes.map((item, index) => (
                        <Tab label={language == 'ar' ? item?.name_ar : item?.name} 
                        icon={ 
                          <img 
                            src={API_URL + '/car/type/image/' + (item?.id == 1 ? 'monthly_packages.png' : 'wash_types.png')} 
                            style={{
                              width: 35,
                              height: 30,
                              objectFit: 'cover'
                            }}
                          /> } {...a11yProps(index)} />
                      ))} 
                    </Tabs>
                  </AppBar>
                  }
                  <TabPanel value={value} index={value}>
                    <DataTable
                      columns={columns}
                      data={services}
                      pagination
                      paginationResetDefaultPage={resetPaginationToggle}
                      persistTableHead
                      fixedHeader
                    />
                  </TabPanel>
                </div>
            </div>
          </div>
        </Grid>
      </div>
    </div>
  );
};
  
export default Packages;
import React, { useRef, useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    menu: {
        color: '#fff',
        textDecoration: 'none',
        fontSize: 18,
        fontWeight: 500,
        display: 'block',
        paddingBottom: 30,
        textAlign: 'left',
        paddingLeft: 64,
        opacity: 0.25,
        "&:hover": {
            cursor: "pointer",
            color: '#FFF !important'
        }
    },
    logout: {
        color: '#fff',
        textDecoration: 'none',
        fontSize: 18,
        fontWeight: 500,
        display: 'block',
        bottom: 35,
        textAlign: 'left',
        paddingLeft: 64,
        position: 'absolute',
        opacity: 0.25
    }
}));

const SideMenu = () => {
    const classes = useStyles();
    const history = useHistory();
    const { t, i18n } = useTranslation();
    const roleId = localStorage.getItem('roleId');
    const id = localStorage.getItem('id');

    useEffect(() => {
        const language = localStorage.getItem('language');
        if(language) {
            i18n.changeLanguage(language);
        }
    }, [])

    function handleLogout(e) {
        localStorage.clear()
        history.push("/")
    }

    return (
        <div>
            <NavLink
                to='/main'
                exact
                activeStyle={{
                    fontWeight: "bold",
                    color: "#fff",
                    opacity: 1
                }}
                className={classes.menu}
            >
                {t('side.main')}
            </NavLink>
            <NavLink
                to='/dashboard'
                exact
                activeStyle={{
                    fontWeight: "bold",
                    color: "#fff",
                    opacity: 1
                }}
                className={classes.menu}
            >
                {t('side.dashboard')}
            </NavLink>
            <NavLink
                to='/buildings'
                exact
                activeStyle={{
                    fontWeight: "bold",
                    color: "#fff",
                    opacity: 1
                }}
                className={classes.menu}
            >
                {t('dashboard.buildings')}
            </NavLink>
            <NavLink
                to='/ratings'
                exact
                activeStyle={{
                    fontWeight: "bold",
                    color: "#fff",
                    opacity: 1
                }}
                className={classes.menu}
            >
                {t('side.feedback')}
            </NavLink>
            <NavLink
                to='/packages'
                exact
                activeStyle={{
                    fontWeight: "bold",
                    color: "#fff",
                    opacity: 1
                }}
                className={classes.menu}
            >
                {t('side.packages')}
            </NavLink>
            <NavLink
                to='/promo-codes'
                exact
                activeStyle={{
                    fontWeight: "bold",
                    color: "#fff",
                    opacity: 1
                }}
                className={classes.menu}
                >
                {t('side.promocodes')}
            </NavLink>
            <NavLink
                to='/bookings'
                exact
                activeStyle={{
                    fontWeight: "bold",
                    color: "#fff",
                    opacity: 1
                }}
                className={classes.menu}
            >
                {t('side.bookings')}
            </NavLink>
            {roleId == 3 &&
                <NavLink
                    to='/users'
                    exact
                    activeStyle={{
                        fontWeight: "bold",
                        color: "#fff",
                        opacity: 1
                    }}
                    className={classes.menu}
                >
                    {t('users.users')}
                </NavLink>
            }
            <NavLink
                to='/notifications'
                exact
                activeStyle={{
                    fontWeight: "bold",
                    color: "#fff",
                    opacity: 1
                }}
                className={classes.menu}
            >
                {t('side.notifications')}
            </NavLink>
            {roleId == 3 &&
                <NavLink
                    to='/notificationmessages'
                    exact
                    activeStyle={{
                        fontWeight: "bold",
                        color: "#fff",
                        opacity: 1
                    }}
                    className={classes.menu}
                >
                    {t('side.notificationmessages')}
                </NavLink>
            }
            <a 
                href="#" 
                className={classes.logout}
                onClick={handleLogout}
            >
                {t('side.logout')}
            </a>
        </div>
    );
}

export default SideMenu;


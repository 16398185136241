import React, { useRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from "react-router-dom";
import { Grid } from '@material-ui/core';
import { API_URL } from '../constants';
import { I18nContext, useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ToastContainer, toast } from 'react-toastify';
import TextField from '@material-ui/core/TextField';
import LoggedInTopMenu from '../components/LoggedInTopMenu';
import SideMenu from '../components/SideMenu';
import Hidden from '@material-ui/core/Hidden';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import LocalCarWashIcon from '@material-ui/icons/LocalCarWash';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import SaveIcon from '@material-ui/icons/Save';
import axios from 'axios';
import arrowrightwhiteimage from '../images/arrow-right-white.png'; 
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import starImage from '../images/star.png'; 
import buildingImage from '../images/building.png'; 
import electricityImage from '../images/electricity.png'; 
import vatImage from '../images/vat.jpeg'; 
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import DescriptionIcon from '@material-ui/icons/Description';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Box from '@material-ui/core/Box';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles(theme => ({
    content: {
      paddingTop: 130,
      marginLeft: '-6%'
    },
    sideMenu: {
      backgroundColor: '#9F0000',
      width: 256,
      height: `calc(100vh - 130px)`,
      borderTopRightRadius: 75,
      color: '#fff',
      paddingTop: 70
    },
    mainContent: {
      width: `calc(100% - 256px)`,
      textAlign: 'left',
      ['@media (max-width:959px)']: { 
        width: '95%'
      }
    },
    article: {
      paddingTop: 20,
      paddingLeft: 50
    },
    cardContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexWrap: 'wrap'
    },
    card: {
      backgroundColor: '#f7f7f7',
      borderRadius: 20,
      width: 290,
      marginRight: 10,
      marginBottom: 20
    },
    top: {
      borderBottomWidth: 1,
      borderBottomColor: '#d3d3d3',
      borderBottom: 'solid',
      paddingTop: 15, 
      paddingRight: 20, 
      paddingBottom: 55,
      paddingLeft: 20,
    },
    bottom: {
      padding: 20
    },
    washContainer: {
      display: 'flex',
      marginBottom: 0
    },
    center: {
      width: 36,
      height: 36,
      borderRadius: 12,
      objectFit: 'cover'
    },
    centerDetails: {
      position: 'absolute',
      marginTop: 5,
      fontSize: 14,
      fontWeight: 'bold',
      width: 140,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    centerNameLink: {
      color: '#000',
      textDecoration: 'none'
    },
    flexContainer: {
      display: 'flex'
    },
    iconWrapper: {
      backgroundColor: '#fff',
      width: 28,
      height: 28,
      borderRadius: 10,
      marginLeft: 4,
      marginRight: 4,
      marginBottom: 10
    },
    detail: {
      fontSize: 14,
      marginTop: 5,
      marginLeft: 15
    },
    statusContainer: {
      textAlign: 'center',
      height: 36,
      backgroundColor: '#9F0000',
      borderRadius: 12,
      paddingTop: 6,
      marginTop: 10,
      "&:hover": {
          cursor: "pointer"
      }
    },
    status: {
      color: '#fff',
      textDecoration: 'none',
      fontSize: 14,
      "&:hover": {
          cursor: "pointer",
          color: '#FFF !important'
      }
    },
    formControl: {
      width: 250,
      marginBottom: 20,
      "&:focus": {
        boxShadow: "none",
        outline: "none"
      },
      "&:active": {
        boxShadow: "none",
        outline: "none"
      }
    },
    applyContainer: {
      display: 'inline-block',
      marginTop: 0,
      marginLeft: 80
    },
    ratingsContainer: {
      display: 'flex',
      flexDirection: 'row',
      margin: '0 auto',
      justifyContent: 'flex-start',
      ['@media (max-width:768px)']: { 
        justifyContent: 'flex-start'
      }
    },
    saveText: {
      color: '#9F0000',
      fontSize: 16,
      fontWeight: 500,
      display: 'inline-block',
      position: 'absolute',
      marginTop: 7,
      marginLeft: -62,
      width: 44
  },
  saveBtnContainer: {
    width: 36,
    height: 36,
    borderRadius: 12,
    backgroundColor: '#9F0000',
    display: 'inline-block',
    "&:hover": {
      cursor: 'pointer'
    },
  },
  saveBtn: {
    width: 21,
    height: 21,
    paddingLeft: 3,
    position: 'absolute',
    marginLeft: 7,
    marginTop: 6
  },
  deleteIcon: {
    fontSize: 23,
    color: '#9F0000',
    "&:hover": {
        cursor: "pointer"
    }
  },

  activateBuilding: {
    textAlign: 'center',
    padding: `8px 12px`,
    borderRadius: 12,
    backgroundColor: '#F9D2D2',
    color: '#9F0000',
    "&:hover": {
        cursor: "pointer"
    }
  },
  deactivateBuilding: {
    textAlign: 'center',
    padding: `8px 12px`,
    borderRadius: 12,
    backgroundColor: '#FFF',
    border: 'solid 1px #F9D2D2',
    color: '#A7A7A7',
    "&:hover": {
        cursor: "pointer"
    }
  },
  activeStatus: {
    color: '#9F0000',
    marginRight: 10,
  },
  inactiveStatus: {
    color: '#A7A7A7',
    marginRight: 10,
  },
  input: {
    width: '100%',
    fontSize: 16
  },
  buildingInput: {

  },
  saveContainer: {
    marginLeft: 65,
    marginTop: 10
  },
  saveText: {
    color: '#9F0000',
    fontSize: 16,
    fontWeight: 500,
    display: 'inline-block',
    position: 'absolute',
    marginTop: 7,
    marginLeft: -62,
    width: 44
  },
  saveBtnContainer: {
    width: 36,
    height: 36,
    borderRadius: 12,
    backgroundColor: '#9F0000',
    display: 'inline-block',
    "&:hover": {
      cursor: 'pointer'
    },
  },
  saveBtn: {
    width: 21,
    height: 21,
    paddingLeft: 3,
    position: 'absolute',
    marginLeft: 7,
    marginTop: 6
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  activeBuildingSettings: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 5
  },
  editBuilding: {
    marginTop: 20,
    marginBottom: 20,
    backgroundColor: '#f9d2d2',
    textAlign: 'center',
    padding: 8,
    borderRadius: 8,
    color: '#9F0000',
    "&:hover": {
      cursor: 'pointer'
    },
  },
  activePageHeader: {
    fontSize: 20,
    fontWeight: 'bold',
    paddingRight: 40,
    color: '#000',
    textDecoration: 'none',
    display: 'block',
    marginBottom: 15
  },
}));

const Main = () => {
  const classes = useStyles();
  const userId = localStorage.getItem('userId');
  const roleId = localStorage.getItem('roleId');
  const history = useHistory();
  let [language, setLanguage] = useState('');
  let [buildings, updateBuildings] = useState('');
  let [switchState, setSwitchState] = useState({});
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const language = localStorage.getItem('language');
    if(language) {
      i18n.changeLanguage(language);
    }

    getBuildings();
  }, [])
  
  if (!userId) {
    return <Redirect to={'/login'} />;
  } 

  const getBuildings = () => {
    axios.get(API_URL + `/properties/web`)
    .then(response => {
        let bldgs = response.data.properties;
        let all_bldgs = bldgs;
        if(roleId == 5) {
            axios.get(API_URL + `/manager-properties`)
            .then(resp => {
                let manager_properties = resp.data.manager_properties;
                let filtered_bldgs = []
                if(all_bldgs?.length > 0) {
                    filtered_bldgs = all_bldgs.filter(mp => {
                      return manager_properties?.some(a => a.property_id == mp.id && a.user_id == userId)
                    })
                } else {
                    filtered_bldgs = all_bldgs
                }
                updateData(filtered_bldgs)
            })
        } else {
          updateData(all_bldgs)
        }
    })
  }

  const updateData = (bldgs) => {
    updateBuildings(bldgs);
    setDynamicSwitchState(bldgs);
  }

  const setDynamicSwitchState = (list) => {
    if (!list) {
      return
    }

    const switchstate = {};

    list.forEach((item, index) => {
        switchstate['switch-' + index] = item.status == 1 ? true : false;
    });

    setSwitchState(switchstate);
  }

  function viewDashboard(buildingId) {
    localStorage.setItem('id', buildingId);
    history.push("/dashboard");
  }

  function renderStarRatings(feedback) {
    var ratings = [];
    if(feedback) {
      for(let i = 0; i < parseInt(feedback); i++){
        ratings.push(<div>
          <img
            src={starImage}
            className="rating-star"
          />
        </div>)
      }
    }
    return ratings;
  }

  const handleChange = (index, event, checked, buildingId) => {
    const list = Object.assign({}, switchState);

    list['switch-' + index] = checked;

    let message = checked ? t("center.activated") : t("center.deactivated");
    let post_url = `${API_URL}/property/status/edit`;

    axios.post(post_url, {
      id: buildingId, 
      status: checked ? 1 : 0
    })
    .then(res => {
        if(res.status == 200) {
            toast.success(message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setSwitchState(list);
        }
    });
  }

  function handleDelete(id) {
    if(id > 0) {
      axios.post(API_URL + `/property/delete/${id}`, {})
      .then(res => {
          if(res.status == 200) {
            window.location.reload(true);
          }
      });
    }
  }

  return (
    <div className="inner">
      <LoggedInTopMenu />
      <div className={classes.content}>
        <Grid
          justify="space-between"
          container
        >
            <Hidden smDown>
                <div className={classes.sideMenu}>
                    <SideMenu />
                </div>
            </Hidden>
            <div className={classes.mainContent}>
                <div className={classes.article}>
                  <div>
                    <a key="1" href="#" className={classes.activePageHeader}>{t('dashboard.buildings')}</a>  
                  </div>
                  <div className={classes.washCentersContainer}>
                    <div className={classes.cardContainer}>
                      {buildings && buildings.map((bldg, index) => (
                        <div className={classes.card}>
                            <>
                              <div className={classes.top}>
                                <div className={classes.washContainer}>
                                  <div className={classes.centerDetails}>
                                    <a 
                                      href="#" 
                                      className={classes.centerNameLink}
                                    >
                                      {language == 'ar' ? bldg.name_ar : bldg.name}
                                    </a>
                                    <div className={classes.ratingsContainer}>
                                      {renderStarRatings(bldg.rating)}
                                    </div>
                                  </div>
                                  <div style={{
                                    position: 'absolute',
                                    marginLeft: 205,
                                    marginTop: -3
                                  }}>
                                    <Switch key={index}
                                      checked={switchState['switch-' + index] || false}
                                      onChange={(event, checked) => handleChange(index, event, checked, bldg.id)}
                                      value={bldg.status}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className={classes.bottom}>
                                <div className={classes.flexContainer}>
                                  <div className={classes.iconWrapper}>
                                    <LocationOnIcon style={{
                                        fontSize: 18,
                                        color: '#9F0000',
                                        marginLeft: 5,
                                        marginTop: 4
                                      }} />
                                  </div>
                                  <div className={classes.detail}>
                                    {language == 'ar' ? bldg.address_ar : bldg.address}
                                  </div>
                                </div>
                                <div className={classes.flexContainer}>
                                  <div className={classes.iconWrapper}>
                                    <LocalCarWashIcon style={{
                                        fontSize: 18,
                                        color: '#9F0000',
                                        marginLeft: 5,
                                        marginTop: 4
                                      }} />
                                  </div>
                                  <div className={classes.detail}>
                                    {bldg.bookings > 0 ? bldg.bookings : '-'} {bldg.bookings > 0 ? t('dashboard.bookings') : ''}
                                  </div>
                                </div>
                                <div className={classes.flexContainer}>
                                  <div className={classes.iconWrapper}>
                                    <AttachMoneyIcon style={{
                                        fontSize: 18,
                                        color: '#9F0000',
                                        marginLeft: 5,
                                        marginTop: 4
                                      }} />
                                  </div>
                                  <div className={classes.detail}>
                                    {bldg.revenue > 0 ? 'BD ' + parseFloat(bldg.revenue).toFixed(2) : '-'}
                                  </div>
                                </div>
                                <div className={classes.statusContainer}>
                                  <div className={classes.status} onClick={() => viewDashboard(bldg.id)}>
                                    {t('dashboard.viewdashboard')}
                                  </div>
                                </div>
                              </div>
                            </>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
            </div>
        </Grid>
      </div>
    </div>
  );
};
  
export default Main;
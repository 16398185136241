import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useHistory, useParams } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { Grid } from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';
import { API_URL } from '../constants';
import { useTranslation } from 'react-i18next';
import LoggedInTopMenu from '../components/LoggedInTopMenu';
import SideMenu from '../components/SideMenu';
import Hidden from '@material-ui/core/Hidden';
import arrowrightwhiteimage from '../images/arrow-right-white.png'; 
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import CloseIcon from '@material-ui/icons/Close';
import Select from 'react-select';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles(theme => ({
    content: {
      paddingTop: 130,
      marginLeft: '-6%'
    },
    sideMenu: {
      backgroundColor: '#9F0000',
      width: 256,
      height: `calc(100vh - 130px)`,
      borderTopRightRadius: 75,
      color: '#fff',
      paddingTop: 70
    },
    mainContent: {
      width: `calc(100% - 256px)`,
      textAlign: 'left',
      ['@media (max-width:959px)']: { 
        width: '95%'
      }
    },
    article: {
      paddingTop: 20,
      paddingLeft: 50,
      ['@media (max-width:959px)']: { 
        paddingLeft: 0
      }
    },
    center: {
        position: 'absolute',
        top: '50%',
        left: '55%',
        width: 580,
        transform: 'translateX(-50%) translateY(-50%)',
        ['@media (max-width:959px)']: { 
            top: '50%',
            left: '0%',
            position: 'initial',
            transform: 'none',
            width: '100%'
        }
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        ['@media (max-width:959px)']: { 
            width: '90%',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        paddingBottom: 25
    },
    close: {
        fontSize: 24,
        "&:hover": {
            cursor: "pointer"
        }
    },
    fieldsContainer: {
        paddingTop: 35,
        width: 580,
        marginLeft: 'auto',
        marginRight: 'auto',
        ['@media (max-width:959px)']: { 
          width: '90%'
        }
    },
    inputContainer: {
        width: '100%',
        height: 72
    },
    inputContainerDesc: {
        width: '100%',
        marginTop: 15,
    },
    inputFlexContainer: {
        height: 72,
        display: 'flex',
        justifyContent: 'space-between',
        ['@media (max-width:959px)']: { 
            width: '100%',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    },
    input: {
        width: '100%',
        fontSize: 16
    },
    flexLastInput: {
        width: '100%',
        fontSize: 16,
        marginLeft: 20
    },
    saveContainer: {
        paddingTop: 16,
        float: 'right',
        marginTop: 65,
        ['@media (max-width:959px)']: { 
            marginRight: '3%',
            marginTop: '25%'
        }
    },
    saveText: {
        color: '#9F0000',
        fontSize: 16,
        fontWeight: 500,
        display: 'inline-block',
        position: 'absolute',
        marginTop: 7,
        marginLeft: -45,
        width: 44
    },
    saveBtnContainer: {
        width: 36,
        height: 36,
        borderRadius: 12,
        backgroundColor: '#9F0000',
        display: 'inline-block',
        "&:hover": {
            cursor: 'pointer'
        },
    },
    saveBtn: {
        width: 21,
        height: 21,
        position: 'absolute',
        marginTop: 6,
        marginLeft: 8
    },
    flexInput: {
        fontSize: 16,
        width: '25%',
        paddingRight: 20
    },
    flexInputLast: {
        fontSize: 16,
        width: '25%'
    },
    select: {
        width: '100%',
        fontFamily: 'Nunito',
        ['@media (max-width:959px)']: { 
            width: 310,
            marginBottom: 20
        }
    },
    serviceLabel: {
        paddingBottom: 20,
        fontSize: 16,
        fontFamily: 'Nunito',
        ['@media (max-width:959px)']: { 
            paddingTop: 65
        }
    },
    flexContainer: {
        display: 'flex',
        ['@media (max-width:959px)']: { 
            display: 'block'
        }
    },
    halfDiv: {
        width: '50%',
        ['@media (max-width:959px)']: { 
            width: '100%'
        }
    },
    deleteContainer: {
        marginTop: 90,
        float: 'left',
        color: '#9F0000',
        "&:hover": {
            cursor: 'pointer'
        },
        ['@media (max-width:959px)']: { 
            marginTop: '22%',
            marginLeft: 15
        }
    },
    buildingContainer: {
        width: '49%',
        ['@media (max-width:959px)']: { 
            width: '100%',
        }
    },
    addressContainer: {
        width: '49%',
        marginTop: 15,
        ['@media (max-width:959px)']: { 
            width: '100%',
        }
    },
}));

const ManageUser = () => {
    const classes = useStyles();
    const userId = localStorage.getItem('userId');
    const history = useHistory();
    const params = useParams();
    const { t, i18n } = useTranslation();
    let [language, setLanguage] = useState('');
    let [mode, setMode] = useState('');
    let [firstName, setFirstName] = useState('');
    let [lastName, setLastName] = useState('');
    let [password, setPassword] = useState('');
    let [confirmPassword, setConfirmPassword] = useState('');
    let [email, setEmail] = useState('');
    let [phone, setPhone] = useState('');
    let [address, setAddress] = useState('');
    let [targetPerDay, setTargetPerDay] = useState('');
    let [properties, setProperties] = useState([]);
    let [selectedProperty, setSelectedProperty] = useState(null);
    let [selectedPropertyObject, setSelectedPropertyObject] = useState(null);
    let [selectedManagerProperty, setSelectedManagerProperty] = useState(null);
    let [selectedManagerPropertyObject, setSelectedManagerPropertyObject] = useState(null);
    let [roles, setRoles] = useState([]);
    let [selectedRole, setSelectedRole] = useState(null);
    let [selectedRoleObject, setSelectedRoleObject] = useState(null);
    let [shifts, setShifts] = useState([]);
    let [selectedCleanerShift, setSelectedCleanerShift] = useState(null);
    let [selectedCleanerShiftObject, setSelectedCleanerShiftObject] = useState(null);
    let [formSubmitted, setFormSubmitted] = useState(false);
    let [user, setUser] = useState(null);
    let [smsNotification, setSmsNotification] = useState(false);
    let [emailNotification, setEmailNotification] = useState(false);
    let [processing, setProcessing] = useState(false);

    useEffect(() => {
        const language = localStorage.getItem('language');
        if(language) {
            i18n.changeLanguage(language);
        }
        setLanguage(language);

        initializeData();
        setMode(params.id > 0 ? 'edit' : 'add');
    }, [])

    if (!userId) {
        return <Redirect to={'/'} />;
    }

    const initializeData = () => {
        axios.get(API_URL + `/user-roles`)
        .then(response => {
            let rls = response.data.roles;
            rls = formatSelectData(rls, 'roles');

            axios.get(API_URL + `/properties`)
            .then(response1 => {
                let prp = response1.data.properties;
                prp = formatSelectData(prp, 'properties');

                axios.get(API_URL + `/shifts`)
                .then(response2 => {
                    let shf = response2.data.shifts;
                    shf = formatSelectData(shf, 'shifts');

                    if(params.id > 0) {
                        axios.get(API_URL + `/v2-user/${params.id}`)
                        .then(response3 => {
                            let user = response3.data.user;
                            console.log(user)
                            if(user) {
                                setUser(user);
                                setFirstName(user.first_name);
                                setLastName(user.last_name);
                                setEmail(user.email);
                                setPhone(user.phone_number);
                                setAddress(user.home_address);
                                setSelectedRole(user.role_id);
                                setSmsNotification(user.sms_notification == 1 ? true : false);
                                setEmailNotification(user.email_notification == 1 ? true : false);
                                if(user.role_id) { setSelectedRoleObject(valueFromId(rls, user.role_id)); }
                                if(user.role_id == 4 && user.cleaner_shifts && user.cleaner_shifts.length > 0) {
                                    let cleaner_shifts = formatSelectData(user.cleaner_shifts, 'cleaner_shifts');
                                    setSelectedCleanerShiftObject(cleaner_shifts);
                                }
                                if(user.target_per_day) { setTargetPerDay(user.target_per_day); }
                                if(user.role_id == 4 || user.role_id == 5) {
                                    if(user.role_id == 5 && user.manager_properties && user.manager_properties.length > 0) {
                                        let mgr_properties = formatSelectData(user.manager_properties, 'manager_properties');
                                        setSelectedManagerPropertyObject(mgr_properties);
                                    }
                                    if(user.role_id == 4 && user.user_properties && user.user_properties.length > 0) {
                                        let usr_properties = formatSelectData(user.user_properties, 'user_properties');
                                        setSelectedManagerPropertyObject(usr_properties);
                                    }
                                } else {
                                    if(user.property_id) { 
                                        setSelectedPropertyObject(valueFromId(prp, user.property_id)); 
                                    } 
                                }
                            }
                        })
                    }
                })
            })
        })
    }

    const valueFromId = (opts, id) => opts.find(o => o.value === id);

    const formatSelectData = (array, mode) => {
        let new_array = []
        if(array.length > 0) {
            array.forEach(item => {
                new_array.push({
                    value: item.id,
                    label: language == 'ar' ? ((item.name_ar || item.role_ar) ? (item.name_ar || item.role_ar) : (item.name || item.role)) : (item.name || item.role)
                })
            })
        }

        if(mode == 'roles') { setRoles(new_array); }
        if(mode == 'properties') { setProperties(new_array); }
        if(mode == 'shifts') { setShifts(new_array); }

        return new_array;
    }

    const handleRoleChange = (data) => {
        setSelectedRoleObject(data);
        setSelectedRole(data.value);
    };

    const handlePropertyChange = (data) => {
        if(selectedRole == 4 || selectedRole == 5) {
            setSelectedManagerProperty(data);
            setSelectedManagerPropertyObject(data);
        } else {
            setSelectedPropertyObject(data);
            setSelectedProperty(data.value);
        }
    };

    const handleShiftChange = (data) => {
        setSelectedCleanerShift(data);
        setSelectedCleanerShiftObject(data);
    };

    const handleFieldChange = (event, type) => {
        switch(type) {
            case 'firstName': 
                setFirstName(event.target.value);
                break;
            case 'lastName': 
                setLastName(event.target.value);
                break;
            case 'email': 
                setEmail(event.target.value);
                break;
            case 'phone':
                setPhone(event);
                break;
            case 'address': 
                setAddress(event.target.value);
                break;
            case 'password': 
                setPassword(event.target.value);
                break;
            case 'confirmPassword': 
                setConfirmPassword(event.target.value);
                break;
            case 'targetPerDay': 
                setTargetPerDay(event.target.value);
                break;
        }
    };

    function handleClick(e) {
        setFormSubmitted(true)
        if(
            (selectedRole != 3 && selectedRole != 4 && selectedRole != 5 && (!firstName || !phone || !selectedRole)) ||
            (selectedRole == 4 && (!firstName || !email || !phone || !selectedRole || !selectedCleanerShiftObject?.length > 0 || !targetPerDay)) ||
            ((selectedRole == 3 || selectedRole == 5) && (!firstName || !email || !phone || !selectedRole))
         ) {
            showError(t('promo-codes.fillup'))
        } else {
            if(mode == 'add') { 
                addUser(); 
            } else {
                editUser();
            }
        }
    }

    function showError(message) {
        toast.error(message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function addUser() {
        axios.post(API_URL + `/v2-user/add`, { 
            first_name: firstName,
            last_name: lastName,
            password,
            email,
            phone_number: phone,
            home_address: address,
            role_id: selectedRole,
            property_id: selectedProperty,
            manager_property: selectedManagerProperty,
            shift_id: null,
            cleaner_shift: selectedRole == 4 ? selectedCleanerShift : null,
            target_per_day: selectedRole == 4 ? targetPerDay : null,
            sms_notification: smsNotification ? 1 : 0,
            email_notification: emailNotification ? 1 : 0,
        })
        .then(res => {
            if(res.status == 200) {
                if(res.data.result == 'existing_user') {
                    showError(t('users.exists'))
                } else {
                    toast.success(t("users.successadd"), {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setTimeout(function () {
                        history.push("/users");
                    }, 2000);
                }
            }
        })
    }

    function editUser() {
        setProcessing(true)
        axios.post(API_URL + `/v2-user/edit/${params.id}`, {
            first_name: firstName,
            last_name: lastName,
            password,
            email,
            phone_number: phone,
            home_address: address,
            role_id: selectedRole,
            property_id: selectedProperty,
            manager_property: selectedManagerProperty,
            shift_id: null,
            cleaner_shift: selectedRole == 4 ? selectedCleanerShift : null,
            target_per_day: selectedRole == 4 ? targetPerDay : null,
            sms_notification: smsNotification ? 1 : 0,
            email_notification: emailNotification ? 1 : 0,
        })
        .then(res => {
            if(res.status == 200) {
                toast.success(t("users.successsave"), {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setProcessing(false);
                setTimeout(function () {
                    history.push("/users");
                }, 2000);
            }
        })
    }

    function handleClose(e) {
        history.push("/users")
    }

    function handleDelete(e) {
        if(params.id) {
            axios.post(API_URL + `/v2-user/deactivate/${params.id}`, {})
            .then(res => {
                if(res.status == 200) {
                    toast.success(t("users.successdelete"), {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setTimeout(function () {
                        history.push("/users");
                    }, 2000);
                }
            })
        } else {
            toast.error(t('promo-codes.deleteerror'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const handleChangeSMSNotification = (event) => {
        let checked = event?.target?.checked;
        setSmsNotification(checked ? true : false);
    };

    const handleChangeEmailNotification = (event) => {
        let checked = event?.target?.checked;
        setEmailNotification(checked ? true : false);
    };

    return (
        <div className="inner">
            <LoggedInTopMenu />
            <div className={classes.content}>
                <Grid
                    justify="space-between"
                    container
                >
                    <Hidden smDown>
                        <div className={classes.sideMenu}>
                            <SideMenu />
                        </div>
                    </Hidden>
                    <ToastContainer />
                    <div className={classes.mainContent}>
                        <div className={classes.article}>
                            <div className={classes.fieldsContainer}>
                                <div className={classes.header}>
                                    <div className={classes.title}>{mode == 'edit' ? t('users.editauser') : t('users.addauser')}</div>
                                    <div><CloseIcon className={classes.close} onClick={handleClose} /></div>
                                </div>
                                <div className={classes.fieldsContainer}>
                                    <form>
                                        <div>
                                            <Select
                                                placeholder={t('users.selectrole')}
                                                value={selectedRoleObject}
                                                onChange={handleRoleChange}
                                                options={roles}
                                                name="role"
                                                required
                                            />
                                        </div>
                                        <div className={classes.flexContainer} style={{gap:25}}>
                                            <div className={classes.inputContainerDesc}>
                                                <TextField 
                                                    id="firstName"
                                                    label={t('users.firstname')} 
                                                    className={classes.input}
                                                    placeholder={t('users.firstname')}
                                                    required
                                                    error={formSubmitted && !firstName ? true : false}
                                                    value={firstName || ''}
                                                    onChange={(event) => handleFieldChange(event, 'firstName')}
                                                />
                                            </div>
                                            <div className={classes.inputContainerDesc}>
                                                <TextField 
                                                    id="lastName"
                                                    label={t('users.lastname')} 
                                                    className={classes.input}
                                                    placeholder={t('users.lastname')}
                                                    value={lastName || ''}
                                                    onChange={(event) => handleFieldChange(event, 'lastName')}
                                                />
                                            </div>
                                        </div>
                                        <div className={classes.flexContainer} style={{gap:25}}>
                                            <div className={classes.inputContainerDesc}>
                                                <TextField 
                                                    type="password"
                                                    id="password"
                                                    label={t('profile.password')} 
                                                    className={classes.input}
                                                    placeholder={t('profile.password')}
                                                    error={formSubmitted && mode == 'add' && !password ? true : false}
                                                    value={password || ''}
                                                    onChange={(event) => handleFieldChange(event, 'password')}
                                                    required={mode == 'add' ? true : false}
                                                />
                                            </div>
                                            <div className={classes.inputContainerDesc}>
                                                <TextField 
                                                    type="password"
                                                    id="confirmPassword"
                                                    label={t('profile.confirmpassword')} 
                                                    className={classes.input}
                                                    placeholder={t('profile.confirmpassword')}
                                                    error={formSubmitted && ((mode == 'add' && !confirmPassword) || (password != confirmPassword)) ? true : false}
                                                    value={confirmPassword || ''}
                                                    onChange={(event) => handleFieldChange(event, 'confirmPassword')}
                                                    required={mode == 'add' ? true : false}
                                                />
                                            </div>
                                        </div>
                                        <div className={classes.flexContainer} style={{gap:25}}>
                                            <div className={classes.addressContainer}>
                                                <TextField 
                                                    id="email"
                                                    label={t('users.email')}
                                                    className={classes.input}
                                                    placeholder={t('users.email')}
                                                    required={selectedRole == 3 || selectedRole == 4 || selectedRole == 5 ? false : true}
                                                    error={formSubmitted && (selectedRole == 3 || selectedRole == 4 || selectedRole == 5) && !email ? true : false}
                                                    value={email || ''}
                                                    onChange={(event) => handleFieldChange(event, 'email')}
                                                />
                                            </div>
                                            <div className={classes.inputContainerDesc} style={{marginTop:31}}>
                                                <PhoneInput
                                                    international
                                                    countryCallingCodeEditable={false}
                                                    defaultCountry="BH"
                                                    value={phone || ''}
                                                    onChange={(event) => handleFieldChange(event, 'phone')} />
                                            </div>
                                        </div>
                                        <div className={classes.flexContainer} style={{gap:25}}>
                                            <div className={classes.addressContainer}>
                                                <TextField 
                                                    id="address"
                                                    label={t('profile.address')}
                                                    multiline
                                                    className={classes.input}
                                                    placeholder={t('profile.address')}
                                                    value={address || ''}
                                                    onChange={(event) => handleFieldChange(event, 'address')}
                                                />
                                            </div>
                                            <div className={classes.buildingContainer} style={{marginTop:24}}>
                                                <Select
                                                    placeholder={t('users.selectbuilding')}
                                                    value={selectedRole == 4 || selectedRole == 5 ? selectedManagerPropertyObject : selectedPropertyObject}
                                                    onChange={handlePropertyChange}
                                                    options={properties}
                                                    isMulti={selectedRole == 4 || selectedRole == 5 ? true : false}
                                                />
                                            </div>
                                        </div>
                                        {selectedRole == 4 &&
                                            <div className={classes.flexContainer} style={{gap:25, marginTop:25, alignItems: 'center'}}>
                                                <div className={classes.halfDiv}>
                                                    <Select
                                                        placeholder={t('users.selectshift')}
                                                        value={selectedCleanerShiftObject}
                                                        onChange={handleShiftChange}
                                                        options={shifts}
                                                        required
                                                        isMulti
                                                    />
                                                </div>
                                                <div className={classes.halfDiv}>
                                                    <TextField 
                                                        type="number"
                                                        id="targetperday"
                                                        label={t('users.targetperday')}
                                                        className={classes.input}
                                                        placeholder={t('users.targetperday')}
                                                        value={targetPerDay || ''}
                                                        onChange={(event) => handleFieldChange(event, 'targetPerDay')}
                                                        error={formSubmitted && !targetPerDay ? true : false}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        }
                                        <div style={{marginTop:30}}>
                                            <b>{t('side.notifications')}</b>
                                            <div className={classes.flexContainer} style={{gap:25}}>
                                                <div className={classes.inputContainerDesc}>
                                                    <div style={{display:'flex',alignItems:'center'}}>
                                                        <Checkbox
                                                            checked={smsNotification}
                                                            onChange={(event) => handleChangeSMSNotification(event)}
                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                        />
                                                        <div>SMS</div>
                                                    </div>
                                                </div>
                                                <div className={classes.inputContainerDesc}>
                                                    <div style={{display:'flex',alignItems:'center'}}>
                                                        <Checkbox
                                                            checked={emailNotification}
                                                            onChange={(event) => handleChangeEmailNotification(event)}
                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                        />
                                                        <div>{t('footer.email')}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div>
                                    {mode == 'edit' &&
                                        <div 
                                            className={classes.deleteContainer}
                                            onClick={handleDelete}
                                        >
                                            {t('promo-codes.delete')}
                                        </div>
                                    }
                                    {!processing &&
                                        <div className={classes.saveContainer}>
                                            <div className={classes.saveText}>{t('promo-codes.save')}</div>
                                            <div 
                                                onClick={handleClick}
                                                className={classes.saveBtnContainer} 
                                            >
                                                <img 
                                                    className={classes.saveBtn} 
                                                    src={arrowrightwhiteimage} 
                                                    alt="Carcare" />
                                            </div>
                                        </div>
                                    }
                                    {processing &&
                                        <div className={classes.saveContainer}>
                                            <div className={classes.addBookingText}>{t('booking.processing')}</div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Grid>
            </div>
        </div>
    );
};
  
export default ManageUser;
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from "react-router-dom";
import { Grid } from '@material-ui/core';
import { API_URL } from '../constants';
import LoggedInTopMenu from '../components/LoggedInTopMenu';
import SideMenu from '../components/SideMenu';
import Hidden from '@material-ui/core/Hidden';
import axios from 'axios';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

import DataTable from 'react-data-table-component';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    content: {
      paddingTop: 130,
      marginLeft: '-6%'
    },
    sideMenu: {
      backgroundColor: '#9F0000',
      width: 256,
      height: `calc(100vh - 130px)`,
      borderTopRightRadius: 75,
      color: '#fff',
      paddingTop: 70
    },
    mainContent: {
      width: `calc(100% - 256px)`,
      textAlign: 'left',
      ['@media (max-width:959px)']: { 
        width: '92%',
        paddingLeft: 0,
        paddingRight: 0
      }
    },
    article: {
      paddingTop: 20,
      paddingLeft: 50
    },
    activePageHeader: {
        fontSize: 20,
        fontWeight: 'bold',
        paddingRight: 40,
        color: '#000',
        textDecoration: 'none',
    },
    pageHeader: {
        fontSize: 20,
        fontWeight: 'bold',
        textTransform: 'uppercase',
        marginBottom: 20
    },
    statementHeader: {
        paddingTop: 20,
        borderBottomWidth: 1,
        borderBottomColor: '#d3d3d3',
        borderBottomStyle: 'solid'
    },
    indentedHeader: {
        color: '#000',
        opacity: 0.25,
        fontSize: 16,
        textAlign: 'left',
        paddingLeft: '3%',
        ['@media (max-width:959px)']: { 
          paddingLeft: 0
        },
        ['@media (min-width:959px)']: { 
          visibility: 'hidden'
        }
    },
    leftHeader: {
        color: '#000',
        opacity: 0.25,
        fontSize: 16,
        textAlign: 'left',
        ['@media (min-width:959px)']: { 
          visibility: 'hidden'
        }
    },
    header: {
        color: '#000',
        opacity: 0.25,
        fontSize: 16,
        textAlign: 'left',
    },
    history: {
        width: '100%'
    },
    header: {
        color: '#000',
        opacity: 0.25,
        fontSize: 16,
        paddingBottom: 39,
        textAlign: 'center'
    },
    left: {
        textAlign: 'left',
        height: 80,
        borderBottomWidth: 1,
        borderBottomColor: '#d3d3d3',
        borderBottomStyle: 'solid'
    },
    center: {
        textAlign: 'center',
        height: 80,
        borderBottomWidth: 1,
        borderBottomColor: '#d3d3d3',
        borderBottomStyle: 'solid',
        fontSize: 14
    },
    amount: {
        fontSize: 20,
        fontWeight: 'bold',
        textAlign: 'center',
        height: 80,
        borderBottomWidth: 1,
        borderBottomColor: '#d3d3d3',
        borderBottomStyle: 'solid'
    },
    complete: {
        fontSize: 12,
        color: '#48D1B5',
        border: 'solid 1px #48D1B5',
        fontWeight: 'bold',
        width: 80,
        margin: '0 auto',
        borderRadius: 10,
        ['@media (max-width:768px)']: { 
          width: 'initial',
          textAlign: 'center'
        }
    },
    name: {
        fontSize: 14,
        fontWeight: 'bold'
    },
    car: {
        fontSize: 14,
        fontWeight: 'bold',
        color: '#000',
        opacity: 0.25
    },
    customer: {
        width: 36,
        height: 36,
        borderRadius: 10,
        objectFit: 'cover',
        marginRight: 12
    },
    client: {
        display: 'flex',
        ['@media (max-width:768px)']: { 
          display: 'block'
        }
    },
    progress: {
        fontSize: 12,
        color: '#f1a542',
        border: 'solid 1px #f1a542',
        fontWeight: 'bold',
        width: 80,
        margin: '0 auto',
        borderRadius: 10,
        "&:hover": {
            cursor: "pointer"
        },
        ['@media (max-width:768px)']: { 
          width: 'initial',
          textAlign: 'center'
        }
    },
    rank: {
        marginTop: 3
    },
    star: {
        width: 16,
        height: 16
    },
    ratingsContainer: {
        display: 'flex',
        flexDirection: 'row',
        margin: '0 auto',
        justifyContent: 'center',
        ['@media (max-width:768px)']: { 
            justifyContent: 'flex-start'
        }
    },
    titleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 7
    },
    dialogTitle: {
        fontSize: 20,
        fontWeight: 'bold'
    },
    close: {
        fontSize: 24,
        "&:hover": {
            cursor: "pointer"
        }
    },
    dialogContent: {
        width: 480,
        marginTop: 50,
        ['@media (max-width:959px)']: { 
            width: '100%'
        }
    },
    serviceInfoDate: {
        fontSize: 14,
        color: '#000',
        opacity: 0.25,
        marginTop: -10
    },
    dialogUserContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        width: '70%',
        marginLeft: 'auto',
        marginRight: 'auto',
        ['@media (max-width:959px)']: { 
            width: '100%'
        }
    },
    dialogUserImage: {
        width: 80,
        height: 80,
        borderRadius: 18,
        objectFit: 'cover',
        marginBottom: 25
    },
    dialogCarImage: {
        width: 80,
        height: 80,
        borderRadius: 18,
        objectFit: 'cover',
        marginBottom: 25
    },
    dialogUser: {
        textAlign: 'center'
    },
    dialogCar: {
        textAlign: 'center'
    },
    dialogFlexContainer: {
        display: 'flex'
    },
    dialogRow: {
        marginTop: 60
    },
    modalIconImage: {
        width: 36,
        height: 36
    },
    dialogRightText: {
        marginLeft: 20,
        paddingTop: 6,
        marginLeft: 20,
        paddingTop: 6,
        width: '85%'
    },
    dialogFlexEndContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    dialogRight: {
        position: 'absolute',
        right: 64,
        fontWeight: 'bold'
    },
    dialogRightLast: {
        position: 'absolute',
        right: 64,
        marginTop: 25,
        fontWeight: 'bold'
    },
    dialogBottomRow: {
        marginTop: 25
    },
    mapContainer: {
        height: 319, 
        width: '100%', 
        borderRadius: 40,
        marginTop: 20
    },
    mapIcon: {
        color: '#9F0000',
        marginBottom: -5,
        marginRight: 5,
        marginLeft: 5
    },
    whatsappIcon: {
        color: '#075e54',
        marginBottom: -5,
        marginRight: 5,
        marginLeft: 5
    },
    emailIcon: {
        color: '#9F0000',
        marginBottom: -5,
        marginRight: 5,
        marginLeft: 5
    },
    filterContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 15,
        marginBottom: 20,
        width: '30%',
        justifyContent: 'space-between',
        ['@media (max-width:959px)']: { 
            width: '100%',
        }
    },
    input: {
        width: 280,
        fontSize: 16
    },
}));

const Feedback = () => {
    const classes = useStyles();
    const id = localStorage.getItem('id');
    let [language, setLanguage] = useState('');
    let [searchKeyword, setSearchKeyword] = useState('');
    let [feedbacks, setFeedbacks] = useState([]);
    let [allFeedbacks, setAllFeedbacks] = useState([]);
    let [columns, setColumns] = useState([]);
    const { t, i18n } = useTranslation();
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    useEffect(() => {
        const language = localStorage.getItem('language');
        if(language) {
            i18n.changeLanguage(language);
        }
        setLanguage(language);
        
        getRatings();
        let table_columns = [
            {
                name: t('booking.assignedstaff'),
                selector: row => (row.assigned_staff_first_name + ' ' + row.assigned_staff_last_name),
                sortable: true,
            },
            {
                name: t('services.building'),
                selector: row => row.building_name,
                sortable: true,
            },
            {
                name: t('feedback.averagerating'),
                selector: row => parseFloat(row.average_rating)?.toFixed(2),
                sortable: true,
            },
            {
                name: t('feedback.ratedbookings'),
                selector: row => row.rated_bookings,
                sortable: true,
            },
            {
                name: t('feedback.completedbookings'),
                selector: row => row.completed_bookings,
                sortable: true,
            }
        ];
        setColumns(table_columns);
    }, [])

    if (!id) {
        return <Redirect to={'/login'} />;
    } 

    const getRatings = () => {
        axios.get(API_URL + `/v2/ratings`)
        .then(response => {
            let rtngs = response.data.ratings;
            setFeedbacks(rtngs);
            setAllFeedbacks(rtngs);
        })
    }

    const handleFieldChange = (event, type) => {
        switch(type) {
            case 'searchKeyword': 
                setSearchKeyword(event.target.value);
                handleFilter(event.target.value, '', '')
                break;
        }
    };

    function handleFilter(search, building, payment_status, package_status) {
        let bkk = allFeedbacks
    
        if(bkk.length > 0) {
            if(search.length > 0) {
                bkk = bkk.filter(s => {
                    let include = false
            
                    if(
                        (s.assigned_staff_first_name && search &&
                            s.assigned_staff_first_name.toLowerCase().indexOf(search.toLowerCase()) >= 0
                        ) || 
                        (s.assigned_staff_last_name && search &&
                            s.assigned_staff_last_name.toLowerCase().indexOf(search.toLowerCase()) >= 0
                        )
                    ) {
                        include = true
                    }
            
                    return include
                })
            }
        }
    
        setFeedbacks(bkk)
    }

    return (
        <div className="inner">
        <LoggedInTopMenu />
        <div className={classes.content}>
            <Grid
                justify="space-between"
                container
            >
                <Hidden smDown>
                    <div className={classes.sideMenu}>
                    <SideMenu />
                    </div>
                </Hidden>
                <div className={classes.mainContent}>
                    <div className={classes.article}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <a key="1" href="#" className={classes.activePageHeader}>{t('side.feedback')}</a>  
                        </div>
                        <div style={{display:'flex',alignItems:'center'}}>
                            <div className={classes.filterContainer} style={{marginRight:10}}>
                                <div style={{marginRight:10}}>
                                    <TextField 
                                        id="searchKeyword"
                                        label={t('dashboard.search')} 
                                        className={classes.input}
                                        placeholder={t('dashboard.search')}
                                        value={searchKeyword || ''}
                                        onChange={(event) => handleFieldChange(event, 'searchKeyword')}
                                    />
                                </div>
                            </div>
                        </div>
                        <DataTable
                            columns={columns}
                            data={feedbacks}
                            pagination
                            paginationResetDefaultPage={resetPaginationToggle}
                            persistTableHead
                            fixedHeader
                        />
                    </div>
                </div>
            </Grid>
        </div>
        </div>
    );
};
  
export default Feedback;
import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useHistory, useParams } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { Grid } from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';
import { API_URL } from '../constants';
import { useTranslation } from 'react-i18next';
import LoggedInTopMenu from '../components/LoggedInTopMenu';
import SideMenu from '../components/SideMenu';
import Hidden from '@material-ui/core/Hidden';
import arrowrightwhiteimage from '../images/arrow-right-white.png'; 
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import CloseIcon from '@material-ui/icons/Close';
import Select from 'react-select';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles(theme => ({
    content: {
      paddingTop: 130,
      marginLeft: '-6%'
    },
    sideMenu: {
      backgroundColor: '#9F0000',
      width: 256,
      height: `calc(100vh - 130px)`,
      borderTopRightRadius: 75,
      color: '#fff',
      paddingTop: 70
    },
    mainContent: {
      width: `calc(100% - 256px)`,
      textAlign: 'left',
      ['@media (max-width:959px)']: { 
        width: '95%'
      }
    },
    article: {
      paddingTop: 20,
      paddingLeft: 50,
      ['@media (max-width:959px)']: { 
        paddingLeft: 0
      }
    },
    center: {
        position: 'absolute',
        top: '50%',
        left: '55%',
        width: 580,
        transform: 'translateX(-50%) translateY(-50%)',
        ['@media (max-width:959px)']: { 
            top: '50%',
            left: '0%',
            position: 'initial',
            transform: 'none',
            width: '100%'
        }
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        ['@media (max-width:959px)']: { 
            width: '90%',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        paddingBottom: 25
    },
    close: {
        fontSize: 24,
        "&:hover": {
            cursor: "pointer"
        }
    },
    fieldsContainer: {
        paddingTop: 35,
        width: 580,
        marginLeft: 'auto',
        marginRight: 'auto',
        ['@media (max-width:959px)']: { 
          width: '90%'
        }
    },
    inputContainer: {
        width: '100%',
        height: 72
    },
    inputContainerDesc: {
        width: '100%',
        marginTop: 15,
    },
    inputFlexContainer: {
        height: 72,
        display: 'flex',
        justifyContent: 'space-between',
        ['@media (max-width:959px)']: { 
            width: '100%',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    },
    input: {
        width: '100%',
        fontSize: 16
    },
    flexLastInput: {
        width: '100%',
        fontSize: 16,
        marginLeft: 20
    },
    saveContainer: {
        paddingTop: 16,
        float: 'right',
        marginTop: 65,
        ['@media (max-width:959px)']: { 
            marginRight: '3%',
            marginTop: '25%'
        }
    },
    saveText: {
        color: '#9F0000',
        fontSize: 16,
        fontWeight: 500,
        display: 'inline-block',
        position: 'absolute',
        marginTop: 7,
        marginLeft: -45,
        width: 44
    },
    saveBtnContainer: {
        width: 36,
        height: 36,
        borderRadius: 12,
        backgroundColor: '#9F0000',
        display: 'inline-block',
        "&:hover": {
            cursor: 'pointer'
        },
    },
    saveBtn: {
        width: 21,
        height: 21,
        position: 'absolute',
        marginTop: 6,
        marginLeft: 8
    },
    flexInput: {
        fontSize: 16,
        width: '25%',
        paddingRight: 20
    },
    flexInputLast: {
        fontSize: 16,
        width: '25%'
    },
    select: {
        width: '100%',
        fontFamily: 'Nunito',
        ['@media (max-width:959px)']: { 
            width: 310,
            marginBottom: 20
        }
    },
    serviceLabel: {
        paddingBottom: 20,
        fontSize: 16,
        fontFamily: 'Nunito',
        ['@media (max-width:959px)']: { 
            paddingTop: 65
        }
    },
    flexContainer: {
        display: 'flex',
        ['@media (max-width:959px)']: { 
            display: 'block'
        }
    },
    halfDiv: {
        width: '50%',
        ['@media (max-width:959px)']: { 
            width: '100%'
        }
    },
    deleteContainer: {
        marginTop: 90,
        float: 'left',
        color: '#9F0000',
        "&:hover": {
            cursor: 'pointer'
        },
        ['@media (max-width:959px)']: { 
            marginTop: '22%',
            marginLeft: 15
        }
    },
}));

const ManageNotificationMessage = () => {
    const classes = useStyles();
    const userId = localStorage.getItem('userId');
    const history = useHistory();
    const params = useParams();
    const { t, i18n } = useTranslation();
    let [language, setLanguage] = useState('');
    let [mode, setMode] = useState('');
    let [events, setEvents] = useState([]);
    let [selectedEvent, setSelectedEvent] = useState(null);
    let [selectedEventObject, setSelectedEventObject] = useState(null);
    let [formSubmitted, setFormSubmitted] = useState(false);
    let [title, setTitle] = useState('');
    let [message, setMessage] = useState('');
    let [notificationMessage, setNotificationMessage] = useState(null);

    useEffect(() => {
        const language = localStorage.getItem('language');
        if(language) {
            i18n.changeLanguage(language);
        }
        setLanguage(language);

        initializeData();
        setMode(params.id > 0 ? 'edit' : 'add');
    }, [])

    if (!userId) {
        return <Redirect to={'/'} />;
    }

    const initializeData = () => {
        axios.get(API_URL + `/notification-messages-events`)
        .then(response => {
            let evts = response.data.events;
            evts = formatSelectData(evts, 'events');

            if(params.id > 0) {
                axios.get(API_URL + `/notification-message/${params.id}`)
                .then(response3 => {
                    let notif = response3.data.notification_message;
                    if(notif) {
                        setNotificationMessage(notif);
                        setTitle(notif.title);
                        setMessage(notif.message);
                        setSelectedEvent(notif.event);
                        if(notif.event) { setSelectedEventObject(valueFromId(evts, notif.event)); }
                    }
                })
            }
        })
    }

    const valueFromId = (opts, id) => opts.find(o => o.value === id);

    const formatSelectData = (array, mode) => {
        let new_array = []
        if(array.length > 0) {
            array.forEach(item => {
                new_array.push({
                    value: item.id || item.value,
                    label: language == 'ar' ? ((item.name_ar || item.role_ar) ? (item.name_ar || item.role_ar) : (item.name || item.role)) : (item.name || item.role)
                })
            })
        }

        if(mode == 'events') { setEvents(new_array); }

        return new_array;
    }

    const handleEventChange = (data) => {
        setSelectedEventObject(data);
        setSelectedEvent(data.value);
    };

    const handleFieldChange = (event, type) => {
        switch(type) {
            case 'title': 
                setTitle(event.target.value);
                break;
            case 'message': 
                setMessage(event.target.value);
                break;
        }
    };

    function handleClick(e) {
        setFormSubmitted(true)
        if(!title || !message || !selectedEvent) {
            showError(t('promo-codes.fillup'))
        } else {
            if(mode == 'add') { 
                addNotificationMessage(); 
            } else {
                editNotificationMessage();
            }
        }
    }

    function showError(message) {
        toast.error(message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function addNotificationMessage() {
        axios.post(API_URL + `/notification-message/add`, {
            event: selectedEvent,
            title: title,
            message: message,
        })
        .then(res => {
            if(res.status == 200) {
                if(res.data.result == 'existing_message') {
                    showError(t('notification-messages.exists'))
                } else {
                    toast.success(t("users.successadd"), {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setTimeout(function () {
                        history.push("/notificationmessages");
                    }, 2000);
                }
            }
        })
    }

    function editNotificationMessage() {
        axios.post(API_URL + `/notification-message/edit/${params.id}`, {
            event: selectedEvent,
            title: title,
            message: message,
        })
        .then(res => {
            if(res.status == 200) {
                toast.success(t("users.successsave"), {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setTimeout(function () {
                    history.push("/notificationmessages");
                }, 2000);
            }
        })
    }

    function handleClose(e) {
        history.push("/notificationmessages")
    }

    function handleDelete(e) {
        if(params.id) {
            axios.post(API_URL + `/notification-message/delete/${params.id}`, {})
            .then(res => {
                if(res.status == 200) {
                    toast.success(t("users.successdelete"), {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setTimeout(function () {
                        history.push("/notificationmessages");
                    }, 2000);
                }
            })
        } else {
            toast.error(t('promo-codes.deleteerror'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    return (
        <div className="inner">
            <LoggedInTopMenu />
            <div className={classes.content}>
                <Grid
                    justify="space-between"
                    container
                >
                    <Hidden smDown>
                        <div className={classes.sideMenu}>
                            <SideMenu />
                        </div>
                    </Hidden>
                    <ToastContainer />
                    <div className={classes.mainContent}>
                        <div className={classes.article}>
                            <div className={classes.fieldsContainer}>
                                <div className={classes.header}>
                                    <div className={classes.title}>{mode == 'edit' ? t('notification-messages.editanotificationmessage') : t('notification-messages.addanotificationmessage')}</div>
                                    <div><CloseIcon className={classes.close} onClick={handleClose} /></div>
                                </div>
                                <div className={classes.fieldsContainer}>
                                    <form>
                                        <div>
                                            <Select
                                                placeholder={t('notification-messages.selectevent')}
                                                value={selectedEventObject}
                                                onChange={handleEventChange}
                                                options={events}
                                                name="event"
                                                required
                                            />
                                        </div>
                                        <div className={classes.inputContainerDesc}>
                                            <TextField 
                                                id="title"
                                                label={t('notification-messages.title')}
                                                className={classes.input}
                                                placeholder={t('notification-messages.title')}
                                                required
                                                error={formSubmitted && !title ? true : false}
                                                value={title || ''}
                                                onChange={(event) => handleFieldChange(event, 'title')}
                                            />
                                        </div>
                                        <div className={classes.inputContainerDesc}>
                                            <TextField 
                                                id="message"
                                                label={t('notification-messages.message')}
                                                multiline
                                                className={classes.input}
                                                placeholder={t('notification-messages.message')}
                                                required
                                                error={formSubmitted && !message ? true : false}
                                                value={message || ''}
                                                onChange={(event) => handleFieldChange(event, 'message')}
                                            />
                                        </div>
                                    </form>
                                </div>
                                <div>
                                    {mode == 'edit' &&
                                        <div 
                                            className={classes.deleteContainer}
                                            onClick={handleDelete}
                                        >
                                            {t('promo-codes.delete')}
                                        </div>
                                    }
                                    <div className={classes.saveContainer}>
                                        <div className={classes.saveText}>{t('promo-codes.save')}</div>
                                        <div 
                                            onClick={handleClick}
                                            className={classes.saveBtnContainer} 
                                        >
                                            <img 
                                                className={classes.saveBtn} 
                                                src={arrowrightwhiteimage} 
                                                alt="Carcare" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Grid>
            </div>
        </div>
    );
};
  
export default ManageNotificationMessage;
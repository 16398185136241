import React, { useRef, useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useHistory, useParams } from "react-router-dom";
import { API_URL, PLACES_API_KEY } from '../constants';
import { ToastContainer, toast } from 'react-toastify';
import { GoogleMap, LoadScript, Marker, DirectionsService, DirectionsRenderer } from '@react-google-maps/api';
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import DateFnsUtils from '@date-io/date-fns';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Title from "../components/Title";
import OtherTopMenu from '../components/OtherTopMenu';
import LoggedInTopMenu from '../components/LoggedInTopMenu';
import SideMenu from '../components/SideMenu';
import Hidden from '@material-ui/core/Hidden';

import TextField from '@material-ui/core/TextField';
import arrowrightwhiteimage from '../images/arrow-right-white.png'; 
import washingcenterimage from '../images/washing-center.png'; 
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import Geocode from "react-geocode";
import axios from 'axios';
import moment from 'moment';
import 'react-toastify/dist/ReactToastify.css';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Radio from '@material-ui/core/Radio';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import FormControl from '@material-ui/core/FormControl';
import { styles } from '@material-ui/pickers/views/Calendar/Calendar';
import Calendar from 'react-calendar-multiday';
import Select from 'react-select';

const BootstrapInput = withStyles((theme) => ({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
}))(InputBase);

const useStyles = makeStyles(theme => ({
    content: {
        paddingTop: 130,
        marginLeft: '-6%'
    },
    sideMenu: {
        backgroundColor: '#9F0000',
        width: 256,
        height: `calc(100vh - 130px)`,
        borderTopRightRadius: 75,
        color: '#fff',
        paddingTop: 70
    },
    mainContent: {
        width: `calc(100% - 256px)`,
        textAlign: 'left',
        ['@media (max-width:959)']: { 
          width: '100%',
          paddingLeft: 0,
          paddingRight: 0
        }
    },
    article: {
        paddingTop: 20,
        paddingLeft: 50
    },
    pageHeader: {
        fontSize: 20,
        fontWeight: 'bold',
        textTransform: 'uppercase',
        marginBottom: 20
    },
    center: {
        position: 'absolute',
        top: '50%',
        left: '55%',
        width: 580,
        transform: 'translateX(-50%) translateY(-50%)',
        ['@media (max-width:959px)']: { 
            top: '50%',
            left: '0%',
            position: 'initial',
            transform: 'none',
            width: '100%'
        }
    },
    flexContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 30,
        "&:hover": {
            cursor: 'pointer'
        },
    },
    addBookingContainer: {
        paddingTop: 30,
        float: 'right'
    },
    addBookingText: {
        color: '#9F0000',
        fontSize: 16,
        fontWeight: 500,
        display: 'inline-block',
        position: 'absolute',
        marginTop: 7,
        marginLeft: -105,
        width: 100
    },
    addBookingBtnContainer: {
        width: 36,
        height: 36,
        borderRadius: 12,
        backgroundColor: '#9F0000',
        display: 'inline-block',
        "&:hover": {
            cursor: 'pointer'
        },
    },
    addBookingBtn: {
        width: 21,
        height: 21,
        paddingLeft: 3,
        position: 'absolute',
        marginLeft: 7,
        marginTop: 6
    },
    saveText: {
        color: '#9F0000',
        fontSize: 16,
        fontWeight: 500,
        display: 'inline-block',
        position: 'absolute',
        marginTop: 7,
        marginLeft: -44,
        width: 55
    },
    arrowIcon: {
        fontSize: 18,
        color: '#9F0000',
        marginLeft: 5,
        marginTop: 4,
        "&:hover": {
            cursor: 'pointer'
        },
    },
    sectionTitle: {
        fontSize: 20,
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 0,
        marginBottom: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: 580,
        ['@media (max-width:959px)']: { 
            width: '90%',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        paddingBottom: 25
    },
    close: {
        fontSize: 24,
        "&:hover": {
            cursor: "pointer"
        }
    },
    sectionArrowContainer: {
        display: 'flex',
        flexDirection: 'row'
    },
    fieldsContainer: {
        width: 580,
        marginLeft: 'auto',
        marginRight: 'auto',
        ['@media (max-width:959px)']: { 
          width: '90%'
        }
    },
    inputContainer: {
        width: '100%',
        height: 72
    },
    inputContainerDesc: {
        width: '100%'
    },
    selectService: {
        width: '100%',
        fontFamily: 'Nunito',
        ['@media (max-width:959px)']: { 
            width: 310,
            marginBottom: 20
        }
    },
    serviceLabel: {
        paddingBottom: 20,
        fontSize: 16,
        fontFamily: 'Nunito',
        ['@media (max-width:959px)']: { 
            paddingTop: 65
        }
    },
    timeContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        width: '85%',
        margin: `0 auto`,
        marginTop: 30,
        paddingLeft: 40
    },
    amountFlexContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 30,
        borderTop: `solid 1px #d3d3d3`,
        paddingTop: 10,
        "&:hover": {
            cursor: 'pointer'
        },
    },
    totalSectionTitle: {
        fontSize: 20,
        textTransform: 'uppercase'
    },
}));

const RenewBooking = () => {
    const classes = useStyles()
    const { t, i18n } = useTranslation()
    const history = useHistory()
    const params = useParams()
    const userId = localStorage.getItem('userId')
    let [language, setLanguage] = useState('')
    let [customerView, setCustomerView] = useState(false)
    let [serviceView, setServiceView] = useState(false)
    let [dateTimeView, setDateTimeView] = useState(false)
    let [discountView, setDiscountView] = useState(false)
    let [paymentStatusView, setPaymentStatusView] = useState(false)
    let [notesView, setNotesView] = useState(false)
    let [summaryView, setSummaryView] = useState(true)
    let [sectionViewTitle, setSectionViewTitle] = useState('')
    let [customer, setCustomer] = useState({})
    let [service, setService] = useState({})
    let [dateTime, setDateTime] = useState({})
    let [discount, setDiscount] = useState({})
    let [discountAmount, setDiscountAmount] = useState('')
    let [selectedDiscount, setSelectedDiscount] = useState('')
    let [discountTypes, setDiscountTypes] = useState([])
    let [paymentStatusValues, setPaymentStatusValues] = useState([])
    let [paymentStatus, setPaymentStatus] = useState({})
    let [selectedPaymentStatus, setSelectedPaymentStatus] = useState('')
    let [guestName, setGuestName] = useState('')
    let [email, setEmail] = useState('')
    let [phone, setPhone] = useState('')
    let [area, setArea] = useState('')
    let [building, setBuilding] = useState('')
    let [road, setRoad] = useState('')
    let [block, setBlock] = useState('')
    let [locationLink, setLocationLink] = useState('')
    let [flatNumber, setFlatNumber] = useState('')
    let [notes, setNotes] = useState('')
    let [roleId, setRoleId] = useState('')
    let [services, setServices] = useState('')
    let [svc, setSvc] = useState('')
    let [washCenters, setWashCenters] = useState('')
    let [washCenter, setWashCenter] = useState('')
    let [carTypes, setCarTypes] = useState('')
    let [carType, setCarType] = useState('')
    let [carTypeId, setCarTypeId] = useState('')
    let [totalAmount, setTotalAmount] = useState('')
    let [selectedServiceDetails, setSelectedServiceDetails] = useState('')
    let [selectedDate, setSelectedDate] = useState('')
    let [times, setTimes] = useState('')
    let [bookingDate, setBookingDate] = useState('')
    let [bookingDateValue, setBookingDateValue] = useState('')
    let [bookingDateDisplay, setBookingDateDisplay] = useState('')
    let [bookingTime, setBookingTime] = useState('')
    let [bookingTimeDisplay, setBookingTimeDisplay] = useState('')
    let [bookingTimeValue, setBookingTimeValue] = useState('')
    let [serviceTimes, updateServiceTimes] = useState('')
    let [serviceTimes1, updateServiceTimes1] = useState('')
    let [packageTypes, setPackageTypes] = useState(null)
    let [selectedPackageType, setSelectedPackageType] = useState(null)
    let [selectedPackageTypeObject, setSelectedPackageTypeObject] = useState(null)
    let [buildings, setBuildings] = useState(null)
    let [allBuildings, setAllBuildings] = useState(null)
    let [selectedBuilding, setSelectedBuilding] = useState(null)
    let [selectedBuildingObject, setSelectedBuildingObject] = useState(null)
    let [packageSubscriptionTypes, setPackageSubscriptionTypes] = useState([]);
    let [selectedPackageSubscriptionType, setSelectedPackageSubscriptionType] = useState('');
    let [selectedPackageSubscriptionTypeObject, setSelectedPackageSubscriptionTypeObject] = useState(null);
    let [packages, setPackages] = useState(null)
    let [allPackages, setAllPackages] = useState(null)
    let [selectedPackage, setSelectedPackage] = useState(null)
    let [selectedPackageObject, setSelectedPackageObject] = useState(null)
    let [startingDate, setStartingDate] = useState(null)
    let [shifts, setShifts] = useState([])
    let [allShifts, setAllShifts] = useState([])
    let [selectedShift, setSelectedShift] = useState(null)
    let [selectedShiftObject, setSelectedShiftObject] = useState(null)
    let [days, setDays] = useState([])
    let [selectedDay, setSelectedDay] = useState(null)
    let [selectedDayObject, setSelectedDayObject] = useState(null)
    let [quantity, setQuantity] = useState(1)
    let [paidOptionValues, setPaidOptionValues] = useState([])
    let [paidOption, setPaidOption] = useState({})
    let [selectedPaidOption, setSelectedPaidOption] = useState('')
    let [plateNumbers, setPlateNumbers] = useState([{plate_number: ""}])
    let [bookingDetails, setBookingDetails] = useState({})
    let [processing, setProcessing] = useState(false)

    useEffect(() => {
        const language = localStorage.getItem('language')
        if(language) {
            i18n.changeLanguage(language)
        }
        setLanguage(language)

        let paymentStatusValues = [
            {
                'type_en': 'Paid',
                'type_ar': 'مدفوع',
                'type_value': 'paid'
            },
            {
                'type_en': 'Not paid',
                'type_ar': 'غير مدفوع',
                'type_value': 'not_paid'
            }
        ]
        setPaymentStatusValues(paymentStatusValues)

        let paidOptionValues = [
            {
                'type_en': 'Cash',
                'type_ar': 'نقدي',
                'type_value': 'cash'
            },
            {
                'type_en': 'Bank transfer',
                'type_ar': 'التحويل المصرفي',
                'type_value': 'bank_transfer'
            }
        ]
        setPaidOptionValues(paidOptionValues)

        const role = localStorage.getItem('roleId')
        setRoleId(role)

        axios.get(API_URL + `/car/types`)
        .then(response => {
            let car_types = response.data.car_types
            setCarTypes(car_types)

            axios.get(API_URL + `/properties`)
            .then(response1 => {
                let bldng = response1.data.properties;
                setAllBuildings(response1.data.properties)
                formatSelectData(bldng, 'properties');

                axios.get(API_URL + `/shifts`)
                .then(response2 => {
                    let shf = response2.data.shifts;
                    setAllShifts(response2.data.shifts);
                    shf = formatSelectData(shf, 'shifts');

                    axios.get(API_URL + `/days`)
                    .then(response3 => {
                        let dys = response3.data.days;
                        dys = formatSelectData(dys, 'days');

                        axios.get(API_URL + `/package-subscription-types`)
                        .then(response4 => {
                            let pst = response4.data.package_subscription_types
                            pst = formatSelectData(pst, 'package_subscription_types');
                
                            axios.get(API_URL + `/package-types`)
                            .then(response5 => {
                                let pck = response5.data.package_types;
                                pck = formatSelectData(pck, 'package_types');
                
                                axios.get(API_URL + `/packages`)
                                .then(response6 => {
                                    let pckge = response6.data.packages;
                                    setPackages(pckge)
                                    setAllPackages(pckge)
                                    pckge = formatSelectData(pckge, 'packages');

                                    axios.get(API_URL + `/one-booking/${params.id}`)
                                    .then(response7 => {
                                        let bkng = response7.data.booking;
                                        setBookingDetails(bkng);
                                        if(bkng) {
                                            assignBookingSections(bkng, pckge)
                                        }
                                    })
                                })
                            })
                        })
                    })
                })
            })
        })
    }, [])

    const assignBookingSections = (booking, pckge) => {
        assignCustomerDetails(booking)
        assignPackageDetails(booking, pckge)
        assignDateTime(booking)
    }

    const assignCustomerDetails = (booking) => {
        setGuestName(booking.customer_first_name + ' ' + booking.customer_last_name)
        setEmail(booking.email)
        setPhone(booking.phone_number)
        setFlatNumber(booking.flat_number)
        let bldg = {
            value: booking.property_id,
            label: language == 'ar' ? booking.property_name_ar : booking.property_name
        }
        setSelectedBuilding(bldg);
        setSelectedBuildingObject(bldg);
        let cust = {
            guestname: booking.customer_first_name + ' ' + booking.customer_last_name,
            email: booking.email,
            phonenumber: booking.phone_number,
            property_id: booking.property_id,
            property: language == 'ar' ? booking.property_name_ar : booking.property_name
        }
        setCustomer(cust)
    }

    const assignPackageDetails = (booking, pckge) => {
        let pcktype = {
            value: booking.package_type_id,
            label: language == 'ar' ? booking.package_type_name_ar : booking.package_type_name
        }
        setSelectedPackageTypeObject(pcktype);
        setSelectedPackageType(pcktype);

        let pcksub = {
            value: booking.package_subscription_type_id,
            label: language == 'ar' ? booking.package_subscription_type_name_ar : booking.package_subscription_type_name
        }
        setSelectedPackageSubscriptionTypeObject(pcksub);
        setSelectedPackageSubscriptionType(pcksub.value);

        let pck = {
            value: booking.package_id,
            label: language == 'ar' ? booking.name_ar : booking.name
        }
        setSelectedPackageObject(pck);
        setSelectedPackage(pck);

        let details
        if(pckge?.length > 0) {
            details = pckge.filter(ap => {
                return ap.value == pck.value
            })
        }

        setSelectedServiceDetails(details?.length > 0 ? details[0] : '')
        setTotalAmount(booking.amount)
        setCarTypeId(booking.car_type_id)
        setCarType(booking.car_type)
        setSvc(pck.value)

        let wc_service = {
            currency: 'BD',
            serviceid: pck.value,
            service: details?.length > 0 ? details[0].label : '',
            cartypeid: booking.car_type_id,
            cartype: booking.car_type,
            quantity:  booking.quantity,
            totalamount: booking.amount
        }
        setService(wc_service)

        // Plate No.
        setQuantity(booking.quantity);
        let plate_numbers = []
        for(var i = 0; i < booking.quantity; i ++) {
            plate_numbers.push({
                'plate_number': booking.plate_number
            })
        }
        setPlateNumbers(plate_numbers)
    }

    const assignDateTime = (booking) => {
        // Starting Date
        if(booking.package_type_id == 1) {
            setSelectedPackageType(1)
            if(booking.starting_date) {
                let startingdate = booking.starting_date
                if(booking?.schedules?.length > 0) {
                    let plus1month = moment(booking?.schedules[0].booking_date).add(1, 'months').format('YYYY-MM-DD')
                    let wkday = moment(booking?.schedules[0].booking_date).week()
                    let nextStartDate = moment().day('Monday').week(moment(plus1month).week()).format('YYYY-MM-DD')
        
                    // Next start date is not a future date
                    if(moment(moment().format('YYYY-MM-DD')).isAfter(moment(nextStartDate).format('YYYY-MM-DD'))) {
                        for (var m = moment(nextStartDate); m.isBefore(moment().add(5, 'days').format('YYYY-MM-DD')); m.add(1, 'weeks')) {
                            nextStartDate = m.format('YYYY-MM-DD')
                        }
                    }
                    startingdate = nextStartDate
                }

                setStartingDate(startingdate)
                setBookingDate(startingdate)
                setBookingDateValue(startingdate)
                setBookingDateDisplay(moment(startingdate).format("ddd MMM D"))
            }
        }

        // Shift
        if(booking?.schedules?.length > 0) {
            let shift_text = language == 'ar' ? `${booking?.schedules[0].name_ar}` :  `${booking?.schedules[0].name}`
            shift_text += ` ${booking?.schedules[0].start_time_display} - ${booking?.schedules[0].end_time_display}`
            let shft = {
                value: booking?.schedules[0].booking_shift_id,
                label: shift_text
            }
            setSelectedShiftObject(shft);
            setSelectedShift(shft.value);
        }

        // Days
        let dy = []
        if(booking.booking_days?.length > 0) {
            booking.booking_days.forEach(bd => {
                let day_name 
                if(bd.day == 'Sun') { day_name = 'Sunday' }
                if(bd.day == 'Mon') { day_name = 'Monday' }
                if(bd.day == 'Tue') { day_name = 'Tuesday' }
                if(bd.day == 'Wed') { day_name = 'Wednesday' }
                if(bd.day == 'Thu') { day_name = 'Thursday' }
                if(bd.day == 'Fri') { day_name = 'Friday' }
                if(bd.day == 'Sat') { day_name = 'Saturday' }
                dy.push({
                    value: bd.id,
                    label: day_name
                })
            })
            setSelectedDay(dy);
            setSelectedDayObject(dy);
        }
    }

    const formatSelectData = (array, mode) => {
        let new_array = []
        if(array.length > 0) {
            array.forEach(item => {
                new_array.push({
                    value: item.id,
                    label: mode == 'shifts' ? (language == 'ar' ? `${(item.name_ar || item.day_ar)} ${moment(moment().format('YYYY-MM-DD') + ' ' + item.start_time).format('h A')}-${moment(moment().format('YYYY-MM-DD') + ' ' + item.end_time).format('h A')}` : `${(item.name || item.day)} ${moment(moment().format('YYYY-MM-DD') + ' ' + item.start_time).format('h A')}-${moment(moment().format('YYYY-MM-DD') + ' ' + item.end_time).format('h A')}`) 
                        : (language == 'ar' ? ((item.name_ar) ? (item.name_ar || item.day_ar) : (item.name || item.day)) : (item.name || item.day))
                })
            })
        }

        if(mode == 'days') { setDays(new_array); }
        if(mode == 'shifts') { setShifts(new_array); }
        if(mode == 'package_types') { setPackageTypes(new_array); }
        if(mode == 'package_subscription_types') { setPackageSubscriptionTypes(new_array); }
        if(mode == 'packages') { setPackages(new_array); }
        if(mode == 'properties') { setBuildings(new_array); }
        return new_array;
    }

    const handlePackageTypeChange = (data) => {
        setSelectedPackageTypeObject(data);
        setSelectedPackageType(data.value);

        if(allPackages?.length > 0) {
            let pkgs = allPackages && allPackages.filter(ap => {
                return ap.package_type_id == data.value
            })
            setPackages(pkgs)
            formatSelectData(pkgs, 'packages')
        }
    };

    const handleBuildingChange = (data) => {
        setSelectedBuildingObject(data);
        setSelectedBuilding(data);
    }

    const handleShiftChange = (data) => {
        setSelectedShiftObject(data);
        setSelectedShift(data.value);
    };

    const handleDayChange = (data) => {
        setSelectedDay(data);
        setSelectedDayObject(data);
    };

    const handlePackageSubscriptionTypeChange = (data) => {
        setSelectedPackageSubscriptionTypeObject(data);
        setSelectedPackageSubscriptionType(data.value);

        if(allPackages?.length > 0) {
            let pkgs = allPackages && allPackages.filter(ap => {
                return ap.package_subscription_type_id == data.value
            })
            setPackages(pkgs)
            formatSelectData(pkgs, 'packages')
        }
    };

    const handlePackageChange = (data) => {
        setSelectedPackageObject(data);
        setSelectedPackage(data);

        let service_id = data.value
        let car_type_id = 1
        let car_type = 'Small'

        let details
        if(allPackages?.length > 0) {
            details = allPackages.filter(ap => {
                return ap.id == data.value
            })
        }

        let total_amount = details?.length > 0 ? details[0].small_price : 0
        setTotalAmount(total_amount)
        setSelectedServiceDetails(details?.length > 0 ? details[0] : '')

        setCarTypeId(car_type_id)
        setCarType(car_type)
        setSvc(service_id)
    }

    async function addBooking(e) {
        e.preventDefault()

        if(
            customer && customer?.guestname &&
            selectedServiceDetails &&
            (
                (selectedPackageType == 1 && bookingDateValue && selectedShift) ||
                (selectedPackageType != 1 && bookingDateValue && bookingTimeValue)
            ) &&
            selectedPaymentStatus
        ) {
            setProcessing(true)
            let cars = []
            if(quantity > 0) {
                for (let i = 1; i <= quantity; i++) {
                    cars.push({
                        id: selectedServiceDetails.id || selectedServiceDetails.value,
                        service: (language == 'ar' ? selectedServiceDetails.name_ar : selectedServiceDetails.name) || selectedServiceDetails.label,
                        quantity: 1,
                        car_type_id: carTypeId,
                        car_type: carType,
                        car_type_image: (carType.toString().toLowerCase() + '.png'),
                        plate_number: plateNumbers[i - 1].plate_number,
                    })
                }
            }
            let cart = [
                {
                    package_id: selectedServiceDetails.id || selectedServiceDetails.value,
                    currency: 'BD',
                    amount: totalAmount,
                    small_count: carType == 'Small' ? quantity : 0,
                    medium_count: carType == 'Medium' ? quantity : 0,
                    large_count: carType == 'Large' ? quantity : 0,
                    cars
                }
            ]

            let selected_days = []
            if(selectedDayObject?.length > 0) {
                selectedDayObject.forEach(d => {
                    selected_days.push({
                        id: d.value,
                        day: d.label.substr(0, 3),
                        selected: 1
                    })
                })
            }

            let shift_time
            let selected_time
            
            if(selectedPackageType == 1) {
                let shift_row
                if(allShifts?.length > 0) {
                    shift_row = allShifts.filter(shift => {
                        return shift.id == selectedShiftObject.value
                    })
                }
                selected_time = {
                    "id": selectedShiftObject.value,
                    "time": shift_row?.length > 0 ? shift_row[0].start_time : '',
                    "time_value": shift_row?.length > 0 ? shift_row[0].end_time : '',
                    "time_display": shift_row?.length > 0 ? (shift_row[0].name + ' ' + moment(moment().format('YYYY-MM-DD') + ' ' + shift_row[0].start_time).format('h A') + ' - ' + moment(moment().format('YYYY-MM-DD') + ' ' + shift_row[0].end_time).format('h A')) : '',
                    "status": "open"
                }
            }

            let reference_number = Math.random().toString(36).substring(7)
            
            let params = {
                customer: {
                    guestname: customer.guestname,
                    email: customer.email,
                    phonenumber: customer.phonenumber,
                    property_id: customer.property_id
                },
                paid_option: paymentStatus.type_value == 'paid' ? selectedPaidOption : '',
                property: { id: customer.property_id },
                total_amount: totalAmount,
                payment_method_id: paymentStatus.type_value == 'paid' ? (selectedPaidOption == 'Cash' ? 1 : null) : null,
                cart,
                reference_number,
                flat_number: flatNumber,
                selected_days,
                package_type_id: selectedPackageType == 1 ? 1 : 2,
                date_time: selectedPackageType == 1 ? '' : (bookingDateValue + ' ' + bookingTimeValue + ':00'),
                starting_date: selectedPackageType == 1 ? startingDate : '',
                selected_time: selectedPackageType == 1 ? selected_time : '',
                renew_assigned_staff_id: bookingDetails?.assigned_staff_id,
                renew: 1,
                renew_booking_id: bookingDetails?.booking_id,
                source: 'web'
            }

            let url
            if(selectedPackageType == 1) {
                url = API_URL + `/property/book/monthly`
            } else {
                url = API_URL + `/property/book/one-time`
            }
            let response = await axios.post(url, params)
            if(response.data && response.data.message == 'success') {
                history.push("/bookings")
            }
        }
    }

    function showCustomer(e) {
        e.preventDefault()
        setSectionViewTitle(t('addbooking.customer'))
        setSummaryView(false)
        setCustomerView(true)
        setServiceView(false)
        setDateTimeView(false)
        setDiscountView(false)
        setPaymentStatusView(false)
        setNotesView(false)
    }

    function showService(e) {
        e.preventDefault()
        setSectionViewTitle(t('addbooking.service'))
        setSummaryView(false)
        setCustomerView(false)
        setServiceView(true)
        setDateTimeView(false)
        setDiscountView(false)
        setPaymentStatusView(false)
        setNotesView(false)
    }

    function showDateTime(e) {
        e.preventDefault()
        setSectionViewTitle(t('addbooking.dateandtime'))
        setSummaryView(false)
        setCustomerView(false)
        setServiceView(false)
        setDateTimeView(true)
        setDiscountView(false)
        setPaymentStatusView(false)
        setNotesView(false)
    }

    function showPaymentStatus(e) {
        e.preventDefault()
        setSectionViewTitle(t('addbooking.paymentstatus'))
        setSummaryView(false)
        setCustomerView(false)
        setServiceView(false)
        setDateTimeView(false)
        setDiscountView(false)
        setPaymentStatusView(true)
        setNotesView(false)
    }

    function resetView() {
        setCustomerView(false)
        setServiceView(false)
        setDateTimeView(false)
        setPaymentStatusView(false)
        setSummaryView(true)
    }

    function handleClose(e) {
        resetView()
    }

    function handleCloseSection(e) {
        setSummaryView(true)
    }

    function saveSectionView(e) {
        if(sectionViewTitle == 'Customer') {
            if(guestName && phone) {
                let cust = {
                    guestname: guestName,
                    email: email,
                    phonenumber: phone,
                    property_id: selectedBuilding?.value,
                    property: selectedBuilding?.label
                }
                setCustomer(cust)
            } else {
                let cust = {}
                setCustomer(cust)
            }
        }

        if(sectionViewTitle == 'Service') {
            if(selectedServiceDetails && totalAmount && parseFloat(totalAmount) > 0 && carTypeId) {
                let wc_service = {
                    currency: 'BD',
                    serviceid: svc,
                    service: selectedServiceDetails.name,
                    cartypeid: carTypeId,
                    cartype: carType,
                    quantity:  quantity,
                    totalamount: totalAmount
                }
                setService(wc_service)
            } else {
                let wc_service = {}
                setService(wc_service)
            }
        }

        if(sectionViewTitle == 'Date & Time') {
            if(selectedPackageType == 1) {
                if(startingDate) {
                    setBookingDate(startingDate)
                    setBookingDateValue(startingDate)
                    setBookingDateDisplay(moment(startingDate).format("ddd MMM D"))
                }
            } else {
                if(selectedDate && bookingTime) {
                    let date_value = moment(selectedDate).format("YYYY-MM-DD")
                    let date_display = moment(selectedDate).format("ddd MMM D")
                    setBookingDate(date_value)
                    setBookingDateValue(date_value)
                    setBookingDateDisplay(date_display)
                }
            }
        }

        resetView()
    }

    const handleFieldChange = (event, type) => {
        switch(type) {
            case 'guestname': 
                setGuestName(event.target.value)
                break
            case 'email': 
                setEmail(event.target.value)
                break
            case 'phone': 
                setPhone(event.target.value)
                break
            case 'flatNumber': 
                setFlatNumber(event.target.value)
                break
            case 'startingDate': 
                setStartingDate(event.target.value);
                break;
            case 'quantity': 
                setQuantity(event.target.value);
                if(carTypes) {
                    carTypes.forEach(ct => {
                        if(ct.id == carTypeId) {
                            if(selectedServiceDetails)  {
                                let total_amount 
                                if(ct.type == 'Small') {
                                    total_amount = event.target.value * parseFloat(selectedServiceDetails.small_price)
                                } else if(ct.type == 'Medium') {
                                    total_amount = event.target.value * parseFloat(selectedServiceDetails.medium_price)
                                } else if(ct.type == 'Large') {
                                    total_amount = event.target.value * parseFloat(selectedServiceDetails.large_price)
                                }
                                setTotalAmount(total_amount)
                            }
                        }
                    })
                }
                let plate_numbers = []
                for(var i = 0; i < event.target.value; i ++) {
                    plate_numbers.push({
                        'plate_number': ''
                    })
                }
                setPlateNumbers(plate_numbers)
                break;
        }
    }

    const handlePaymentStatusChange = (event) => {
        setPaidOption({})
        setSelectedPaidOption('')
        if(paymentStatusValues) {
            paymentStatusValues.forEach(p => {
                if(p.type_value == event.target.value) {
                    setPaymentStatus(p)
                    let translation_key = 'addbooking.' + event.target.value.replace('_', '')
                    setSelectedPaymentStatus(t(translation_key))
                }
            })
        }
    }

    const handlePaidOptionChange = (event) => {
        if(paidOptionValues) {
            paidOptionValues.forEach(p => {
                if(p.type_value == event.target.value) {
                    setPaidOption(p)
                    let translation_key = 'addbooking.' + event.target.value.replace('_', '')
                    setSelectedPaidOption(t(translation_key))
                }
            })
        }
    }

    const handleCarTypeChange = (event) => {
        setCarTypeId(event.target.value)
        if(carTypes) {
            carTypes.forEach(ct => {
                if(ct.id == event.target.value) {
                    setCarType(ct.type)

                    if(selectedServiceDetails)  {
                        let total_amount 
                        if(ct.type == 'Small') {
                            total_amount = parseFloat(quantity) * parseFloat(selectedServiceDetails.small_price)
                        } else if(ct.type == 'Medium') {
                            total_amount = parseFloat(quantity) * parseFloat(selectedServiceDetails.medium_price)
                        } else if(ct.type == 'Large') {
                            total_amount = parseFloat(quantity) * parseFloat(selectedServiceDetails.large_price)
                        }
                        setTotalAmount(total_amount)
                    }
                }
            })
        }
        let plate_numbers = []
        for(var i = 0; i < (quantity || 1); i++) {
            plate_numbers.push({
                'plate_number': ''
            })
        }
        setPlateNumbers(plate_numbers)
    }

    const handleDateClick = async(ob) => {
        if(ob.current) {
            setSelectedDate(ob.current)

            let date = ob.current
            let date_value = moment(date).format("YYYY-MM-DD")
            let date_display = moment(date).format("ddd MMM D")
            var dayOfWeek = new Date(date_value).getDay()
            if(dayOfWeek == 0) {
                dayOfWeek = 7;
            }   

            if(allBuildings?.length > 0) {
                let bldg_row = allBuildings.filter(ab => {
                    return ab.id == selectedBuilding.value
                })
                washCenter = bldg_row?.length > 0 ? bldg_row[0].wash_center_id : 0
            }
            let res =  await axios.get(API_URL + `/business/available/hours/new/${washCenter}/${dayOfWeek}`)
            let bhs = res.data.available_hours

            let resp = await axios.get(API_URL + `/wash-center/calendar/hours/${washCenter}`)
            let breakTimeHours = []
            if(resp.data) {
                breakTimeHours = resp.data.calendar_hours.breakTimeHours
            }

            let bh = [];
            if(bhs) {
                let current_time = moment(new Date()).utcOffset('+0300').add(1, 'hours').format('HH:mm');
                let current_date = moment(new Date()).utcOffset('+0300').format('YYYY-MM-DD');
                if(current_date == date_value) {
                    bhs.forEach(ah => {
                        if(ah.time_value >= current_time) {
                            bh.push({
                                "id": ah.id,
                                "time": ah.time,
                                "time_value": ah.time_value,
                                "time_display": ah.time_display,
                                "status": ah.status
                            })
                        }
                    })
                    bhs = bh;
                }
            }
            
            let respon = await axios.get(API_URL + `/business/service-slots/${washCenter}`)
            let serviceSlots = respon.data.service_slots
            if(date_value && bhs) {
                let business_hours = bhs;
                bhs = [];
                business_hours.forEach(bh => {
                    let match = serviceSlots.some(a => a.date === date_value && a.time === (bh.time_value + ':00') && a.status == 'full');
                    let status = bh.status;
                    if(match) {
                        status = 'full';
                    }

                    let breakMatch = breakTimeHours.some(a => a.dayOfWeek == dayOfWeek && a.startTime == bh.time_value)
                    if(breakMatch) {
                        status = 'full';
                    }

                    bhs.push({
                        id: bh.id,
                        time: bh.time,
                        time_value: bh.time_value,
                        time_display: bh.time_display,
                        status: status
                    });
                })
            }

            let rsp = await axios.get(API_URL + `/business/disabled/dates/${washCenter}`)
            let disabledDates = rsp.data.disabled_days
            let disabledDate = false;
            if(disabledDates) {
                if(disabledDates.indexOf(date_value) >= 0) {
                    disabledDate = true
                    bhs = []
                }
            }

            // Check if no available time
            let busy = false
            if(bhs) {
                let avail = bhs.filter(h => {
                    return h.status != 'full'
                })
                if(!avail || (avail && avail.length == 0)) {
                    busy = true
                }
            }

            updateServiceTimes(bhs)
        }
    }

    function onSingleTimeChange(time) {
        if(time.status != 'full') {
            setBookingTimeDisplay(time.time_display)
            setBookingTime(time.time)
            setBookingTimeValue(time.time_value)
        }
    }

    function handleClose(e) {
        history.push("/bookings")
    }

    const handlePlateNoChange = (index, event) => {
        const values = [...plateNumbers];
        values[index]['plate_number'] = event.target.value;
        setPlateNumbers(values);
    };

    return (
        <div className='inner'>
            <LoggedInTopMenu />
            <div className={classes.content}>
                <Grid
                    justify="space-between"
                    container
                >
                    <Hidden smDown>
                        <div className={classes.sideMenu}>
                            <SideMenu />
                        </div>
                    </Hidden>
                    <div className={classes.mainContent}>
                        <div className={classes.article}>
                            <div className={classes.header}>
                                <div className={classes.title} style={{marginLeft:-25}}>{t('booking.renewbooking')}</div>
                                <div style={{marginRight:25}}><CloseIcon className={classes.close} onClick={handleClose} /></div>
                            </div>
                            <div className={classes.center}>
                                {summaryView &&
                                    <>
                                        <div className={classes.flexContainer} onClick={showCustomer}>
                                            <div className={classes.sectionTitle}>
                                                {t('addbooking.customer')}
                                            </div>
                                            <div className={classes.sectionArrowContainer}>
                                                <div 
                                                    style={{
                                                        paddingTop: 2,
                                                        opacity: customer && customer?.guestname ? 1 : 0.25
                                                    }}
                                                >
                                                    {customer && customer?.guestname ? (customer?.guestname + (customer?.property ? (', ' + customer.property) : '')) : t('addbooking.enter')}
                                                </div>
                                                <ArrowForwardIosIcon 
                                                    className={classes.arrowIcon}
                                                />
                                            </div>
                                        </div>
                                        <div className={classes.flexContainer} onClick={showService}>
                                            <div className={classes.sectionTitle}>
                                                {t('packages.package')}
                                            </div>
                                            <div className={classes.sectionArrowContainer}>
                                                <div 
                                                    style={{
                                                        paddingTop: 2,
                                                        opacity: service && service?.service ? 1 : 0.25
                                                    }}
                                                >
                                                    {service?.service ? (service.service + ', ' + service.quantity + 'x ' + service.cartype) : t('addbooking.select')}
                                                </div>
                                                <ArrowForwardIosIcon 
                                                    className={classes.arrowIcon}
                                                />
                                            </div>
                                        </div>
                                        <div className={classes.flexContainer} onClick={showDateTime}>
                                            <div className={classes.sectionTitle}>
                                                {t('addbooking.dateandtime')}
                                            </div>
                                            <div className={classes.sectionArrowContainer}>
                                                <div 
                                                    style={{
                                                        paddingTop: 2,
                                                        opacity: bookingDate ? 1 : 0.25
                                                    }}
                                                >
                                                    {bookingDate ? (bookingDateDisplay + (bookingTimeDisplay ? (', ' + bookingTimeDisplay) : (', ' + selectedShiftObject?.label))) : t('addbooking.pick')}
                                                </div>
                                                <ArrowForwardIosIcon 
                                                    className={classes.arrowIcon}
                                                />
                                            </div>
                                        </div>
                                        <div className={classes.flexContainer} onClick={showPaymentStatus}>
                                            <div className={classes.sectionTitle}>
                                                {t('addbooking.paymentstatus')}
                                            </div>
                                            <div className={classes.sectionArrowContainer}>
                                                <div 
                                                    style={{
                                                        paddingTop: 2,
                                                        opacity: selectedPaymentStatus ? 1 : 0.25
                                                    }}
                                                >
                                                   {selectedPaymentStatus ? (selectedPaymentStatus + (selectedPaidOption ? (', ' + selectedPaidOption) : '')) : t('addbooking.pick')}
                                                </div>
                                                <ArrowForwardIosIcon 
                                                    className={classes.arrowIcon}
                                                />
                                            </div>
                                        </div>
                                        {totalAmount &&
                                            <div className={classes.amountFlexContainer}>
                                                <div className={classes.totalSectionTitle}>
                                                    {t('addbooking.total')}
                                                </div>
                                                <div className={classes.sectionArrowContainer}>
                                                    <div 
                                                        style={{
                                                            paddingTop: 2,
                                                            opacity: 1,
                                                            fontWeight: 'bold',
                                                            fontSize: 18
                                                        }}
                                                    >
                                                        BD {totalAmount}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {!processing &&
                                            <div className={classes.addBookingContainer}>
                                                <div className={classes.addBookingText}>{t('side.addbooking')}</div>
                                                <div 
                                                    onClick={addBooking}
                                                    className={classes.addBookingBtnContainer} 
                                                >
                                                    <img 
                                                        className={classes.addBookingBtn} 
                                                        src={arrowrightwhiteimage} 
                                                        alt="CarCare" />
                                                </div>
                                            </div>
                                        }
                                        {processing &&
                                            <div className={classes.addBookingContainer}>
                                                <div className={classes.addBookingText}>{t('booking.processing')}</div>
                                            </div>
                                        }
                                    </>
                                }
                                {!summaryView &&
                                    <>
                                        <div className={classes.header}>
                                            <div className={classes.title}>{sectionViewTitle}</div>
                                            <div><CloseIcon className={classes.close} onClick={handleCloseSection} /></div>
                                        </div>
                                        <div className={classes.fieldsContainer}>
                                            <form>
                                                {sectionViewTitle == 'Customer' &&
                                                    <>
                                                        <div className={classes.inputContainerDesc}>
                                                            <TextField 
                                                                id="name"
                                                                label={t('addbooking.name')}
                                                                className={classes.input}
                                                                placeholder={t('addbooking.name')}
                                                                value={guestName || ''}
                                                                onChange={(event) => handleFieldChange(event, 'guestname')}
                                                            />
                                                        </div>
                                                        <div className={classes.inputContainerDesc}>
                                                            <TextField 
                                                                id="email"
                                                                label={t('addbooking.email')}
                                                                className={classes.input}
                                                                placeholder={t('addbooking.email')}
                                                                value={email || ''}
                                                                onChange={(event) => handleFieldChange(event, 'email')}
                                                            />
                                                        </div>
                                                        <div className={classes.inputContainerDesc}>
                                                            <TextField 
                                                                id="phone"
                                                                label={t('addbooking.phone')}
                                                                className={classes.input}
                                                                placeholder={t('addbooking.phone')}
                                                                value={phone || ''}
                                                                onChange={(event) => handleFieldChange(event, 'phone')}
                                                            />
                                                        </div>
                                                        <div style={{marginTop:25}}>
                                                            <Select
                                                                placeholder={t('users.selectbuilding')}
                                                                value={selectedBuildingObject}
                                                                onChange={handleBuildingChange}
                                                                options={buildings}
                                                            />
                                                        </div>
                                                        <div className={classes.inputContainerDesc}>
                                                            <TextField 
                                                                id="phone"
                                                                label={t('dashboard.flatno')}
                                                                className={classes.input}
                                                                placeholder={t('dashboard.flatno')}
                                                                value={flatNumber || ''}
                                                                onChange={(event) => handleFieldChange(event, 'flatNumber')}
                                                            />
                                                        </div>
                                                    </>
                                                }
                                                {sectionViewTitle == 'Service' &&
                                                    <>
                                                        <div style={{marginTop:15}}>
                                                            <Select
                                                                placeholder={t('packages.selecttype')}
                                                                value={selectedPackageTypeObject}
                                                                onChange={handlePackageTypeChange}
                                                                options={packageTypes}
                                                                name="packagetype"
                                                                required
                                                            />
                                                        </div>
                                                        {selectedPackageType == 1 &&
                                                            <div style={{marginTop:15}}>
                                                                <Select
                                                                    className={classes.input}
                                                                    placeholder={t('packages.selecttype')}
                                                                    value={selectedPackageSubscriptionTypeObject}
                                                                    onChange={handlePackageSubscriptionTypeChange}
                                                                    options={packageSubscriptionTypes}
                                                                    name="packagetype"
                                                                    required
                                                                />
                                                            </div>
                                                        }
                                                        <div style={{marginTop:15,marginBottom:10}}>
                                                            <Select
                                                                placeholder={t('services.selectservice')}
                                                                value={selectedPackageObject}
                                                                onChange={handlePackageChange}
                                                                options={packages}
                                                                required
                                                            />
                                                        </div>
                                                        <div className={styles.flexContainer}>
                                                            {carTypes && carTypes.map((ct) => (
                                                                <div>
                                                                    <FormControlLabel
                                                                        value="end" 
                                                                        control={<Radio 
                                                                            checked={carType == ct.type}
                                                                            onChange={handleCarTypeChange}
                                                                            value={ct.id}
                                                                            name="radio-button-demo"
                                                                            inputProps={{ 'aria-label': ct.type }} />} 
                                                                            label={ct.type} />
                                                                </div>
                                                            ))}
                                                        </div>
                                                        <div className={styles.flexContainer} style={{marginBottom:15}}>
                                                            {plateNumbers && plateNumbers.map((el, i) => (
                                                                <TextField 
                                                                    id="phone"
                                                                    label={t('addbooking.plateno')}
                                                                    className={classes.input}
                                                                    placeholder={t('addbooking.plateno')}
                                                                    value={el.plate_number || ""}
                                                                    onChange={e => handlePlateNoChange(i, e)}
                                                                />
                                                            ))}
                                                        </div>
                                                        <div className={classes.inputContainerDesc}>
                                                            <TextField 
                                                                id="quantity"
                                                                type="number"
                                                                label={t('packages.quantity')}
                                                                className={classes.input}
                                                                placeholder={t('packages.quantity')}
                                                                value={quantity || ''}
                                                                inputProps={{ min: 1, max: 10 }}
                                                                onChange={(event) => handleFieldChange(event, 'quantity')}
                                                                fullWidth
                                                            />
                                                        </div>
                                                        <div className={classes.inputContainerDesc}>
                                                            <TextField 
                                                                id="amount"
                                                                label={t('addbooking.amount')}
                                                                className={classes.input}
                                                                placeholder={t('addbooking.amount')}
                                                                value={totalAmount || ''}
                                                                readonly
                                                            />
                                                        </div>
                                                    </>
                                                }
                                                {sectionViewTitle == 'Date & Time' &&
                                                    <>
                                                        {selectedPackageType == 1 ?
                                                            (
                                                                <div>
                                                                    <div className={classes.inputContainerDesc}>
                                                                        <TextField 
                                                                            id="startingDate"
                                                                            type="date"
                                                                            className={classes.input}
                                                                            placeholder={t('packages.startingdate')}
                                                                            value={startingDate || ''}
                                                                            onChange={(event) => handleFieldChange(event, 'startingDate')}
                                                                            fullWidth
                                                                        />
                                                                    </div>
                                                                    <div className={classes.inputContainerDesc} style={{marginTop:25}}>
                                                                        <Select
                                                                            placeholder={t('users.selectshift')}
                                                                            value={selectedShiftObject}
                                                                            onChange={handleShiftChange}
                                                                            options={shifts}
                                                                            required
                                                                        />
                                                                    </div>
                                                                    <div style={{marginTop:25}}>
                                                                        <Select
                                                                            placeholder={t('buildings.selectdays')}
                                                                            value={selectedDayObject}
                                                                            onChange={handleDayChange}
                                                                            options={days}
                                                                            isMulti
                                                                        />
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div>
                                                                    <div id="service-info-edit-current-calendar">
                                                                        <Calendar
                                                                            isMultiple={false}
                                                                            selected={selectedDate} 
                                                                            onChange={handleDateClick}
                                                                        />
                                                                    </div>
                                                                    <div className={classes.timeContainer}>
                                                                        {serviceTimes && serviceTimes.map((time, i) => (
                                                                            <div
                                                                                onClick={() => onSingleTimeChange(time)}
                                                                                style={{
                                                                                    border: `solid 1px rgb(159, 0, 0)`,
                                                                                    borderRadius: 5,
                                                                                    padding: 10,
                                                                                    marginRight: 10,
                                                                                    width: 100,
                                                                                    textAlign: 'center',
                                                                                    alignItems: 'center',
                                                                                    marginBottom: 10,
                                                                                    backgroundColor: bookingTime == time.time ? '#9F0000' : '#FFFFFF'
                                                                                }}
                                                                            >
                                                                                <div
                                                                                    style={{
                                                                                        fontSize: 14,
                                                                                        fontFamily: 'Avenir-Roman',
                                                                                        color: time?.status == 'full' ? '#a7a7a7' : (bookingTime == time.time ? '#FFFFFF' : '#9F0000')
                                                                                    }}
                                                                                >
                                                                                    {time.time}
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    </>
                                                }
                                                {sectionViewTitle == 'Payment Status' &&
                                                    <div>
                                                        {paymentStatusValues && paymentStatusValues.map((ps) => (
                                                            <div>
                                                                <FormControlLabel
                                                                    value="end" 
                                                                    control={<Radio 
                                                                        checked={paymentStatus.type_value == ps.type_value}
                                                                        onChange={handlePaymentStatusChange}
                                                                        value={ps.type_value}
                                                                        name="radio-button-demo"
                                                                        inputProps={{ 'aria-label': ps.type_en }} />} 
                                                                    label={localStorage.getItem('language') == 'ar' ? (ps.type_ar ? ps.type_ar : ps.type_en) : ps.type_en} />
                                                            </div>
                                                        ))}
                                                        {paymentStatus.type_value == 'paid' &&
                                                            <>
                                                                <div style={{marginTop:15}}>{t('addbooking.paidoptions')}: </div>
                                                                <div>
                                                                    {paidOptionValues && paidOptionValues.map((pd) => (
                                                                        <div>
                                                                            <FormControlLabel
                                                                                value="end" 
                                                                                control={<Radio 
                                                                                    checked={paidOption.type_value == pd.type_value}
                                                                                    onChange={handlePaidOptionChange}
                                                                                    value={pd.type_value}
                                                                                    name="radio-button-demo"
                                                                                    inputProps={{ 'aria-label': pd.type_en }} />} 
                                                                                label={localStorage.getItem('language') == 'ar' ? (pd.type_ar ? pd.type_ar : pd.type_en) : pd.type_en} />
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                }
                                            </form>
                                        </div>
                                        <div className={classes.addBookingContainer}>
                                            <div className={classes.saveText}>{t('addbooking.save')}</div>
                                            <div 
                                                onClick={saveSectionView}
                                                className={classes.addBookingBtnContainer} 
                                            >
                                                <img 
                                                    className={classes.addBookingBtn} 
                                                    src={arrowrightwhiteimage} 
                                                    alt="CarCare" />
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </Grid>
            </div>
        </div>
    );
};
  
export default RenewBooking;
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useParams } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { Grid } from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';
import { API_URL } from '../constants';
import { useTranslation } from 'react-i18next';
import LoggedInTopMenu from '../components/LoggedInTopMenu';
import SideMenu from '../components/SideMenu';
import Hidden from '@material-ui/core/Hidden';
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import DataTable from 'react-data-table-component';
import Select from 'react-select';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  content: {
    paddingTop: 130,
    marginLeft: '-6%'
  },
  sideMenu: {
    backgroundColor: '#9F0000',
    width: 256,
    height: `calc(100vh - 130px)`,
    borderTopRightRadius: 75,
    color: '#fff',
    paddingTop: 70
  },
  mainContent: {
    width: `calc(100% - 256px)`,
    textAlign: 'left',
    ['@media (max-width:959)']: { 
      width: '100%',
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  article: {
    paddingTop: 20,
    paddingLeft: 50
  },
  activePageHeader: {
    fontSize: 20,
    fontWeight: 'bold',
    paddingRight: 40,
    color: '#000',
    textDecoration: 'none',
  },
  pageHeader: {
    fontSize: 20,
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  serviceHeader: {
    paddingTop: 20,
    borderBottomWidth: 1,
    borderBottomColor: '#d3d3d3',
    borderBottomStyle: 'solid'
  },
  indentedHeader: {
    color: '#000',
    opacity: 0.25,
    fontSize: 16,
    textAlign: 'left',
    paddingLeft: '3%',
    ['@media (max-width:959px)']: { 
      paddingLeft: 0
    },
    ['@media (min-width:959px)']: { 
      visibility: 'hidden'
    }
  },
  leftHeader: {
    color: '#000',
    opacity: 0.25,
    fontSize: 16,
    textAlign: 'left',
    ['@media (min-width:959px)']: { 
      visibility: 'hidden'
    }
  },
  header: {
    color: '#000',
    opacity: 0.25,
    fontSize: 16,
    textAlign: 'center',
  },
  col1: {
    textAlign: 'left',
    height: 60,
    borderBottomWidth: 1,
    borderBottomColor: '#d3d3d3',
    borderBottomStyle: 'solid',
    width: '25%',
    paddingLeft: '3%',
    verticalAlign: 'baseline',
    ['@media (max-width:768px)']: { 
      width: '100%',
      paddingLeft: 0
    }
  },
  col2: {
    textAlign: 'left',
    height: 60,
    borderBottomWidth: 1,
    borderBottomColor: '#d3d3d3',
    borderBottomStyle: 'solid',
    width: '5%',
    verticalAlign: 'baseline',
    ['@media (max-width:768px)']: { 
      width: '100%'
    }
  },
  col3: {
    textAlign: 'center',
    height: 60,
    borderBottomWidth: 1,
    borderBottomColor: '#d3d3d3',
    borderBottomStyle: 'solid',
    width: '10%',
    verticalAlign: 'baseline',
    ['@media (max-width:768px)']: { 
      width: '100%'
    }
  },
  col4: {
    textAlign: 'center',
    height: 60,
    borderBottomWidth: 1,
    borderBottomColor: '#d3d3d3',
    borderBottomStyle: 'solid',
    width: '10%',
    verticalAlign: 'baseline',
    ['@media (max-width:768px)']: { 
      width: '100%'
    }
  },
  col5: {
    textAlign: 'center',
    height: 60,
    borderBottomWidth: 1,
    borderBottomColor: '#d3d3d3',
    borderBottomStyle: 'solid',
    width: '10%',
    verticalAlign: 'baseline',
    ['@media (max-width:768px)']: { 
      width: '100%'
    }
  },
  col6: {
    textAlign: 'left',
    height: 60,
    borderBottomWidth: 1,
    borderBottomColor: '#d3d3d3',
    borderBottomStyle: 'solid',
    width: '25%',
    verticalAlign: 'baseline',
    ['@media (max-width:768px)']: { 
      width: '100%',
      height: 'auto'
    }
  },
  col7: {
    textAlign: 'center',
    height: 60,
    borderBottomWidth: 1,
    borderBottomColor: '#d3d3d3',
    borderBottomStyle: 'solid',
    width: '15%',
    verticalAlign: 'baseline',
    ['@media (max-width:768px)']: { 
      width: '100%'
    }
  },
  colAdd: {
    paddingLeft: '3%',
    height: 60,
    borderBottomWidth: 1,
    borderBottomColor: '#d3d3d3',
    borderBottomStyle: 'solid',
    ['@media (max-width:768px)']: { 
      width: '100%',
      paddingLeft: 0
    }
  },
  addService: {
    textDecoration: 'none',
    color: '#9F0000'
  },
  addIcon: {
    position: 'absolute'
  },
  addServiceText: {
    marginLeft: 30,
  },
  descriptionText: {
    color: '#a7a7a7',
    fontSize: 14,
    whiteSpace: 'pre-wrap'
  },
  serviceLink: {
    color: '#9F0000'
  },
  fieldsContainer: {
    marginTop: 20,
    width: 110,
    ['@media (max-width:959px)']: { 
      width: '100%',
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  timerContainer: {
    marginTop: 20,
    width: 110,
    ['@media (max-width:959px)']: { 
      width: '100%',
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  inputContainer: {
    width: '100%',
    height: 65
  },
  input: {
    width: '100%',
    fontSize: 16
  },
  saveContainer: {
    marginLeft: 65,
    marginTop: 10
  },
  saveText: {
      color: '#9F0000',
      fontSize: 16,
      fontWeight: 500,
      display: 'inline-block',
      position: 'absolute',
      marginTop: 7,
      marginLeft: -62,
      width: 44
  },
  saveBtnContainer: {
    width: 36,
    height: 36,
    borderRadius: 12,
    backgroundColor: '#9F0000',
    display: 'inline-block',
    "&:hover": {
      cursor: 'pointer'
    },
  },
  saveBtn: {
    width: 21,
    height: 21,
    paddingLeft: 3,
    position: 'absolute',
    marginLeft: 7,
    marginTop: 6
  },
  updateInfo: {
    display: 'flex',
    position: 'absolute',
    right: 200,
    ['@media (max-width:959px)']: { 
      position: 'initial',
      width: '90%',
      display: 'block',
      right: 'initial',
      marginLeft: -10
    }
  },
  flexContainer: {
    display: 'flex',
    ['@media (max-width:959px)']: { 
      width: '100%',
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  flexWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    ['@media (max-width:959px)']: { 
      width: '100%',
      display: 'block'
    }
  },
  save1Container: {
    marginLeft: 65,
    marginTop: 10
  },
  formControl: {
    width: 250,
    "&:focus": {
      boxShadow: "none",
      outline: "none"
    },
    "&:active": {
      boxShadow: "none",
      outline: "none"
    }
  },
  applyContainer: {
    display: 'inline-block',
    marginTop: 0,
    marginLeft: 80
  },
  ratingsContainer: {
    display: 'flex',
    flexDirection: 'row',
    margin: '0 auto',
    justifyContent: 'flex-start',
    ['@media (max-width:768px)']: { 
      justifyContent: 'flex-start'
    }
  },
  tabContainer: {
    marginTop: 40,
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  buildingInput: {
    marginRight: 15
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 15,
    marginBottom: 20,
    width: '100%',
    ['@media (max-width:959px)']: { 
        width: '100%',
    }
  },
  input: {
    width: 280,
    fontSize: 16,
    marginRight: 15,
    ['@media (max-width:959px)']: { 
      width: '100%',
      marginBottom: 15,
    }
  },
  filterDiv: {
    marginTop: 10,
    float: 'left',
    backgroundColor: '#9F0000',
    color: '#fff',
    borderRadius: 10,
    marginRight: 15,
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 6,
    height: 34,
    "&:hover": {
        cursor: 'pointer'
    }
},
  resetDiv: {
    marginTop: 10,
    float: 'left',
    color: '#9F0000',
    borderColor: '#9F0000',
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 10,
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 6,
    height: 34,
    "&:hover": {
        cursor: 'pointer'
    }
  },
}));

const PromoCodes = () => {
  const classes = useStyles();
  const userId = localStorage.getItem('userId');
  const roleId = localStorage.getItem('roleId');
  const history = useHistory();
  let [language, setLanguage] = useState('');
  let [searchKeyword, setSearchKeyword] = useState('');
  let [columns, setColumns] = useState([]);
  let [promoCodes, setPromoCodes] = useState([]);
  let [allPromoCodes, setAllPromoCodes] = useState([]);
  let [managerProperties, setManagerProperties] = useState([]);
  let [buildings, setBuildings] = useState(null);
  let [selectedBuilding, setSelectedBuilding] = useState(null);
  let [selectedBuildingObject, setSelectedBuildingObject] = useState(null);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const { t, i18n } = useTranslation();

  const CustomTitle = ({ row }) => (
    <div>
        {}
        <div>
            <div
                data-tag="allowRowEvents"
                style={{ color: 'grey', overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
            >
                {}
                <a className={classes.serviceLink} href={`/promo-code/${row.id}`}>
                  {row.promo_code}
                </a>
            </div>
        </div>
    </div>
  );

  useEffect(() => {
    const language = localStorage.getItem('language');
    if(language) {
      i18n.changeLanguage(language);
    }
    setLanguage(language);

    getPromoCodes();
    let table_columns = [
      {
        name: t('promo-codes.promocode'),
        selector: row => row.promo_code,
        sortable: true,
        cell: row => <CustomTitle row={row} />,
      },
      {
        name: t('promo-codes.discount'),
        selector: row => row.percentage > 0 ? (row.percentage + ' %') : (row.amount > 0 ? ('BHD ' + row.amount) : ''),
        sortable: true,
      },
      {
        name: t('promo-codes.valid'),
        selector: row => (row.valid_from + ' - ' + row.valid_to),
        sortable: true,
      },
      {
        name: t('services.limitpercustomer'),
        selector: row => row.limit_per_user,
        sortable: true,
      },
      {
        name: t('promo-codes.nooftimesused'),
        selector: row => row.no_used || 0,
        sortable: true,
      },
    ];
    setColumns(table_columns);
  }, [])
  
  if (!userId) {
    return <Redirect to={'/login'} />;
  }

  const getPromoCodes = () => {
    axios.get(API_URL + `/v2/promo-codes`)
    .then(response => {
      let all_promo_codes = response.data.promoCodes;

      axios.get(API_URL + `/properties`)
        .then(resp => {
          let all_bldgs = resp.data.properties;

          if(roleId == 5) {
            axios.get(API_URL + `/manager-properties`)
            .then(resp => {
              let manager_properties = resp.data.manager_properties;
              setManagerProperties(manager_properties)
              let filtered_bldgs = []
              let filtered_promo_codes = []
              if(all_promo_codes?.length > 0) {
                filtered_promo_codes = all_promo_codes.filter(mp => {
                  return manager_properties?.some(a => a.property_id == mp.id && a.user_id == userId)
                })
              } else {
                filtered_promo_codes = all_promo_codes
              }
              if(all_bldgs?.length > 0) {
                filtered_bldgs = all_bldgs.filter(mp => {
                  return manager_properties?.some(a => a.property_id == mp.id && a.user_id == userId)
                })
              } else {
                filtered_bldgs = all_bldgs
              }
              formatSelectData(filtered_bldgs, 'properties');
              setPromoCodes(filtered_promo_codes)
              setAllPromoCodes(filtered_promo_codes)
            })
          } else {
            formatSelectData(all_bldgs, 'properties');
            setPromoCodes(all_promo_codes)
            setAllPromoCodes(all_promo_codes)
          }
        })
    })
  }

  const formatSelectData = (array, mode) => {
    let new_array = []
    if(array.length > 0) {
        array.forEach(item => {
            new_array.push({
                value: item.id,
                label: language == 'ar' ? ((item.name_ar) ? (item.name_ar) : (item.name)) : (item.name)
            })
        })
    }

    if(mode == 'properties') { setBuildings(new_array); }
    return new_array;
  }

  const handleFieldChange = (event, type) => {
    switch(type) {
      case 'searchKeyword': 
        setSearchKeyword(event.target.value)
        handleFilter(event.target.value)
        break;
    }
  };

  function handleFilter(search) {
    // let svcs = allServices

    // if(svcs.length > 0 && search.length > 0) {
    //   svcs = svcs.filter(s => {
    //     let include = false

    //     if(s.name && search && s.name.toLowerCase().indexOf(search.toLowerCase()) >= 0) {
    //       include = true
    //     }

    //     return include
    //   })
    // }

    // let new_svcs = [...svcs].sort((a, b) => a.order - b.order || a.package_subscription_type_id - b.package_subscription_type_id);
    // localStorage.setItem('packages', JSON.stringify(new_svcs))
    // new_svcs = new_svcs?.map((p, index) => {
    //   return {
    //     ...p,
    //     index: index,
    //   };
    // });
    // localStorage.setItem('packages', JSON.stringify(new_svcs))
    // updateServices(new_svcs)
  }

  const handleBuildingChange = (data) => {
    setSelectedBuildingObject(data);
    setSelectedBuilding(data);
  };

  return (
    <div className="inner">
      <LoggedInTopMenu />
      <div className={classes.content}>
        <Grid
          justify="space-between"
          container
        >
          <Hidden smDown>
            <div className={classes.sideMenu}>
              <SideMenu />
            </div>
          </Hidden>
          <div id="services-maincontent" className={classes.mainContent}>
            <div className={classes.article}>
                <ToastContainer />
                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <a key="1" href="#" className={classes.activePageHeader}>{t('side.promocodes')}</a>  
                    <a className={classes.addService} href={'/promo-code/0'}>
                      <AddIcon className={classes.addIcon} />
                      <div className={classes.addServiceText}>{t('promo-codes.addapromocode')}</div>
                    </a>
                </div>
                <div className={classes.filterContainer}>
                  <div>
                    <TextField 
                      id="searchKeyword"
                      label={t('dashboard.search')} 
                      className={classes.input}
                      placeholder={t('dashboard.search')}
                      value={searchKeyword || ''}
                      onChange={(event) => handleFieldChange(event, 'searchKeyword')}
                    />
                  </div>
                  <div style={{marginTop:8}}>
                    <Select
                      placeholder={t('users.selectbuilding')}
                      value={selectedBuildingObject}
                      onChange={handleBuildingChange}
                      options={buildings}
                      name="building"
                      required
                      className={classes.input}
                      isMulti
                    />
                  </div>
                </div>
                <div style={{marginTop:20}}>
                  <DataTable
                    columns={columns}
                    data={promoCodes}
                    pagination
                    paginationResetDefaultPage={resetPaginationToggle}
                    persistTableHead
                    fixedHeader
                  />
                </div>
            </div>
          </div>
        </Grid>
      </div>
    </div>
  );
};
  
export default PromoCodes;
import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useHistory, useParams } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { Grid } from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';
import { API_URL } from '../constants';
import { useTranslation } from 'react-i18next';
import LoggedInTopMenu from '../components/LoggedInTopMenu';
import SideMenu from '../components/SideMenu';
import Hidden from '@material-ui/core/Hidden';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import CloseIcon from '@material-ui/icons/Close';
import Select from 'react-select';
import NativeSelect from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import arrowrightwhiteimage from '../images/arrow-right-white.png'; 
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    content: {
      paddingTop: 130,
      marginLeft: '-6%'
    },
    sideMenu: {
      backgroundColor: '#9F0000',
      width: 256,
      height: `calc(100vh - 130px)`,
      borderTopRightRadius: 75,
      color: '#fff',
      paddingTop: 70
    },
    mainContent: {
      width: `calc(100% - 256px)`,
      textAlign: 'left',
      ['@media (max-width:959px)']: { 
        width: '95%'
      }
    },
    article: {
      paddingTop: 20,
      paddingLeft: 50,
      ['@media (max-width:959px)']: { 
        paddingLeft: 0
      }
    },
    center: {
        position: 'absolute',
        top: '50%',
        left: '55%',
        width: 580,
        transform: 'translateX(-50%) translateY(-50%)',
        ['@media (max-width:959px)']: { 
            top: '50%',
            left: '0%',
            position: 'initial',
            transform: 'none',
            width: '100%'
        }
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        ['@media (max-width:959px)']: { 
            width: '90%',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
    },
    close: {
        fontSize: 24,
        "&:hover": {
            cursor: "pointer"
        }
    },
    fieldsContainer: {
        paddingTop: 35,
        width: 580,
        marginLeft: 'auto',
        marginRight: 'auto',
        ['@media (max-width:959px)']: { 
          width: '90%'
        }
    },
    inputContainer: {
        width: '100%',
        height: 72
    },
    inputContainerDesc: {
        width: '100%',
        marginTop: 15,
    },
    inputFlexContainer: {
        height: 72,
        display: 'flex',
        justifyContent: 'space-between',
        ['@media (max-width:959px)']: { 
            width: '100%',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    },
    input: {
        width: '100%',
        fontSize: 16
    },
    flexLastInput: {
        width: '100%',
        fontSize: 16,
        marginLeft: 20
    },
    saveContainer: {
        paddingTop: 16,
        float: 'right',
        marginTop: 20,
        ['@media (max-width:959px)']: { 
            marginRight: '3%',
            marginTop: '20%'
        }
    },
    saveText: {
        color: '#9F0000',
        fontSize: 16,
        fontWeight: 500,
        display: 'inline-block',
        position: 'absolute',
        marginTop: 7,
        marginLeft: -45,
        width: 44
    },
    saveBtnContainer: {
        width: 36,
        height: 36,
        borderRadius: 12,
        backgroundColor: '#9F0000',
        display: 'inline-block',
        "&:hover": {
            cursor: 'pointer'
        },
    },
    saveBtn: {
        width: 21,
        height: 21,
        position: 'absolute',
        marginTop: 6,
        marginLeft: 8
    },
    flexInput: {
        fontSize: 16,
        width: '25%',
        paddingRight: 20
    },
    flexInputLast: {
        fontSize: 16,
        width: '25%'
    },
    selectpromoCode: {
        width: '100%',
        fontFamily: 'Nunito',
        ['@media (max-width:959px)']: { 
            width: 310,
            marginBottom: 20
        }
    },
    promoCodeLabel: {
        paddingBottom: 20,
        fontSize: 16,
        fontFamily: 'Nunito',
        ['@media (max-width:959px)']: { 
            paddingTop: 65
        }
    },
    flexContainer: {
        display: 'flex',
        ['@media (max-width:959px)']: { 
            display: 'block'
        }
    },
    halfDiv: {
        width: '50%',
        ['@media (max-width:959px)']: { 
            width: '100%'
        }
    },
    deleteContainer: {
        marginTop: 45,
        float: 'left',
        color: '#9F0000',
        "&:hover": {
            cursor: 'pointer'
        },
        ['@media (max-width:959px)']: { 
            marginTop: '20%',
            marginLeft: 15
        }
    },
}));

const ManagePromoCode = () => {
    const classes = useStyles();
    const userId = localStorage.getItem('userId');
    const roleId = localStorage.getItem('roleId');
    const history = useHistory();
    const params = useParams();
    const { t, i18n } = useTranslation();
    const [days, setDays] = useState('');
    const [hours, setHours] = useState('');
    const [minutes, setMinutes] = useState('');
    const [hoursTo, setHoursTo] = useState('');
    const [minutesTo, setMinutesTo] = useState('');
    let [language, setLanguage] = useState('');
    let [mode, setMode] = useState('');
    let [name, setName] = useState('');
    let [nameAr, setNameAr] = useState('');
    let [description, setDescription] = useState('');
    let [descriptionAr, setDescriptionAr] = useState('');
    let [percentage, setPercentage] = useState(null);
    let [amount, setAmount] = useState(null);
    let [limit, setLimit] = useState('');
    let [validFrom, setValidFrom] = useState(new Date());
    let [validTo, setValidTo] = useState(new Date());
    let [promoCode, setPromoCode] = useState(null);
    let [promoCodeDetails, setPromoCodeDetails] = useState(null);
    let [buildings, setBuildings] = useState(null);
    let [selectedBuilding, setSelectedBuilding] = useState(null);
    let [selectedBuildingObject, setSelectedBuildingObject] = useState(null);
    let [packages, setPackages] = useState(null)
    let [allPackages, setAllPackages] = useState(null)
    let [selectedPackage, setSelectedPackage] = useState(null)
    let [selectedPackageObject, setSelectedPackageObject] = useState(null)
    let [formSubmitted, setFormSubmitted] = useState(false);

    useEffect(() => {
        const language = localStorage.getItem('language');
        if(language) {
            i18n.changeLanguage(language);
        }
        setLanguage(language);

        initializeData();
        setMode(params.id > 0 ? 'edit' : 'add');
    }, [])

    if (!userId) {
        return <Redirect to={'/'} />;
    } 

    const initializeData = () => {
        setValidFrom(moment(new Date()).format("YYYY-MM-DD"));
        setValidTo(moment(new Date()).add(7, 'days').format("YYYY-MM-DD"));

        axios.get(API_URL + `/properties`)
            .then(resp => {
                let all_bldgs = resp.data.properties;
                if(roleId == 5) {
                    axios.get(API_URL + `/manager-properties`)
                    .then(resp => {
                        let manager_properties = resp.data.manager_properties;
                        let filtered_bldgs = []
                        if(all_bldgs?.length > 0) {
                            filtered_bldgs = all_bldgs.filter(mp => {
                                return manager_properties?.some(a => a.property_id == mp.id && a.user_id == userId)
                            })
                        } else {
                            filtered_bldgs = all_bldgs
                        }
                        continueLoad(filtered_bldgs)
                    })
                } else {
                    continueLoad(all_bldgs)
                }
            })
    }

    const continueLoad = (all_bldgs) => {
        let bldng = formatSelectData(all_bldgs, 'properties');
        axios.get(API_URL + `/packages`)
        .then(rsp => {
            let pst = rsp.data.packages;
            setPackages(pst)
            setAllPackages(pst)
            pst = formatSelectData(pst, 'packages');

            if(params.id > 0) {
                axios.get(API_URL + `/v2/promo-code-details/${params.id}`)
                .then(response2 => {
                    let pcd = response2.data.promoCode;
                    if(pcd) {
                        setPromoCodeDetails(pcd);
                        setPromoCode(pcd.promo_code);
                        setDescription(pcd.description);
                        setDescriptionAr(pcd.description_ar);
                        setPercentage(pcd.percentage);
                        setAmount(pcd.amount);
                        setValidFrom(pcd.valid_from);
                        setValidTo(pcd.valid_to);
                        if(pcd.package_id) { 
                            setSelectedPackage(pcd.package_id);
                            setSelectedPackageObject(valueFromId(pst, pcd.package_id)); 
                        }
                        if(pcd.promo_code_properties) {
                            let properties = formatSelectData(pcd.promo_code_properties, 'promo_code_properties');
                            setSelectedBuildingObject(properties);
                        }
                        if(pcd.limit_per_user > 0) {
                            setLimit(pcd.limit_per_user);
                        }
                    }
                })
            }
        })
    }

    const valueFromId = (opts, id) => opts.find(o => o.value === id);

    const formatSelectData = (array, mode) => {
        let new_array = []
        if(array.length > 0) {
            array.forEach(item => {
                new_array.push({
                    value: item.id,
                    label: language == 'ar' ? ((item.name_ar) ? (item.name_ar) : (item.name)) : (item.name)
                })
            })
        }

        if(mode == 'properties') { setBuildings(new_array); }
        if(mode == 'packages') { setPackages(new_array); }
        return new_array;
    }

    const handleFieldChange = (event, type) => {
        switch(type) {
            case 'promoCode': 
                setPromoCode(event.target.value);
                break;
            case 'description': 
                setDescription(event.target.value);
                break;
            case 'descriptionAr': 
                setDescriptionAr(event.target.value);
                break;
            case 'percentage': 
                setPercentage(event.target.value);
                break;
            case 'amount': 
                setAmount(event.target.value);
                break;
            case 'validFrom': 
                setValidFrom(event.target.value);
                break;
            case 'validTo': 
                setValidTo(event.target.value);
                break;
            case 'limit': 
                setLimit(event.target.value);
                break;
        }
    };

    const handleBuildingChange = (data) => {
        setSelectedBuildingObject(data);
        setSelectedBuilding(data);
    };

    const handlePackageChange = (data) => {
        setSelectedPackageObject(data);
        setSelectedPackage(data);
    }

    function handleClick(e) {
        setFormSubmitted(true);
        if(!promoCode) {
            showError(t('promo-codes.fillup'))
        } else {
            if(percentage || amount) {
                if(mode == 'add') { 
                    addPromoCode(); 
                } else {
                    editPromoCode();
                }
            } else {
                showError(t('promo-codes.fillup'))
            }
        }
    }

    function addPromoCode() {
        let params = {
            promo_code: promoCode,
            package_id: selectedPackage?.value || null,
            description,
            description_ar: descriptionAr,
            percentage: percentage || null,
            amount: amount || null,
            valid_from: validFrom,
            valid_to: validTo,
            property_id: selectedBuilding,
            limit_per_user: limit || 0,
        }

        axios.post(API_URL + `/v2/add-promo-code`, params)
        .then(res => {
            if(res.status == 200) {
                localStorage.removeItem('filename')
                toast.success(t("users.successadd"), {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setTimeout(function () {
                    history.push("/promo-codes");
                }, 2000);
            }
        })
    }

    function editPromoCode() {
        let payload = {
            promo_code: promoCode,
            package_id: selectedPackage?.value || null,
            description,
            description_ar: descriptionAr,
            percentage: percentage || null,
            amount: amount || null,
            valid_from: validFrom,
            valid_to: validTo,
            property_id: selectedBuilding,
            limit_per_user: limit || 0,
        }

        axios.post(API_URL + `/v2/edit-promo-code/${params.id}`, payload)
        .then(res => {
            if(res.status == 200) {
                toast.success(t("users.successsave"), {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setTimeout(function () {
                    history.push("/promo-codes");
                }, 2000);
            }
        })
    }

    function showError(message) {
        toast.error(message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function handleClose(e) {
        history.push("/promo-codes")
    }

    function handleDelete(e) {
        if(params.id) {
            axios.post(API_URL + `/v2/delete-promo-code/${params.id}`, {})
            .then(res => {
                if(res.status == 200) {
                    toast.success(t("users.successdelete"), {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setTimeout(function () {
                        history.push("/promo-codes");
                    }, 2000);
                }
            })
        } else {
            toast.error(t('promo-codes.deleteerror'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    return (
        <div className="inner">
            <LoggedInTopMenu />
            <div className={classes.content}>
                <Grid
                    justify="space-between"
                    container
                >
                    <Hidden smDown>
                        <div className={classes.sideMenu}>
                            <SideMenu />
                        </div>
                    </Hidden>
                    <ToastContainer />
                    <div className={classes.mainContent}>
                        <div className={classes.article}>
                            <div className={classes.fieldsContainer}>
                                <div className={classes.header}>
                                    <div className={classes.title}>{mode == 'edit' ? t('promo-codes.editpromocode') : t('promo-codes.addapromocode')}</div>
                                    <div><CloseIcon className={classes.close} onClick={handleClose} /></div>
                                </div>
                                <div className={classes.fieldsContainer}>
                                    <form>
                                    <div className={classes.inputContainerDesc}>
                                            <TextField 
                                                id="description"
                                                label={t('promo-codes.promocode')}
                                                multiline
                                                className={classes.input}
                                                placeholder={t('promo-codes.promocode')}
                                                value={promoCode || ''}
                                                onChange={(event) => handleFieldChange(event, 'promoCode')}
                                            />
                                        </div>
                                        <div className={classes.inputContainerDesc}>
                                            <TextField 
                                                id="description"
                                                label={t('promo-codes.description')}
                                                multiline
                                                className={classes.input}
                                                placeholder={t('promo-codes.description')}
                                                value={description || ''}
                                                onChange={(event) => handleFieldChange(event, 'description')}
                                            />
                                        </div>
                                        <div className={classes.inputContainerDesc}>
                                            <TextField 
                                                id="description"
                                                label={t('promo-codes.descriptionar')}
                                                multiline
                                                className={classes.input}
                                                placeholder={t('promo-codes.descriptionar')}
                                                value={descriptionAr || ''}
                                                onChange={(event) => handleFieldChange(event, 'descriptionAr')}
                                            />
                                        </div>
                                        <div className={classes.inputContainerDesc}>
                                            <TextField 
                                                id="limit"
                                                label={t('services.limitpercustomer')} 
                                                type="number"
                                                className={classes.input}
                                                placeholder={t('services.limitpercustomer')}
                                                value={limit || ''}
                                                onChange={(event) => handleFieldChange(event, 'limit')}
                                            />
                                        </div>
                                        <div className={classes.flexContainer} style={{gap:25,marginTop:10,alignItems:'center'}}>
                                            <div className={classes.halfDiv}>
                                                <TextField 
                                                    id="percentage"
                                                    label={t('promo-codes.percentage')} 
                                                    className={classes.input}
                                                    placeholder={t('promo-codes.percentage')}
                                                    value={percentage || ''}
                                                    onChange={(event) => handleFieldChange(event, 'percentage')}
                                                />
                                            </div>
                                            <div className={classes.halfDiv}>
                                                <TextField 
                                                    id="amount"
                                                    label={t('promo-codes.amount')}
                                                    className={classes.input}
                                                    placeholder={t('promo-codes.amount')}
                                                    value={amount || ''}
                                                    onChange={(event) => handleFieldChange(event, 'amount')}
                                                />
                                            </div>
                                        </div>
                                        <div className={classes.flexContainer} style={{gap:25,marginTop:15,alignItems:'center'}}>
                                            <div className={classes.halfDiv}>
                                                <TextField 
                                                    id="validFrom"
                                                    type="date"
                                                    label={t('promo-codes.validfrom')} 
                                                    className={classes.input}
                                                    placeholder={t('promo-codes.validfrom')}
                                                    value={validFrom || ''}
                                                    onChange={(event) => handleFieldChange(event, 'validFrom')}
                                                />
                                            </div>
                                            <div className={classes.halfDiv}>
                                                <TextField 
                                                    id="validTo"
                                                    type="date"
                                                    label={t('promo-codes.validto')} 
                                                    className={classes.flexLastInput}
                                                    style={{marginLeft:4}}
                                                    placeholder={t('promo-codes.validto')}
                                                    value={validTo || ''}
                                                    onChange={(event) => handleFieldChange(event, 'validTo')}
                                                />
                                            </div>
                                        </div>
                                        <div className={classes.flexContainer} style={{gap:25,marginTop:20,alignItems:'center'}}>
                                            <div className={classes.halfDiv}>
                                                <Select
                                                    placeholder={t('services.selectservice')}
                                                    value={selectedPackageObject}
                                                    onChange={handlePackageChange}
                                                    options={packages}
                                                    name="package"
                                                />
                                            </div>
                                            <div className={classes.halfDiv}>
                                                <Select
                                                    placeholder={t('users.selectbuilding')}
                                                    value={selectedBuildingObject}
                                                    onChange={handleBuildingChange}
                                                    options={buildings}
                                                    isMulti={true}
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div>
                                    {mode == 'edit' &&
                                        <div 
                                            className={classes.deleteContainer}
                                            onClick={handleDelete}
                                        >
                                            {t('promo-codes.delete')}
                                        </div>
                                    }
                                    <div className={classes.saveContainer}>
                                        <div className={classes.saveText}>{t('promo-codes.save')}</div>
                                        <div 
                                            onClick={handleClick}
                                            className={classes.saveBtnContainer} 
                                        >
                                            <img 
                                                className={classes.saveBtn} 
                                                src={arrowrightwhiteimage} 
                                                alt="Carcare" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Grid>
            </div>
        </div>
    );
};
  
export default ManagePromoCode;
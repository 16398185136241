import React, { useRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { MenuItem } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { useLocation, useHistory } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/DriveEta';
import logoimage from '../images/logo-image-white.png'; 
import logoadminimage from '../images/logo-admin.png'; 
import logotext from '../images/logo-text-white.png';
import { useTranslation } from 'react-i18next';

const drawerWidth = 0;

const useStyles = makeStyles(theme => ({
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    backgroundColor: `rgba(159,0,0,1)`  
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontSize: 18,
    color: '#fff',
    textDecoration: 'none',
    marginTop: 6,
    position: 'absolute',
    right: 0,
    ['@media (max-width:959px)']: { 
      marginTop: 9
    },
    "&:hover": {
      cursor: "pointer",
      color: '#FFF !important'
    }
  },
  logoImage: {
    width: 40
  },
  logoAdminImage: {
    width: 80
  },
  logoText: {
    width: 104,
    position: 'absolute',
    top: 15,
    left: 43
  },
  toolbar: {
    paddingLeft: 0,
    paddingRight: 0,
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
}));

function OtherTopMenu() {
  const classes = useStyles();
  const history = useHistory();
  const { t, i18n } = useTranslation();

  useEffect(() => {
      const language = localStorage.getItem('language');
      if(language) {
          i18n.changeLanguage(language);
      }
  }, [])

  let location = useLocation();
  let isPartnerPage = false;
  if(location.pathname == '/partner-with-us') {
    isPartnerPage = true;
  }

  return (
    <AppBar position='fixed' className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Grid
          justify="space-between"
          container 
          spacing={24}
        >
          <Grid item>
            <a href="/">
              <img 
                  className={classes.logoAdminImage} 
                  src={logoadminimage} 
                  alt="Carcare" />
              {/* <img 
                  className={classes.logoText} 
                  src={logotext} 
                  alt="Carcare" /> */}
            </a>
          </Grid>
          {/* <Grid item>
            {!isPartnerPage &&
              <a href="/partner-with-us" className={classes.title}>
                <Typography variant='h6'>
                  {t('side.partnerwithus')}
                </Typography>
              </a>
            }
          </Grid> */}
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default OtherTopMenu;
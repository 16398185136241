import React from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { CSSTransition } from "react-transition-group";
import './App.scss';
import { withTranslation } from 'react-i18next';

import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import NewDashboard from "./pages/NewDashboard";
import NotFound from './pages/NotFound';
import Profile from './pages/Profile';
import ForgotPassword from './pages/ForgotPassword';
import Main from "./pages/Main";
import Buildings from "./pages/Buildings";
import ManageBuilding from './pages/ManageBuilding';
import Users from "./pages/Users";
import ManageUser from './pages/ManageUser';
import Packages from "./pages/Packages";
import ManagePackage from './pages/ManagePackage';
import PromoCodes from "./pages/PromoCodes";
import ManagePromoCode from './pages/ManagePromoCode';
import Bookings from "./pages/Bookings";
import ManageBooking from './pages/ManageBooking';
import RenewBooking from './pages/RenewBooking';
import EditAssignedStaff from './pages/EditAssignedStaff';
import EditBooking from './pages/EditBooking';
import EditBookingItem from './pages/EditBookingItem';
import EditBookingSchedules from './pages/EditBookingSchedules';
import Notifications from "./pages/Notifications";
import NotificationMessages from "./pages/NotificationMessages";
import ManageNotificationMessage from './pages/ManageNotificationMessage';
import TapResponse from './pages/TapResponse';
import TapResponseSandbox from './pages/TapResponseSandbox';
import Feedback from "./pages/Feedback";
import PauseBookingItem from './pages/PauseBookingItem';

const routes = [
  {
    path: "/", 
    name: "Login",
    Component: Login
  },
  {
    path: "/dashboard", 
    name: "NewDashboard",
    Component: NewDashboard
  },
  {
    path: "/profile", 
    name: "Profile",
    Component: Profile
  },
  {
    path: "/forgot-password", 
    name: "forgot-password",
    Component: ForgotPassword
  },
  {
    path: "/not-found", 
    name: "NotFound",
    Component: NotFound
  },
  {
    path: "/main", 
    name: "Main",
    Component: Main
  },
  {
    path: "/buildings", 
    name: "Buildings",
    Component: Buildings
  },
  {
    path: "/building/:id", 
    name: "ManageBuilding",
    Component: ManageBuilding
  },
  {
    path: "/users", 
    name: "Users",
    Component: Users
  },
  {
    path: "/user/:id", 
    name: "ManageUser",
    Component: ManageUser
  },
  {
    path: "/packages", 
    name: "Packages",
    Component: Packages
  },
  {
    path: "/package/:id/:type_id", 
    name: "ManagePackage",
    Component: ManagePackage
  },
  {
    path: "/promo-codes", 
    name: "PromoCodes",
    Component: PromoCodes
  },
  {
    path: "/promo-code/:id", 
    name: "ManagePromoCode",
    Component: ManagePromoCode
  },
  {
    path: "/bookings", 
    name: "Bookings",
    Component: Bookings
  },
  {
    path: "/booking/:id", 
    name: "ManageBooking",
    Component: ManageBooking
  },
  {
    path: "/renewbooking/:id", 
    name: "RenewBooking",
    Component: RenewBooking
  },
  {
    path: "/editbooking/:id/:itemid", 
    name: "EditBooking",
    Component: EditBooking
  },
  {
    path: "/editbookingitem/:id/:itemid", 
    name: "EditBookingItem",
    Component: EditBookingItem
  },
  {
    path: "/editbookingschedules/:id/:itemid", 
    name: "EditBookingSchedules",
    Component: EditBookingSchedules
  },
  {
    path: "/editassignedstaff/:id", 
    name: "EditAssignedStaff",
    Component: EditAssignedStaff
  },
  {
    path: "/notifications", 
    name: "Notifications",
    Component: Notifications
  },
  {
    path: "/notificationmessages", 
    name: "NotificationMessages",
    Component: NotificationMessages
  },
  {
    path: "/notificationmessage/:id", 
    name: "ManageNotificationMessage",
    Component: ManageNotificationMessage
  },
  {
    path: "/tap-response", 
    name: "TapResponse",
    Component: TapResponse
  },
  {
    path: "/tap-responsesandbox", 
    name: "TapResponseSandbox",
    Component: TapResponseSandbox
  },
  {
    path: "/ratings", 
    name: "Feedback",
    Component: Feedback
  },
  {
    path: "/pause/:id/:itemid", 
    name: "PauseBookingItem",
    Component: PauseBookingItem
  },
]

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Nunito',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif'
    ].join(','),
  }
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Router>
          <Switch>
            {routes.map(({ path, Component }) => (
              <Route key={path} exact path={path}>
                {({ match }) => (
                  <CSSTransition
                    in={match != null}
                    timeout={1200}
                    classNames='page'
                    unmountOnExit>
                    <div className='page'>
                      <Component />
                    </div>
                  </CSSTransition>
                )}
              </Route>
            ))}
            <Route component={NotFound} />
          </Switch>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default withTranslation()(App);
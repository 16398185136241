import React, { useRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import { API_URL } from '../constants';
import { ToastContainer, toast } from 'react-toastify';
import Title from "../components/Title";
import OtherTopMenu from '../components/OtherTopMenu';
import TextField from '@material-ui/core/TextField';
import arrowrightwhiteimage from '../images/arrow-right-white.png'; 
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    fieldsContainer: {
        paddingTop: 35,
        width: 580,
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingLeft: 48,
        paddingRight: 48,
        ['@media (max-width:768px)']: { 
          width: '100%',
          paddingLeft: 0,
          paddingRight: 0
        }
    },
    inputContainer: {
        width: '100%',
        height: 72
    },
    input: {
        width: '100%',
        fontSize: 16
    },
    forgotPasswordContainer: {
        textAlign: 'left',
        marginTop: 30
    },
    forgotPassword: {
        color: '#a7a7a7',
        fontSize: 16
    },
    loginContainer: {
        paddingTop: 60,
        float: 'right'
    },
    loginText: {
        color: '#9F0000',
        fontSize: 16,
        fontWeight: 500,
        display: 'inline-block',
        position: 'absolute',
        marginTop: 7,
        marginLeft: -55,
        width: 44
    },
    loginBtnContainer: {
        width: 36,
        height: 36,
        borderRadius: 12,
        backgroundColor: '#9F0000',
        display: 'inline-block',
        "&:hover": {
            cursor: 'pointer'
        },
    },
    loginBtn: {
        width: 21,
        height: 21,
        paddingLeft: 3,
        position: 'absolute',
        marginLeft: -11,
        marginTop: 6
    },
    errorMessage: {
        color: '#fff',
        fontSize: 14,
        marginTop: 9,
        backgroundColor: '#cc0000',
        padding: 10,
        textAlign: 'center',
        fontWeight:'bold'
    }
}));

const Login = () => {
    const classes = useStyles();
    const emailRef = useRef('');
    const passwordRef = useRef('');
    const history = useHistory();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const language = localStorage.getItem('language');
        if(language) {
            i18n.changeLanguage(language);
        }
    }, [])

    function handleClick(e) {
        e.preventDefault();
    
        let email = emailRef.current.value;
        let password = passwordRef.current.value;

        if(email && password) {
            axios.post(API_URL + `/v2-user/web-login`, { 
                email: email,
                password: password
            })
            .then(res => {
                if(res.data) {
                    if(res.data.user) {
                        if(res.data.user.role_id == 2) {
                            localStorage.setItem('id', res.data.user.id);
                            localStorage.setItem('roleId', res.data.user.role_id);
                            localStorage.setItem('image', res.data.user.image);
                            localStorage.setItem('email', res.data.user.email);
                            localStorage.setItem('name', res.data.user.first_name + ' ' + res.data.user.last_name);
                            history.push("/dashboard");
                        } else if(res.data.user.role_id == 3 || res.data.user.role_id == 5) {
                            localStorage.setItem('userId', res.data.user.id);
                            localStorage.setItem('roleId', res.data.user.role_id);
                            localStorage.setItem('image', res.data.user.image);
                            localStorage.setItem('email', res.data.user.email);
                            localStorage.setItem('name', res.data.user.first_name + ' ' + res.data.user.last_name);
                            history.push("/main");
                        }
                    } else {
                        if(res.data.message == 'Access denied') {
                            toast.error(t('login.accessdenied'), {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        } else if(res.data.message == 'User does not exist') {
                            toast.error(t('login.incorrectemail'), {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }
                    }
                } else {
                    toast.error(t('login.incorrectemail'), {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
        }  else {
            toast.error(t('login.pleaseenteryouremailandpassword'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        
    }

    return (
      <div className='inner'>
            <OtherTopMenu />
            <Title lineContent={t('login.login')} lineContent2={t('login.nicetoseeyouagain')} />
            <div className={classes.fieldsContainer}>
                <ToastContainer />
                <form>
                    <div className={classes.inputContainer}>
                        <TextField 
                            id="email"
                            label={t('login.email')}
                            className={classes.input}
                            placeholder={t('login.email')}
                            type="email"
                            inputRef={emailRef}
                        />
                    </div>
                </form>
                <div className={classes.inputContainer}>
                    <TextField 
                        id="password" 
                        label={t('login.password')} 
                        type="password"
                        className={classes.input}
                        placeholder={t('login.password')}
                        inputRef={passwordRef}
                    />
                    <div className={classes.forgotPasswordContainer}>
                        <a href="/forgot-password" className={classes.forgotPassword}>{t('login.forgotpassword')}</a>
                    </div>
                    <div className={classes.loginContainer}>
                        <div className={classes.loginText}>{t('login.login')}</div>
                        <div 
                            onClick={handleClick}
                            className={classes.loginBtnContainer} 
                        >
                            <img 
                                className={classes.loginBtn} 
                                src={arrowrightwhiteimage} 
                                alt="Carcare" />
                        </div>
                    </div>
                </div>
            </div>
      </div>
    );
};
  
export default Login;
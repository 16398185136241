import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from "react-router-dom";
import { Grid } from '@material-ui/core';
import { API_URL } from '../constants';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import LoggedInTopMenu from '../components/LoggedInTopMenu';
import SideMenu from '../components/SideMenu';
import Hidden from '@material-ui/core/Hidden';
import axios from 'axios';
import AddIcon from '@material-ui/icons/Add';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import TextField from '@material-ui/core/TextField';
import DataTable from 'react-data-table-component';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles(theme => ({
    content: {
      paddingTop: 130,
      marginLeft: '-6%'
    },
    sideMenu: {
      backgroundColor: '#9F0000',
      width: 256,
      height: `calc(100vh - 130px)`,
      borderTopRightRadius: 75,
      color: '#fff',
      paddingTop: 70
    },
    mainContent: {
      width: `calc(100% - 256px)`,
      textAlign: 'left',
      ['@media (max-width:959px)']: { 
        width: '92%',
        paddingLeft: 0,
        paddingRight: 0
      }
    },
    article: {
      paddingTop: 20,
      paddingLeft: 50
    },
    activePageHeader: {
        fontSize: 20,
        fontWeight: 'bold',
        paddingRight: 40,
        color: '#000',
        textDecoration: 'none',
    },
    history: {
        width: '100%',
        marginTop: 20,
    },
    header: {
        color: '#000',
        opacity: 0.25,
        fontSize: 16,
        textAlign: 'center'
    },
    headerLeft: {
        color: '#000',
        opacity: 0.25,
        fontSize: 16,
        textAlign: 'left'
    },
    left: {
        textAlign: 'left',
        height: 80,
        borderBottomWidth: 1,
        borderBottomColor: '#d3d3d3',
        borderBottomStyle: 'solid'
    },
    center: {
        textAlign: 'center',
        height: 80,
        borderBottomWidth: 1,
        borderBottomColor: '#d3d3d3',
        borderBottomStyle: 'solid',
        fontSize: 14
    },
    amount: {
        fontSize: 20,
        fontWeight: 'bold',
        textAlign: 'center',
        height: 80,
        borderBottomWidth: 1,
        borderBottomColor: '#d3d3d3',
        borderBottomStyle: 'solid'
    },
    complete: {
        fontSize: 12,
        color: '#48D1B5',
        border: 'solid 1px #48D1B5',
        fontWeight: 'bold',
        width: 80,
        margin: '0 auto',
        borderRadius: 10,
        ['@media (max-width:768px)']: { 
          width: 'initial',
          textAlign: 'center'
        }
    },
    name: {
        fontSize: 14,
        fontWeight: 'bold'
    },
    car: {
        fontSize: 14,
        fontWeight: 'bold',
        color: '#000',
        opacity: 0.25
    },
    customer: {
        width: 36,
        height: 36,
        borderRadius: 10,
        objectFit: 'cover',
        marginRight: 12
    },
    client: {
        display: 'flex',
        ['@media (max-width:768px)']: { 
          display: 'block'
        }
    },
    colAdd: {
        paddingLeft: '3%',
        height: 60,
        borderBottomWidth: 1,
        borderBottomColor: '#d3d3d3',
        borderBottomStyle: 'solid',
        ['@media (max-width:768px)']: { 
          width: '100%',
          paddingLeft: 0
        }
    },
    addService: {
        textDecoration: 'none',
        color: '#9F0000'
    },
    addIcon: {
        position: 'absolute'
    },
    addServiceText: {
        marginLeft: 30,
    },
    serviceLink: {
        color: '#9F0000'
    },
    filterContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 15,
        marginBottom: 20,
        width: '30%',
        justifyContent: 'space-between',
        ['@media (max-width:959px)']: { 
            width: '100%',
        }
    },
    input: {
        width: 280,
        fontSize: 16
    },
    progress: {
        fontSize: 12,
        color: '#f1a542',
        border: 'solid 1px #f1a542',
        fontWeight: 'bold',
        width: 115,
        margin: '0 auto',
        borderRadius: 10,
        "&:hover": {
            cursor: "pointer"
        },
        ['@media (max-width:768px)']: { 
          width: 'initial',
          textAlign: 'center'
        }
    },
    icon: {
        "&:hover": {
            cursor: "pointer"
        },
    },
}));

const Users = () => {
    const classes = useStyles();
    const userId = localStorage.getItem('userId');
    const roleId = localStorage.getItem('roleId');
    let [language, setLanguage] = useState('');
    let [users, setUsers] = useState([]);
    let [roles, setRoles] = useState([]);
    let [allUsers, setAllUsers] = useState([]);
    let [searchKeyword, setSearchKeyword] = useState('');
    let [columns, setColumns] = useState([]);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const { t, i18n } = useTranslation();

    const CustomID = ({ row }) => (
        <div>
            {}
            <div>
                <div
                    data-tag="allowRowEvents"
                    style={{ color: 'grey', overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
                >
                    {}
                    <a className={classes.serviceLink} href={`/user/${row.id}`}>{row.id}</a>
                </div>
            </div>
        </div>
    );

    const CustomTitle = ({ row }) => (
        <div>
            {}
            <div>
                <div
                    data-tag="allowRowEvents"
                    style={{ color: 'grey', overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
                >
                    {}
                    <a className={classes.serviceLink} href={`/user/${row.id}`}>{row.first_name}</a>
                </div>
            </div>
        </div>
    );

    const CustomStatus = ({ row }) => (
        <div>
            {}
            <div>
                <div
                    data-tag="allowRowEvents"
                    style={{ color: 'grey', overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
                >
                    {}
                    <FiberManualRecordIcon 
                        className={classes.icon}
                        style={{
                            color: row.onesignal_player_id ? '#5cb85c' : '#c7c7c7',
                            fontSize: 24
                        }}
                    />
                </div>
            </div>
        </div>
    );
    
    useEffect(() => {
        const language = localStorage.getItem('language');
        if(language) {
            i18n.changeLanguage(language);
        }
        setLanguage(language);

        getRoles()

        let table_columns = [
            {
                name: 'ID',
                selector: row => row.id,
                sortable: true,
                cell: row => <CustomID row={row} />,
            },
            {
                name: t('users.firstname'),
                selector: row => row.first_name,
                sortable: true,
                cell: row => <CustomTitle row={row} />,
            },
            {
                name: t('users.lastname'),
                selector: row => row.last_name,
                sortable: true,
            },
            {
                name: t('users.email'),
                selector: row => row.email,
                sortable: true,
            },
            {
                name: t('landing.phonenumber'),
                selector: row => row.phone_number,
                sortable: true,
            },
            {
                name: t('history.status'),
                selector: row => row.onesignal_player_id ? 1 : 0,
                sortable: true,
                cell: row => <CustomStatus row={row} />,
            },
        ];
        setColumns(table_columns);
    }, [])

    if (!userId) {
        return <Redirect to={'/'} />;
    }

    const getRoles = () => {
        axios.get(API_URL + `/user-roles`)
        .then(response => {
            let roles = response.data.roles;
            setRoles(roles);
            getUsers();
        })
    }

    const getUsers = () => {
        axios.get(API_URL + `/v2-users`)
        .then(response => {
            let users = response.data.users;
            let all_users = users;
            setAllUsers(all_users);
            setUsers(users);
        })
    }

    const handleFieldChange = (event, type) => {
        switch(type) {
            case 'searchKeyword': 
                setSearchKeyword(event.target.value)
                handleFilter(event.target.value)
                break;
        }
    };

    function handleFilter(search) {
        let usrs = allUsers

        if(usrs.length > 0 && search.length > 0) {
            usrs = usrs.filter(s => {
                let include = false

                if(
                    (s.first_name && search &&
                        s.first_name.toLowerCase().indexOf(search.toLowerCase()) >= 0
                    ) || 
                    (s.last_name && search &&
                        s.last_name.toLowerCase().indexOf(search.toLowerCase()) >= 0
                    ) ||
                    (s.email && search &&
                        s.email.toLowerCase().indexOf(search.toLowerCase()) >= 0
                    ) 
                ) {
                    include = true
                }

                return include
            })
        }

        setUsers(usrs)
    }

    return (
        <div className="inner">
            <LoggedInTopMenu />
            <div className={classes.content}>
                <Grid
                    justify="space-between"
                    container
                >
                <Hidden smDown>
                    <div className={classes.sideMenu}>
                    <SideMenu />
                    </div>
                </Hidden>
                <div className={classes.mainContent}>
                    <div className={classes.article}>
                        <ToastContainer />
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <a key="1" href="#" className={classes.activePageHeader}>{t('users.users')}</a>
                            <a className={classes.addService} href={'/user/0'}>
                                <AddIcon className={classes.addIcon} />
                                <div className={classes.addServiceText}>{t('users.addauser')}</div>
                            </a>
                        </div>
                        <div className={classes.filterContainer}>
                            <div>
                                <TextField 
                                    id="searchKeyword"
                                    label={t('dashboard.search')} 
                                    className={classes.input}
                                    placeholder={t('dashboard.search')}
                                    value={searchKeyword || ''}
                                    onChange={(event) => handleFieldChange(event, 'searchKeyword')}
                                />
                            </div>
                        </div>
                        <div style={{marginTop:20}}>
                            <DataTable
                                columns={columns}
                                data={users}
                                pagination
                                paginationResetDefaultPage={resetPaginationToggle}
                                persistTableHead
                                fixedHeader
                            />
                        </div>
                    </div>
                </div>
                </Grid>
            </div>
        </div>
    );
};
  
export default Users;
import React, { useRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { API_URL } from '../constants';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import { Animated } from "react-animated-css";
import LoggedInTopMenu from '../components/LoggedInTopMenu';
import SideMenu from '../components/SideMenu';
import Hidden from '@material-ui/core/Hidden';
import axios from 'axios';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from "@fullcalendar/timegrid";
import CheckIcon from '@material-ui/icons/Check';
import interactionPlugin from "@fullcalendar/interaction"; 
import washimage from '../images/wash.png'; 
import calendarimage from '../images/calendar.png'; 
import pinimage from '../images/pin.png'; 
import washmodalimage from '../images/wash-modal.png'; 
import pinmodalimage from '../images/pin-modal.png'; 
import calendarmodalimage from '../images/calendar-modal.png'; 
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import Calendar from 'react-calendar-multiday';
import CancelIcon from '@material-ui/icons/Cancel';
import MapIcon from '@material-ui/icons/Map';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import EmailIcon from '@material-ui/icons/Email';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ReceiptIcon from '@material-ui/icons/Receipt';
import useSound from 'use-sound';
import soundFile from '../assets/alert.mp3';
import EventNoteIcon from '@material-ui/icons/EventNote';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import ShareIcon from '@material-ui/icons/Share';
import ContactsIcon from '@material-ui/icons/Contacts';
import starImage from '../images/star.png';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles(theme => ({
    ratingsContainer: {
      display: 'flex',
      flexDirection: 'row',
      margin: '0 auto',
      marginTop: 15,
      justifyContent: 'flex-start',
      ['@media (max-width:768px)']: { 
        justifyContent: 'flex-start'
      }
    },
    placeholderImage: {
      width: 160
    },
    toggleACWButton: {
      backgroundColor: '#30acdc',
      color: '#fff',
      borderColor: '#9f0000',
      borderWidth: 1
    },
    content: {
      paddingTop: 130,
      marginLeft: '-6%'
    },
    sideMenu: {
      backgroundColor: '#9F0000',
      width: 256,
      height: `calc(200vh - 130px)`,
      borderTopRightRadius: 75,
      color: '#fff',
      paddingTop: 70
    },
    mainContent: {
      width: `calc(100% - 256px)`,
      textAlign: 'left',
      ['@media (max-width:959px)']: { 
        width: '92%',
        paddingLeft: 0,
        paddingRight: 0
      }
    },
    article: {
      paddingTop: 20,
      paddingLeft: 50
    },
    pageHeader: {
      fontSize: 20,
      fontWeight: 'bold',
      paddingRight: 40,
      color: '#a7a7a7',
      textDecoration: 'none'
    },
    activePageHeader: {
      fontSize: 20,
      fontWeight: 'bold',
      paddingRight: 40,
      color: '#000',
      textDecoration: 'none',
      marginBottom: 15,
    },
    userContainer: {
      display: 'flex',
      marginBottom: 10
    },
    customer: {
      width: 36,
      height: 36,
      borderRadius: 12,
      objectFit: 'cover'
    },
    customerDetails: {
      position: 'absolute',
      marginLeft: 50,
      marginTop: 5,
      fontSize: 14,
      fontWeight: 'bold',
      wordBreak: 'break-word',
      width: 162
    },
    carContainer: {
      display: 'flex'
    },
    car: {
      width: 36,
      height: 36,
      borderRadius: 12,
      objectFit: 'cover'
    },
    carDetails: {
      position: 'absolute',
      marginLeft: 50,
      marginTop: 5,
      fontSize: 14,
      fontWeight: 'bold'
    },
    cardContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexWrap: 'wrap'
    },
    card: {
      backgroundColor: '#f7f7f7',
      borderRadius: 20,
      width: 290,
      marginRight: 10,
      marginBottom: 20
    },
    top: {
      borderBottomWidth: 1,
      borderBottomColor: '#d3d3d3',
      borderBottom: 'solid',
      padding: 20
    },
    bottom: {
      padding: 20
    },
    serviceContainer: {
      display: 'flex'
    },
    locationContainer: {
      display: 'flex'
    },
    calendarContainer: {
      display: 'flex'
    },
    progressContainer: {
      display: 'flex',
      position: 'absolute',
      float: 'right',
      marginLeft: 185,
      border: 'solid 1px #1d7846',
      paddingLeft: 10,
      paddingRight: 10,
      borderRadius: 8
    },
    wash: {
      backgroundColor: '#fff',
      width: 25,
      height: 25,
      borderRadius: 10
    },
    washImage: {
      width: 18,
      height: 18
    },
    wash: {
      backgroundColor: '#fff',
      width: 28,
      height: 28,
      borderRadius: 10,
      marginLeft: 4,
      marginRight: 4,
      marginBottom: 10
    },
    washImage: {
      width: 18,
      height: 18,
      margin: 5
    },
    pin: {
      backgroundColor: '#fff',
      width: 28,
      height: 28,
      borderRadius: 10,
      marginLeft: 4,
      marginRight: 4,
      marginBottom: 10
    },
    pinImage: {
      width: 17,
      height: 20,
      margin: '4px 5px'
    },
    calendar: {
      backgroundColor: '#fff',
      width: 28,
      height: 28,
      borderRadius: 10,
      marginLeft: 4,
      marginRight: 4,
      marginBottom: 10
    },
    calendarImage: {
      width: 18,
      height: 18,
      margin: 5
    },
    service: {
      fontSize: 14,
      marginTop: 5,
      marginLeft: 15,
      wordBreak: 'break-word'
    },
    address: {
      fontSize: 14,
      marginTop: 5,
      marginLeft: 15
    },
    next: {
      fontSize: 14,
      marginTop: 5,
      marginLeft: 15
    },
    progress: {
      fontSize: 14,
      marginTop: 2,
      color: '#1d7846'
    },
    checkIcon: {
      color: '#1d7846',
      fontSize: 16
    },
    check: {
      marginTop: 2,
      marginRight: 7
    },
    statusContainer: {
      textAlign: 'center',
      height: 36,
      backgroundColor: '#9F0000',
      borderRadius: 12,
      paddingTop: 6,
      marginTop: 10,
      "&:hover": {
          cursor: "pointer"
      }
    },
    status: {
      color: '#fff',
      textDecoration: 'none',
      fontSize: 14
    },
    customerNameLink: {
      color: '#000',
      textDecoration: 'none'
    },
    titleContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 7
    },
    dialogTitle: {
      fontSize: 20,
      fontWeight: 'bold'
    },
    close: {
      fontSize: 24,
      "&:hover": {
          cursor: "pointer"
      }
    },
    dialogContent: {
      width: 480,
      marginTop: 50,
      ['@media (max-width:959px)']: { 
        width: '100%'
      }
    },
    serviceInfoDate: {
      fontSize: 14,
      color: '#000',
      opacity: 0.25,
      marginTop: -10
    },
    dialogUserContainer: {
      display: 'flex',
      justifyContent: 'space-around',
      width: '70%',
      marginLeft: 'auto',
      marginRight: 'auto',
      ['@media (max-width:959px)']: { 
        width: '100%'
      }
    },
    dialogUserImage: {
      width: 80,
      height: 80,
      borderRadius: 18,
      objectFit: 'cover',
      marginBottom: 25
    },
    dialogCarImage: {
      width: 80,
      height: 80,
      borderRadius: 18,
      objectFit: 'cover',
      marginBottom: 25
    },
    dialogUser: {
      textAlign: 'center'
    },
    dialogCar: {
      textAlign: 'center'
    },
    dialogFlexContainer: {
      display: 'flex'
    },
    dialogRow: {
      marginTop: 60
    },
    modalIconImage: {
      width: 36,
      height: 36
    },
    dialogRightText: {
      marginLeft: 20,
      paddingTop: 6,
      marginLeft: 20,
      paddingTop: 6,
      width: '85%'
    },
    dialogFlexEndContainer: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    dialogRight: {
      position: 'absolute',
      right: 64,
      fontWeight: 'bold'
    },
    dialogRightLast: {
      position: 'absolute',
      right: 64,
      marginTop: 25,
      fontWeight: 'bold'
    },
    dialogBottomRow: {
      marginTop: 25
    },
    mapContainer: {
      height: 319, 
      width: '100%', 
      borderRadius: 40,
      marginTop: 20
    },
    editIcon: {
      marginRight: 10,
      color: '#9f0000',
      fontSize: 18,
      "&:hover": {
          cursor: "pointer"
      }
    },
    editButton: {
      marginTop: 20,
      width: 158,
      color: '#9f0000',
      backgroundColor: '#fff',
      borderRadius: 5,
      marginLeft: -15,
      "&:hover": {
        cursor: "pointer",
        backgroundColor: '#fff',
        color: '#9f0000'
      },
      ['@media (max-width:959px)']: { 
        width: '100%'
      }
    },
    cancelIcon: {
      marginRight: 10,
      color: '#fff',
      fontSize: 18,
      "&:hover": {
          cursor: "pointer"
      }
    },
    cancelButton: {
      marginTop: 20,
      color: '#fff',
      backgroundColor: '#9f0000',
      borderRadius: 5,
      "&:hover": {
        cursor: "pointer",
        backgroundColor: '#9f0000',
        color: '#fff'
      }
    },
    input: {
      width: '100%',
      fontSize: 16
    },
    input3rd: {
      width: '30%',
      fontSize: 16,
      ['@media (max-width:959px)']: { 
        width: '100%'
      }
    },
    saveContainer: {
      float: 'right',
      marginRight: 0,
      marginTop: 12,
      marginLeft: 58
    },
    saveText: {
      color: '#9F0000',
      fontSize: 16,
      fontWeight: 500,
      display: 'inline-block',
      position: 'absolute',
      marginTop: 7,
      marginLeft: -45,
      width: 44
    },
    saveBtnContainer: {
      width: 36,
      height: 36,
      borderRadius: 12,
      backgroundColor: '#9F0000',
      display: 'inline-block',
      marginLeft: 10,
      "&:hover": {
          cursor: 'pointer'
      },
    },
    saveBtn: {
      width: 21,
      height: 21,
      paddingLeft: 3,
      position: 'absolute',
      marginLeft: 7,
      marginTop: 6
    },
    buttonContainerFlex: {
      display: 'flex',
      justifyContent: 'space-between',
      ['@media (max-width:959px)']: { 
        justifyContent: 'center',
        flexWrap: 'wrap'
      }
    },
    timeslot: {
      marginTop: -35
    },
    mapIcon: {
      color: '#9F0000',
      marginBottom: -5,
      marginRight: 5,
      marginLeft: 5
    },
    whatsappIcon: {
      color: '#075e54',
      marginBottom: -5,
      marginRight: 5,
      marginLeft: 5
    },
    emailIcon: {
      color: '#9F0000',
      marginBottom: -5,
      marginRight: 5,
      marginLeft: 5
    },
    share: {
      "&:hover": {
          cursor: "pointer"
      }
    },
}));

const Dashboard = () => {
  const classes = useStyles();
  const id = localStorage.getItem('id');
  const roleId = localStorage.getItem('roleId');
  let [role, setRole] = useState('');
  let [dashboardevents, setDashboardevents] = useState('');
  let [events, setEvents] = useState('');
  let [eventCount, setEventCount] = useState('');
  let [selecteddayevents, setSelecteddayevents] = useState('');
  let [selectedday, setSelectedday] = useState('');
  let [servicedays, setServicedays] = useState('');
  let [servicephotos, setServicephotos] = useState('');
  let [center, setCenter] = useState('');
  let [zoom, setZoom] = useState('');
  let [google, setGoogle] = useState('');
  let [directions, setDirections] = useState('');
  let [drivetime, setDrivetime] = useState('');
  let [edit, setEdit] = useState(false);
  let [selectedevent, setSelectedevent] = useState([]);
  let [location, setLocation] = useState('');
  let [houseBuilding, setHouseBuilding] = useState('');
  let [road, setRoad] = useState('');
  let [block, setBlock] = useState('');
  let [timeslot, setTimeslot] = useState('');
  let [customerlocation, setCustomerlocation] = useState('');
  let [whatsapplink, setWhatsapplink] = useState('');
  let [mailto, setMailto] = useState('');
  let [washCenterHours, setWashCenterHours] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [openconfirm, setOpenconfirm] = React.useState(false);
  const [scroll, setScroll] = React.useState('body');
  const [calendarView, setCalendarView] = React.useState(true);
  const [cardView, setCardView] = React.useState(false);
  let [editselecteddate, setEditselecteddate] = useState([]);
  const { t, i18n } = useTranslation();
  const [play] = useSound(soundFile);
  const inputRef = React.useRef(null);
  let [searchKeyword, setSearchKeyword] = useState('');

  useEffect(() => {
    setRole(roleId);
    const language = localStorage.getItem('language');
    if(language) {
      i18n.changeLanguage(language);
    }

    loadData(id)
  }, [])

  function loadData(buildingId) {
    axios.get(API_URL + `/dashboard/schedules/web/${buildingId}/${roleId}`)
    .then(response => {
      let dbevts = response.data.schedules;

      let evts = [];
      setDashboardevents(dbevts);

      if(dbevts) {
        dbevts.forEach(e => {
          let title = t('dashboard.flat') + ' ' + e.flat_number;
          title = title + "," + e.quantity + "x " + e.car_type + "," + e.customer_first_name + " " + e.customer_last_name
          let dt = moment(e.dt).utc().format("YYYY-MM-DD HH:mm:ss").toString();
          let end_dt;
          if(e.end_dt) {
            let dt_end = e.end_dt.replace(" ", "T").replace(":00:00", ":00:00.000Z");
            end_dt = moment(dt_end).utc().format("YYYY-MM-DD HH:mm:ss").toString();
          }
          if(end_dt) {
            evts.push({
              title: title,
              date: e.date,
              id: e.random_id,
              start: dt,
              end: end_dt
            })
          } else {
            evts.push({
              title: title,
              date: e.date,
              id: e.random_id,
              start: dt
            })
          }
        });

        if(evts) {
          setEvents(evts);

          // Load today
          let timezoneOffset = new Date().getTimezoneOffset() * -1;
          let tz = moment(new Date()).utc().utcOffset(timezoneOffset);
          let today = moment(new Date()).utc().utcOffset(timezoneOffset).format("YYYY-MM-DD").toString();
          if(dbevts) {
            let db = dbevts.filter(e => {
              return e.date == today
            })
            setSelectedday('Today');
            setSelecteddayevents(db);
            setEventCount(db.length)
          }
        }
      }
    })
  }

  function handleDateClick(arg) {
    var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
    var n = new Date().getTimezoneOffset()
    let suffix_tz = n / 60 * -1
    let suffix_oper = ''
    let timezone = ''
    if(suffix_tz < 0) {
      suffix_oper = '-'
    } else {
      suffix_oper = '+'
    }
    if(suffix_tz < 10) {
      timezone = suffix_oper + '0' + suffix_tz + ':00'
    } else {
      timezone = suffix_oper + suffix_tz + ':00'
    }

    let selecteddatetime = arg.dateStr.replace("T", " ").replace("30:00", "00:00").substring(0, 19);
    let selected_date_time = arg.dateStr.replace("30:00", "00:00").substring(0, 19);
    if(isSafari) {
      selected_date_time += timezone;
    }
  }

  function handleEventClick(event) {
    let id = event.event.id;
    
    if(id == 0) {
      let selected_date_time = event.event.start.toString().replace("T", " ").substring(0, 19);
    } else {
      let timezoneOffset = new Date().getTimezoneOffset() * -1;
      let tz = moment(new Date()).utc().utcOffset(timezoneOffset);
      let today = moment(new Date()).utc().utcOffset(timezoneOffset).format("YYYY-MM-DD").toString();

      let date;
      let include = false;
      let db = dashboardevents.filter(e => {
        let dt = moment(e.dt).utc().format("YYYY-MM-DD HH:mm:ss").toString();
        let selecteddatetime = moment(event.event.start).format("YYYY-MM-DD HH:mm:ss").toString();
        if(e.random_id == id && dt == selecteddatetime) {
          date = e.date;
          include = true;
        } else {
          include = false;
        }
        
        return include;
      })
      if(today == date) {
        setSelectedday('Today');
      } else {
        setSelectedday(moment(date).format("MMM D"));
      }
      setSelecteddayevents(db[0]);
      setCardView(true);
      setCalendarView(false);
    }
  }

  function handleUpdateStatus(event) {
    
  }

  const handleClose = () => {
    setSelectedevent([]);
    setOpen(false);
  };

  const handleServiceDayClick = (ob) => {
    if(ob.current) {
      localStorage.setItem('selecteddate', ob.current);
      setEditselecteddate(ob.current);
    }
  }

  const handleClickOpen = (scrollType, event) => () => {
    selectEvent(scrollType, event);
  };

  const handleShareClick = (event) => () => {
    let message = event.dt.replace("T", " ").replace(":00.000Z", "") + '\r\n';
    message += event.outcall + '\r\n';
    if(event.plate_number) {
      message += event.plate_number + '\r\n';
    }
    message += event.payment + '\r\n';
    message += event.phone_number + '\r\n';
    message += (event.business_name) + ' ' + (event.address || event.location) + '\r\n';

    let additional_address = '';
    if(event.building) {
      additional_address += 'House/Building ' + event.building + ' ';
    }
    if(event.road) {
      additional_address += 'Road ' + event.road + ' ';
    }
    if(event.block) {
      additional_address += 'Block ' + event.block + ' ';
    }

    if(additional_address) {
      message += additional_address + '\r\n';
    }
    
    if(event.location) {
      if(event.location_link && event.location_link != 'null') {
        message += event.location_link + '\r\n'
      } else {
        if(event.location) {
          let address;
          if(event.location.indexOf("block") >= 0 && event.location.indexOf("building") >= 0) {
              let add = event.location.split(',');
              if(add) {
                  address = "block " + event.block + add[1];
              } else {
                  address = event.location;
              }
          } else {
              address = event.location;
          }
          let latitude = event.location_latitude;
          let longitude = event.location_longitude;
          let mapLink = `https://www.google.com.sa/maps/search/${encodeURI(address)}`;
          if(latitude && longitude) {
            mapLink = `http://maps.google.com/?q=${latitude},${longitude}`;
          }
          message += mapLink + '\r\n'
        }
      }
    }
    message += '\r\nBooked via CarCare App';

    let whatsappLink = 'https://api.whatsapp.com/send?text=' + window.encodeURIComponent(message);
    window.open(
      whatsappLink,
      '_blank'
    );
  };

  function selectEvent(scrollType, event) {
    if(event.id) {
      setSelectedevent(event);
      setEdit(false)

      let utc_time = '';
      let timezoneOffset = new Date().getTimezoneOffset();
      let tz = moment(event.start_time_slot).utc().utcOffset(timezoneOffset);

      let timeslot;
      if(event.start_time_slot) {
        timeslot = moment(event.start_time_slot).utc().format("h A");
        let end_timeslot = moment(event.end_time_slot).utc().format("h A");
        if(event.times > 0) {
          timeslot += '-' + end_timeslot
        }
        setTimeslot(timeslot);
      }

      setServicedays([event.date]);

      axios.get(API_URL + `/service/uploads/${event.id}`)
      .then(res => {
        setServicephotos(res.data.photos);
      })
        
      setOpen(true);
      setScroll(scrollType);

      let mapLink = `https://www.google.com.sa/maps/search/${encodeURI(event.address)}`;
      let whatsappLink = 'https://api.whatsapp.com/send?text=' + 'Customer Location: ' + event.address + ' ' + encodeURI(mapLink);
      let mailLink = `mailto:?Subject=Customer Location&body=` + 'Customer Location: ' + event.address + ' ' + encodeURI(mapLink);
      setCustomerlocation(mapLink);
      setWhatsapplink(whatsappLink);
      setMailto(mailLink);
    } else {
      setOpen(true);
      setScroll(scrollType);
    }
  }

  function onLoad() {
    if(window.google && window.google.maps) {
      setGoogle(window.google);
    }
  }

  function directionServiceLoad(directionsService) {
    directionsService.route(
      {
        origin: selectedevent.address,
        destination: selectedevent.location,
        travelMode: google.maps.TravelMode.DRIVING
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          setDirections(result);

          // Compute time
          var time = '';
          var myroute = result.routes[0];
          for (var i = 0; i < myroute.legs.length; i++) {
            time += myroute.legs[i].duration.text;
          }

          time = time.replace('hours','hr');
          time = time.replace('mins','min');

          let drive_time = '(' + time + ' drive)';
          drive_time.replace('(0', '(');
          setDrivetime(drive_time);

        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
  }

  function handleEdit() {
    if(selectedevent) {
      setLocation(selectedevent.location);
      setHouseBuilding(selectedevent.building);
      setRoad(selectedevent.road);
      setBlock(selectedevent.block);
    }
    setEdit(true);
  }

  const handleFieldChange = (event, type) => {
    switch(type) {
      case 'location': 
        setLocation(event.target.value);
        break;
      case 'houseBuilding': 
        setHouseBuilding(event.target.value);
        break;
      case 'road': 
        setRoad(event.target.value);
        break;
      case 'block': 
        setBlock(event.target.value);
        break;
      case 'searchKeyword': 
        setSearchKeyword(event.target.value);
        break;
    }
  };

  function handleCancelBooking() {
    if(selectedevent) {
      setOpenconfirm(true);
    }
  }

  function renderOtherDialog () {
    return (
      <Dialog
        open={openconfirm}
        onRequestClose={() => setOpenconfirm(false)}
        id="confirm-dialog"
      >
        <DialogTitle>{t('dashboard.areyousurecancelbooking')}</DialogTitle>
        <DialogActions id="confirm-buttons-container">
          <Button onClick={() => setOpenconfirm(false)} primary>{t('dashboard.cancel')}</Button>
          <Button onClick={cancelBooking} primary>{t('dashboard.yescancel')}</Button>
        </DialogActions>
      </Dialog>
    )
  }

  function cancelBooking() {
    axios.post(API_URL + `/subscription/cancel/${selectedevent.booking_item_id}`, {})
    .then(res => {
      window.location.reload(true);
    })
  }

  function clickNotification(requestId, requestDateId) {
    let event;
    if(selecteddayevents) {
      selecteddayevents.forEach(e => {
        if(e.id == requestId) {
          if(requestDateId) {
            if(e.request_date_id == requestDateId) {
              event = e;
            }
          } else {
            event = e;
          }
        }
      });
      if(event) {
        selectEvent('body', event);
      }
    }
  }

  function showCalendar() {
    setCalendarView(true);
    setCardView(false);
  }

  function showCards() {
    setCardView(true);
    setCalendarView(false);
  }

  const EventDetail = (eventInfo) => {
      let title_array
      let staff = ''
      let cars = ''
      if(eventInfo.event && eventInfo.event._def && eventInfo.event._def.title && eventInfo.event._def.title.indexOf(',') >= 0) {
        title_array = eventInfo.event._def.title.split(',')
        staff = title_array[0]
        cars = title_array[1]
      }
      return (
        <div>
          <div>{staff}</div>
          <div>{cars}</div>
        </div>
      )
  };

  const viewInvoice = (event) => {
    window.open(
      `${API_URL}/invoice/${event.reference_number}.pdf`,
      '_blank'
    );
  }

  function blockTime() {

  }

  function renderStarRatings(feedback) {
    var ratings = [];
    if(feedback) {
      for(let i = 0; i < parseInt(feedback); i++){
        ratings.push(<div>
          <img
            src={starImage}
            className="rating-star"
          />
        </div>)
      }
    }
    return ratings;
  }

  return (
    <div className="inner">
      <LoggedInTopMenu notificationClick={clickNotification} />
      <div ref={inputRef} onClick={play}>CarCare</div>
      <div className={classes.content}>
        <Grid
          justify="space-between"
          container
        >
          <Hidden smDown>
            <div className={classes.sideMenu}>
              <SideMenu />
            </div>
          </Hidden>
          <div className={classes.mainContent}>
            <div className={classes.article}>
              <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                <a key="1" href="#" className={classes.activePageHeader}>{t('side.dashboard')}</a>  
                <div id="dashboard-view-container" style={{margnBottom:15}}>
                  <div
                    class="view-container calendar"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      backgroundColor: calendarView ? '#D3D3D0' : '#FFF',
                      fontWeight: calendarView ? 'bold' : ''
                    }}
                    onClick={showCalendar} 
                  >
                    <EventNoteIcon 
                      id="calendar-view" 
                      style={{
                        color: calendarView ? '#9F0000' : '#000'
                      }}
                    />
                    <div style={{
                      marginLeft: 8
                    }}>CALENDAR VIEW</div>
                  </div>
                  <div
                    class="view-container card"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      backgroundColor: cardView ? '#D3D3D0' : '#FFF',
                      fontWeight: cardView ? 'bold' : ''
                    }}
                    onClick={showCards}
                  >
                    <ViewCarouselIcon 
                      id="card-view" 
                      style={{
                        color: cardView ? '#9F0000' : '#000'
                      }}
                    />
                    <div style={{
                      marginLeft: 8
                    }}>CARD VIEW</div>
                  </div>
                </div>
              </div>
              <div id="calendar-wrapper">
                <Hidden smDown>
                  <div id="calendar"
                    style={{
                      display: cardView ? 'none' : ''
                    }}
                  >
                    <FullCalendar
                      plugins={[ dayGridPlugin, interactionPlugin, listPlugin, timeGridPlugin ]}
                      initialView="timeGridWeek"
                      contentHeight="auto"
                      events={events}
                      dateClick={handleDateClick}
                      eventClick={handleEventClick}
                      themeSystem='bootstrap'
                      allDaySlot={false}
                      slotMinTime={'05:00:00'}
                      slotMaxTime={'22:00:00'}
                      // slotDuration={'02:00:00'}
                      slotEventOverlap={true}
                      eventContent={EventDetail}
                      businessHours={washCenterHours}
                    />
                  </div>
                </Hidden>
                <Hidden mdUp>
                  <div
                    style={{
                      display: cardView ? 'none' : ''
                    }}
                  >
                    <FullCalendar
                      plugins={[ dayGridPlugin, interactionPlugin, listPlugin, timeGridPlugin ]}
                      initialView="timeGridWeek"
                      contentHeight="auto"
                      events={events}
                      dateClick={handleDateClick}
                      eventClick={handleEventClick}
                      themeSystem='bootstrap'
                      allDaySlot={false}
                      slotMinTime={'05:00:00'}
                      slotMaxTime={'22:00:00'}
                      slotEventOverlap={true}
                      eventContent={EventDetail}
                      businessHours={washCenterHours}
                    />
                  </div>
                </Hidden>
                <Animated animationIn="zoomInUp" animationOut="zoomOutDown" isVisible={cardView}>
                  <div id="events">
                  {selectedday == 'Today' ? t('dashboard.today') : selectedday}
                  {!selecteddayevents?.id &&
                    <div>{t('dashboard.noschedule')}</div>
                  }
                  <div className={classes.cardContainer}>
                    <div key={selecteddayevents?.id} className={classes.card}>
                      <div className={classes.top}>
                        <div className={classes.userContainer}>
                          <a 
                            href="#"
                            style={{
                              width: 36,
                              height: 36,
                              backgroundColor: '#f9d2d2',
                              borderRadius: 10,
                              paddingTop: 8,
                              paddingLeft: 7,
                              size: 10,
                              fontSize: 10
                            }}
                          >
                            <ContactsIcon 
                              style={{
                                color: '#9f0000'
                              }}
                            />
                          </a>
                          <div className={classes.customerDetails}>
                            <a 
                              href="#" 
                              className={classes.customerNameLink}
                            >
                              {t('dashboard.flat')} {selecteddayevents?.flat_number}
                            </a>
                          </div>
                        </div>
                        <div className={classes.userContainer}>
                          <a 
                            href="#"
                            onClick={handleClickOpen('body', selecteddayevents)}
                          >
                            <img
                              className={classes.customer}
                              src={API_URL + '/profile/image/' + (selecteddayevents.created_by_image ? selecteddayevents.created_by_image : 'default.png')} 
                            />
                          </a>
                          <div className={classes.customerDetails}>
                            <a 
                              href="#" 
                              className={classes.customerNameLink} 
                              onClick={handleClickOpen('body', selecteddayevents)}
                            >
                              {selecteddayevents?.customer_first_name} {selecteddayevents?.customer_last_name}
                            </a>
                          </div>
                          <div>
                            <ShareIcon 
                              className={classes.share}
                              style={{
                                position: 'absolute',
                                marginLeft: 195,
                                marginTop: 5
                              }}
                              onClick={handleShareClick(selecteddayevents)}
                            />
                          </div>
                        </div>
                        <div className={classes.carContainer}>
                        <img
                          className={classes.car}
                          src={API_URL + '/car/type/image/' + selecteddayevents.car_type_image} 
                        />
                        <div className={classes.carDetails}>{selecteddayevents.quantity}x {selecteddayevents.car_type}</div>
                        </div>
                      </div>
                      <div className={classes.bottom}>
                        <div className={classes.serviceContainer}>
                          <div className={classes.wash}>
                            <img 
                              className={classes.washImage} 
                              src={washimage} 
                              alt="Wash" />
                          </div>
                          <div className={classes.service}>{selecteddayevents.outcall}</div>
                          <div className={selecteddayevents?.progress ? classes.progressContainer : ''}>
                            {selecteddayevents.progress && 
                              <div className={classes.check}>
                                <CheckIcon className={classes.checkIcon} />
                              </div>
                            }  
                            <div className={classes.progress}>{selecteddayevents.progress}</div>
                          </div>
                        </div>
                        <div className={classes.locationContainer}>
                          <div className={classes.pin}>
                            <img 
                              className={classes.pinImage} 
                              src={pinimage} 
                              alt="Location" />
                          </div>
                          <div className={classes.address}>{selecteddayevents.business_name}, {selecteddayevents.address}</div>
                        </div>
                        <div className={classes.calendarContainer}>
                          <div className={classes.calendar}>
                            <img 
                              className={classes.calendarImage} 
                              src={calendarimage} 
                              alt="Next" />
                          </div>
                          <div className={classes.next}>{selecteddayevents.next_date ? (t('dashboard.next') + ': ') : ''}{selecteddayevents.next_date ? selecteddayevents.next_date : selecteddayevents.time_slot}</div>
                        </div>
                        <div className={classes.calendarContainer}>
                          <div className={classes.calendar}>
                            <AttachMoneyIcon style={{
                              fontSize: 18,
                              color: '#9F0000',
                              marginLeft: 5,
                              marginTop: 4
                            }} />
                          </div>
                          <div className={classes.next}>{selecteddayevents.currency} {selecteddayevents.amount} {selecteddayevents.payment ? ('(' + selecteddayevents.payment.substr(0, 4) + ')') : ''}</div>
                        </div>
                        <div className={classes.calendarContainer}>
                          <div className={classes.calendar}>
                            <ReceiptIcon style={{
                              fontSize: 18,
                              color: '#9F0000',
                              marginLeft: 5,
                              marginTop: 4
                            }} />
                          </div>
                          <div className={classes.next} style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                          }}>{selecteddayevents.reference_number}</div>
                        </div>
                        {selecteddayevents.status &&
                          <div className={classes.statusContainer} onClick={() => handleUpdateStatus(selecteddayevents)}>
                            {selecteddayevents.status &&
                              <a 
                                className={classes.status} 
                                href="#" 
                              >
                                {
                                  selecteddayevents.status == 'On the way' ? 
                                    t('dashboard.ontheway') : 
                                    (selecteddayevents.status == 'Complete' ? t('dashboard.complete') : 
                                      (
                                        selecteddayevents.status.indexOf('On the way') >= 0 ? 
                                          selecteddayevents.status.replace('On the way', t('dashboard.ontheway')) : 
                                          selecteddayevents.status
                                      )
                                    )
                                }
                              </a>  
                            }
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                  </div>
                </Animated>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  scroll={scroll}
                  aria-labelledby="scroll-dialog-title"
                  aria-describedby="scroll-dialog-description"
                  maxWidth = {'sm'}
                >
                  <DialogTitle id="scroll-dialog-title">
                    <div className={classes.titleContainer}>
                      <div className={classes.dialogTitle}>{selectedevent.outcall}</div>
                      <div><CloseIcon className={classes.close} onClick={handleClose} /></div>
                    </div>
                    <div className={classes.serviceInfoDate}>
                      {selectedevent.request_date}, {selectedevent.time_slot}
                    </div>
                    <div className={classes.buttonContainerFlex}>
                      <>
                        {selectedevent.status &&
                          <Button 
                            aria-label="cancel" 
                            onClick={handleCancelBooking}
                            className={classes.cancelButton}
                          >
                            <CancelIcon className={classes.cancelIcon} />
                            {t('dashboard.cancelbooking')}
                          </Button>
                        }
                        {/* <Button 
                          aria-label="cancel" 
                          className={classes.cancelButton}
                        >
                          <SkipNextIcon className={classes.cancelIcon} />
                          {t('dashboard.skip')}
                        </Button>
                        <Button 
                          aria-label="cancel"
                          className={classes.cancelButton}
                        >
                          <PauseIcon className={classes.cancelIcon} />
                          {t('dashboard.pause')}
                        </Button> */}
                      </>
                    </div>
                  </DialogTitle>
                  <DialogContent dividers={scroll === 'paper'}>
                    <div className={classes.dialogContent}>
                      <div className={classes.dialogUserContainer}>
                        <div className={classes.dialogUser}>
                          <div>
                            <img
                              className={classes.dialogUserImage}
                              src={API_URL + '/profile/image/' + (selectedevent.created_by_image ? selectedevent.created_by_image : 'default.png')} 
                            />
                          </div>  
                          <div>
                            {selectedevent.customer_first_name} {selectedevent.customer_last_name}
                          </div>
                          <div>
                            {selectedevent.phone_number}
                          </div>
                        </div>
                        <div className={classes.dialogCar}>
                          <div>
                            <img
                              className={classes.dialogCarImage}
                              src={API_URL + '/car/type/image/' + selectedevent.car_type_image} 
                            />
                          </div>
                          <div>
                            {selectedevent.car_type}
                          </div>
                          <div>
                            {t('dashboard.carnumber')} {selectedevent.plate_number}
                          </div>
                        </div>
                      </div>
                      <div className={classes.dialogRow}>
                        <div className={classes.dialogFlexContainer}>
                          <img
                            className={classes.modalIconImage}
                            src={washmodalimage} 
                          />
                          <div className={classes.dialogRightText}> 
                            {selectedevent.outcall}
                          </div>
                        </div>
                      </div>
                      <div className={classes.dialogRow}>
                        <div className={classes.dialogFlexContainer}>
                          <div
                            style={{
                              width: 36,
                              height: 36,
                              borderRadius: 10,
                              backgroundColor: '#F7F7F7'
                            }}
                          >
                              <AttachMoneyIcon style={{
                              fontSize: 23,
                              color: '#9F0000',
                              marginLeft: 7,
                              marginTop: 6
                            }} />
                          </div>
                          <div className={classes.dialogRightText}> 
                            {selectedevent.currency} {selectedevent.amount} {selectedevent.payment ? ('(' + selectedevent.payment.substr(0, 4) + ')') : ''} 
                            <div
                              style={{
                                width: '104%',
                                marginTop: 10
                              }}
                            ><b>{t('dashboard.referencenumber')}: </b>{selectedevent.reference_number}</div>
                          </div>
                        </div>
                        <div style={{marginLeft:70}}>
                          <Button 
                            aria-label="cancel"
                            className={classes.cancelButton}
                            onClick={(e) => viewInvoice(selectedevent)}
                          >
                            <ReceiptIcon className={classes.cancelIcon} />
                            {t('statement.viewinvoice')}
                          </Button>
                        </div>
                      </div>
                      <div className={classes.dialogRow}>
                        <div className={classes.dialogFlexContainer}>
                          <img
                            className={classes.modalIconImage}
                            src={pinmodalimage} 
                          />
                          <div className={classes.dialogRightText}> 
                            <>
                              <span>{selectedevent.business_name}, {t('dashboard.flat')} {selectedevent.flat_number}</span>
                              <div>{selectedevent.parking_details}</div>
                              <div
                                style={{
                                  marginTop: 10,
                                  marginLeft: -5
                                }}
                              >
                                <a href={customerlocation} target="_blank">
                                  <MapIcon className={classes.mapIcon} />
                                </a>
                                <a href={whatsapplink} target="_blank">
                                  <WhatsAppIcon className={classes.whatsappIcon} />
                                </a>
                                <a href={mailto}>
                                  <EmailIcon className={classes.emailIcon} />
                                </a>
                              </div>
                              <div className={classes.ratingsContainer}>{renderStarRatings(selectedevent.rating)}</div>
                              <div style={{display:'flex',justifyContent:'space-between',marginTop:20,flexWrap:'wrap'}}>
                                {servicephotos && servicephotos.map((item, index) => (
                                  <img 
                                    className={classes.placeholderImage} 
                                    src={API_URL + '/service/image/' + item.image} 
                                    alt="Carcare" />
                                ))}
                              </div>
                            </>
                          </div>
                        </div>
                      </div>
                      <div className={classes.dialogRow}>
                        <div className={classes.dialogFlexContainer}>
                          <img
                            className={classes.modalIconImage}
                            src={calendarmodalimage} 
                          />
                          <div className={classes.dialogRightText}> 
                            <div className={classes.dialogFlexEndContainer}>
                              <div>{t('dashboard.time')}</div>
                              <div className={classes.dialogRight}>
                                {selectedevent.time_slot} {timeslot}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div id="service-info-current-calendar">
                          <Calendar
                            selected={servicedays} 
                            onChange={handleServiceDayClick}
                          />
                        </div>
                      </div>
                    </div>
                  </DialogContent>
                </Dialog>
                {renderOtherDialog()}
              </div>
            </div>
          </div>
        </Grid>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};
  
export default Dashboard;
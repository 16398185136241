import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { NavLink } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  li {
    padding: 18px 10px;
  }
  @media (max-width: 959px) {
    flex-flow: column nowrap;
    background-color: #9F0000;
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    top: 0;
    right: 0;
    height: 100vh;
    width: 300px;
    padding-top: 7.5rem;
    margin-top: 0px;
    padding-left: 0px;
    transition: transform 0.3s ease-in-out;
    li {
      color: #fff;
    }
    a {
      color: #fff;
      text-decoration: none;
    }
  }
`;

const useStyles = makeStyles(theme => ({
  menu: {
      color: '#fff',
      textDecoration: 'none',
      fontSize: 18,
      fontWeight: 500,
      display: 'block',
      paddingBottom: 30,
      opacity: 0.25
  },
  logout: {
      color: '#fff',
      textDecoration: 'none',
      fontSize: 18,
      fontWeight: 500,
      paddingBottom: 30,
      opacity: 0.25,
      display: 'block'
  }
}));

const RightNav = ({ open }) => {
  const classes = useStyles();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const roleId = localStorage.getItem('roleId');

  useEffect(() => {
      const language = localStorage.getItem('language');
      if(language) {
          i18n.changeLanguage(language);
      }
  }, [])

  function handleLogout(e) {
    localStorage.clear()
    history.push("/")
  }

  return (
    <Ul open={open}>
        <NavLink
          to='/main'
          exact
          activeStyle={{
              fontWeight: "bold",
              color: "#fff",
              opacity: 1
          }}
          className={classes.menu}
        >
          {t('side.main')}
        </NavLink>
        <NavLink
          to='/dashboard'
          exact
          activeStyle={{
              fontWeight: "bold",
              color: "#fff",
              opacity: 1
          }}
          className={classes.menu}
        >
          {t('side.dashboard')}
        </NavLink>
        <NavLink
          to='/buildings'
          exact
          activeStyle={{
              fontWeight: "bold",
              color: "#fff",
              opacity: 1
          }}
          className={classes.menu}
        >
          {t('dashboard.buildings')}
        </NavLink>
        <NavLink
          to='/packages'
          exact
          activeStyle={{
              fontWeight: "bold",
              color: "#fff",
              opacity: 1
          }}
          className={classes.menu}
        >
          {t('side.packages')}
        </NavLink>
        <NavLink
          to='/promo-codes'
          exact
          activeStyle={{
              fontWeight: "bold",
              color: "#fff",
              opacity: 1
          }}
          className={classes.menu}
        >
          {t('side.promocodes')}
        </NavLink>
        <NavLink
          to='/bookings'
          exact
          activeStyle={{
              fontWeight: "bold",
              color: "#fff",
              opacity: 1
          }}
          className={classes.menu}
        >
          {t('side.bookings')}
        </NavLink>
        <NavLink
          to='/ratings'
          exact
          activeStyle={{
              fontWeight: "bold",
              color: "#fff",
              opacity: 1
          }}
          className={classes.menu}
        >
          {t('side.feedback')}
        </NavLink>
        {roleId == 3 &&
          <NavLink
            to='/users'
            exact
            activeStyle={{
                fontWeight: "bold",
                color: "#fff",
                opacity: 1
            }}
            className={classes.menu}
          >
            {t('users.users')}
          </NavLink>
        }
        <NavLink
          to='/notifications'
          exact
          activeStyle={{
              fontWeight: "bold",
              color: "#fff",
              opacity: 1
          }}
          className={classes.menu}
        >
          {t('side.notifications')}
        </NavLink>
        {roleId == 3 &&
          <NavLink
            to='/notificationmessages'
            exact
            activeStyle={{
                fontWeight: "bold",
                color: "#fff",
                opacity: 1
            }}
            className={classes.menu}
          >
            {t('side.notificationmessages')}
          </NavLink>
        }
        <li>
          <a 
              href="#" 
              className={classes.logout}
              onClick={handleLogout}
          >
              {t('side.logout')}
          </a>
      </li>
    </Ul>
  )
}

export default RightNav
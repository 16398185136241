import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useHistory, useParams } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { Grid } from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';
import { API_URL } from '../constants';
import { useTranslation } from 'react-i18next';
import LoggedInTopMenu from '../components/LoggedInTopMenu';
import SideMenu from '../components/SideMenu';
import Hidden from '@material-ui/core/Hidden';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import CloseIcon from '@material-ui/icons/Close';
import Select from 'react-select';
import NativeSelect from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import 'react-toastify/dist/ReactToastify.css';

// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FilePondPluginFileRename from "filepond-plugin-file-rename";

// Register the plugins
registerPlugin(
    FilePondPluginFileRename,
    FilePondPluginImageExifOrientation, 
    FilePondPluginImagePreview
);

const useStyles = makeStyles(theme => ({
    content: {
      paddingTop: 130,
      marginLeft: '-6%'
    },
    sideMenu: {
      backgroundColor: '#9F0000',
      width: 256,
      height: `calc(100vh - 130px)`,
      borderTopRightRadius: 75,
      color: '#fff',
      paddingTop: 70
    },
    mainContent: {
      width: `calc(100% - 256px)`,
      textAlign: 'left',
      ['@media (max-width:959px)']: { 
        width: '95%'
      }
    },
    article: {
      paddingTop: 20,
      paddingLeft: 50,
      ['@media (max-width:959px)']: { 
        paddingLeft: 0
      }
    },
    center: {
        position: 'absolute',
        top: '50%',
        left: '55%',
        width: 580,
        transform: 'translateX(-50%) translateY(-50%)',
        ['@media (max-width:959px)']: { 
            top: '50%',
            left: '0%',
            position: 'initial',
            transform: 'none',
            width: '100%'
        }
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        ['@media (max-width:959px)']: { 
            width: '90%',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
    },
    close: {
        fontSize: 24,
        "&:hover": {
            cursor: "pointer"
        }
    },
    topFieldsContainer: {
        // width: 580,
        marginLeft: 'auto',
        marginRight: 'auto',
        ['@media (max-width:959px)']: { 
          width: '90%'
        }
    },
    fieldsContainer: {
        paddingTop: 35,
        // width: 580,
        marginLeft: 'auto',
        marginRight: 'auto',
        ['@media (max-width:959px)']: { 
          width: '90%'
        }
    },
    inputContainer: {
        width: '100%',
        height: 72
    },
    inputContainerDesc: {
        width: '100%',
        marginTop: 15,
    },
    inputFlexContainer: {
        height: 72,
        display: 'flex',
        justifyContent: 'space-between',
        ['@media (max-width:959px)']: { 
            width: '100%',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    },
    input: {
        width: '100%',
        fontSize: 16
    },
    flexLastInput: {
        width: '100%',
        fontSize: 16,
        marginLeft: 20
    },
    saveContainer: {
        paddingTop: 16,
        float: 'right',
        marginTop: 65,
        ['@media (max-width:959px)']: { 
            marginRight: '3%',
            marginTop: '25%'
        }
    },
    saveText: {
        color: '#9F0000',
        fontSize: 16,
        fontWeight: 500,
        display: 'inline-block',
        position: 'absolute',
        marginTop: 7,
        marginLeft: -45,
        width: 44
    },
    saveBtnContainer: {
        width: 36,
        height: 36,
        borderRadius: 12,
        backgroundColor: '#9F0000',
        display: 'inline-block',
        "&:hover": {
            cursor: 'pointer'
        },
    },
    saveBtn: {
        width: 21,
        height: 21,
        position: 'absolute',
        marginTop: 6,
        marginLeft: 8
    },
    flexInput: {
        fontSize: 16,
        width: '25%',
        paddingRight: 20
    },
    flexInputLast: {
        fontSize: 16,
        width: '25%'
    },
    selectService: {
        width: '100%',
        fontFamily: 'Nunito',
        ['@media (max-width:959px)']: { 
            width: 310,
            marginBottom: 20
        }
    },
    serviceLabel: {
        paddingBottom: 20,
        fontSize: 16,
        fontFamily: 'Nunito',
        ['@media (max-width:959px)']: { 
            paddingTop: 65
        }
    },
    deleteContainer: {
        marginTop: 85,
        float: 'left',
        color: '#9F0000',
        "&:hover": {
            cursor: 'pointer'
        },
        ['@media (max-width:959px)']: { 
            marginTop: '22%',
            marginLeft: 15
        }
    },
    priceFlex: {
        flex: 1,
        flexDirection: 'row',
        marginTop: 15,
    },
    flexInput: {
        fontSize: 16,
        width: '33%',
        paddingRight: 20
    },
    flexInputLast: {
        fontSize: 16,
        width: '34%'
    },
    inputContainerDurationTo: {
        width: '100%',
        height: 72,
        ['@media (max-width:959px)']: { 
            marginTop: 100
        }
    },
    durationLabel: {
        paddingBottom: 20,
        fontSize: 16,
        marginTop: 20,
        ['@media (max-width:959px)']: { 
            paddingTop: 65
        }
    },
    durationContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        ['@media (max-width:768px)']: { 
          display: 'block'
        }
    },
    selectDuration: {
        // width: 135,
        ['@media (max-width:959px)']: { 
            // width: 310,
            marginBottom: 20
        }
    },
    flexContainer: {
        display: 'flex',
        ['@media (max-width:959px)']: { 
            display: 'block'
        }
    },
    halfDiv: {
        width: '50%',
        ['@media (max-width:959px)']: { 
            width: '100%'
        }
    },
    leftContainer: {
        width: '25%',
        marginRight: '5%',
        ['@media (max-width:959px)']: { 
            width: '100%',
            marginRight: 0,
        }
    },
    rightContainer: {
        width: '70%',
        ['@media (max-width:959px)']: { 
            width: '100%'
        }
    },
    mainFlex: {
        display: 'flex',
        ['@media (max-width:959px)']: { 
            display: 'block'
        }
    },
    minusHalfDiv: {
        width: '49%',
        ['@media (max-width:959px)']: { 
            width: '100%'
        }
    },
    saveDiv: {
        marginTop: 40,
        float: 'left',
        backgroundColor: '#9F0000',
        color: '#fff',
        borderRadius: 10,
        marginRight: 15,
        paddingLeft: 30,
        paddingRight: 30,
        paddingTop: 6,
        height: 34,
        "&:hover": {
            cursor: 'pointer'
        }
    },
    deleteDiv: {
        marginTop: 40,
        float: 'left',
        color: '#9F0000',
        borderColor: '#9F0000',
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 10,
        paddingLeft: 30,
        paddingRight: 30,
        paddingTop: 6,
        height: 34,
        "&:hover": {
            cursor: 'pointer'
        }
    },
}));

const ManagePackage = () => {
    const classes = useStyles();
    const userId = localStorage.getItem('userId');
    const roleId = localStorage.getItem('roleId');
    const history = useHistory();
    const params = useParams();
    const { t, i18n } = useTranslation();
    const [days, setDays] = useState('');
    const [hours, setHours] = useState('');
    const [minutes, setMinutes] = useState('');
    const [hoursTo, setHoursTo] = useState('');
    const [minutesTo, setMinutesTo] = useState('');
    let [language, setLanguage] = useState('');
    let [mode, setMode] = useState('');
    let [name, setName] = useState('');
    let [nameAr, setNameAr] = useState('');
    let [description, setDescription] = useState('');
    let [descriptionAr, setDescriptionAr] = useState('');
    let [smallPrice, setSmallPrice] = useState('');
    let [mediumPrice, setMediumPrice] = useState('');
    let [largePrice, setLargePrice] = useState('');
    let [frequency, setFrequency] = useState('');
    let [frequencyUnit, setFrequencyUnit] = useState('');
    let [service, setService] = useState(null);
    let [packageTypes, setPackageTypes] = useState(null);
    let [selectedPackageType, setSelectedPackageType] = useState(null);
    let [selectedPackageTypeObject, setSelectedPackageTypeObject] = useState(null);
    let [packageSubscriptionTypes, setPackageSubscriptionTypes] = useState(null);
    let [selectedPackageSubscriptionType, setSelectedPackageSubscriptionType] = useState(null);
    let [selectedPackageSubscriptionTypeObject, setSelectedPackageSubscriptionTypeObject] = useState(null);
    let [buildings, setBuildings] = useState(null);
    let [selectedBuilding, setSelectedBuilding] = useState(null);
    let [selectedBuildingObject, setSelectedBuildingObject] = useState(null);
    let [formSubmitted, setFormSubmitted] = useState(false);
    let [files, setFiles] = useState(null);
    let [packageImage, setPackageImage] = useState(null);

    useEffect(() => {
        const language = localStorage.getItem('language');
        if(language) {
            i18n.changeLanguage(language);
        }
        setLanguage(language);

        initializeData();
        setMode(params.id > 0 ? 'edit' : 'add');
    }, [])

    if (!userId) {
        return <Redirect to={'/'} />;
    } 

    const initializeData = () => {
        axios.get(API_URL + `/properties`)
            .then(resp => {
                let all_bldgs = resp.data.properties;
                if(roleId == 5) {
                    axios.get(API_URL + `/manager-properties`)
                    .then(resp => {
                        let manager_properties = resp.data.manager_properties;
                        let filtered_bldgs = []
                        if(all_bldgs?.length > 0) {
                            filtered_bldgs = all_bldgs.filter(mp => {
                                return manager_properties?.some(a => a.property_id == mp.id && a.user_id == userId)
                            })
                        } else {
                            filtered_bldgs = all_bldgs
                        }
                        continueLoad(filtered_bldgs)
                    })
                } else {
                    continueLoad(all_bldgs)
                }
            })
    }

    const continueLoad = (all_bldgs) => {
        let bldng = formatSelectData(all_bldgs, 'properties');
        axios.get(API_URL + `/package-subscription-types`)
        .then(rsp => {
            let pst = rsp.data.package_subscription_types;
            pst = formatSelectData(pst, 'package_subscription_types');

            axios.get(API_URL + `/package-types`)
            .then(response => {
                let pck = response.data.package_types;
                pck = formatSelectData(pck, 'package_types');

                if(params.id > 0) {
                    axios.get(API_URL + `/package-details/${params.id}`)
                    .then(response2 => {
                        let svc = response2.data.service;
                        if(svc) {
                            setService(svc);
                            setName(svc.name);
                            setNameAr(svc.name_ar);
                            setSmallPrice(svc.small_price);
                            setMediumPrice(svc.medium_price);
                            setLargePrice(svc.large_price);
                            setDescription(svc.description);
                            setDescriptionAr(svc.description_ar);
                            setFrequency(svc.frequency);
                            setFrequencyUnit(svc.frequency_unit);
                            if(svc.image) {
                                setPackageImage(`${API_URL}/package-image/${svc.image}`);
                            }
                            if(svc.unit == 'hr') {
                                setHours(svc.duration);
                            } else if(svc.unit == 'min') {
                                setMinutes(svc.duration);
                            }
                            if(svc.unit_to == 'hr') {
                                setHoursTo(svc.duration_to);
                            } else if(svc.unit_to == 'min') {
                                setMinutesTo(svc.duration_to);
                            }
                            if(svc.package_type_id) { 
                                setSelectedPackageType(svc.package_type_id);
                                setSelectedPackageTypeObject(valueFromId(pck, svc.package_type_id)); 
                            }
                            if(svc.package_subscription_type_id) { 
                                setSelectedPackageSubscriptionType(svc.package_subscription_type_id);
                                setSelectedPackageSubscriptionTypeObject(valueFromId(pst, svc.package_subscription_type_id)); 
                            }
                            if(svc.properties && svc.properties.length > 0) {
                                let properties = formatSelectData(svc.properties, 'buildings');
                                setSelectedBuildingObject(properties);
                            }
                        }
                    })
                }
            })
        })
    }

    const valueFromId = (opts, id) => opts.find(o => o.value === id);

    const formatSelectData = (array, mode) => {
        let new_array = []
        if(array.length > 0) {
            array.forEach(item => {
                new_array.push({
                    value: item.id,
                    label: language == 'ar' ? ((item.name_ar) ? (item.name_ar) : (item.name)) : (item.name)
                })
            })
        }

        if(mode == 'package_types') { setPackageTypes(new_array); }
        if(mode == 'package_subscription_types') { setPackageSubscriptionTypes(new_array); }
        if(mode == 'properties') { setBuildings(new_array); }
        return new_array;
    }

    const handleFieldChange = (event, type) => {
        switch(type) {
            case 'name': 
                setName(event.target.value);
                break;
            case 'nameAr': 
                setNameAr(event.target.value);
                break;
            case 'smallPrice': 
                setSmallPrice(event.target.value);
                break;
            case 'mediumPrice': 
                setMediumPrice(event.target.value);
                break;
            case 'largePrice': 
                setLargePrice(event.target.value);
                break;
            case 'description': 
                setDescription(event.target.value);
                break;
            case 'descriptionAr': 
                setDescriptionAr(event.target.value);
                break;
            case 'frequency': 
                setFrequency(event.target.value);
                break;
        }
    };

    const handlePackageTypeChange = (data) => {
        setSelectedPackageTypeObject(data);
        setSelectedPackageType(data.value);
    };

    const handlePackageSubscriptionTypeChange = (data) => {
        setSelectedPackageSubscriptionTypeObject(data);
        setSelectedPackageSubscriptionType(data.value);
    };

    const handleBuildingChange = (data) => {
        setSelectedBuildingObject(data);
        setSelectedBuilding(data);
    };

    function handleClick(e) {
        setFormSubmitted(true);
        if(!name || !nameAr || !smallPrice || !mediumPrice || !largePrice) {
            showError(t('promo-codes.fillup'))
        } else {
            if(mode == 'add') { 
                addPackage(); 
            } else {
                editPackage();
            }
        }
    }

    const handleHoursChange = (event) => {
        setHours(event.target.value);
    };

    const handleMinutesChange = (event) => {
        setMinutes(event.target.value);
    };

    const handleHoursToChange = (event) => {
        setHoursTo(event.target.value);
    };

    const handleMinutesToChange = (event) => {
        setMinutesTo(event.target.value);
    };

    const handleFrequencyUnitChange = (event) => {
        setFrequencyUnit(event.target.value);
    };

    function addPackage() {
        let duration;
        let duration_to;
        let unit;
        let unit_to;
        if(hours) {
            duration = hours;
            unit = 'hr';
        }  else if(minutes) {
            duration = minutes;
            unit = 'min';
        }

        if(hoursTo) {
            duration_to = hoursTo;
            unit_to = 'hr';
        }  else if(minutesTo) {
            duration_to = minutesTo;
            unit_to = 'min';
        }

        let filename = localStorage.getItem('filename')
        axios.post(API_URL + `/package/add`, { 
            name,
            name_ar: nameAr,
            package_type_id: selectedPackageType,
            description,
            description_ar: descriptionAr,
            small_price: smallPrice || null,
            medium_price: mediumPrice || null,
            large_price: largePrice || null,
            duration,
            duration_to,
            unit,
            unit_to,
            frequency: frequency || null,
            frequency_unit: frequencyUnit || '',
            package_subscription_type_id: selectedPackageSubscriptionType,
            package_properties: selectedBuilding,
            image: filename || ''
        })
        .then(res => {
            if(res.status == 200) {
                localStorage.removeItem('filename')
                toast.success(t("users.successadd"), {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setTimeout(function () {
                    history.push("/packages");
                }, 2000);
            }
        })
    }

    function editPackage() {
        let duration;
        let duration_to;
        let unit;
        let unit_to;
        if(hours) {
            duration = hours;
            unit = 'hr';
        }  else if(minutes) {
            duration = minutes;
            unit = 'min';
        }

        if(hoursTo) {
            duration_to = hoursTo;
            unit_to = 'hr';
        }  else if(minutesTo) {
            duration_to = minutesTo;
            unit_to = 'min';
        }

        let filename = localStorage.getItem('filename')
        axios.post(API_URL + `/package/edit/${params.id}`, {
            name,
            name_ar: nameAr,
            package_type_id: selectedPackageType,
            description,
            description_ar: descriptionAr,
            small_price: smallPrice || null,
            medium_price: mediumPrice || null,
            large_price: largePrice || null,
            duration,
            duration_to,
            unit,
            unit_to,
            frequency: frequency || null,
            frequency_unit: frequencyUnit,
            package_subscription_type_id: selectedPackageSubscriptionType,
            package_properties: selectedBuilding,
            image: filename || ''
        })
        .then(res => {
            if(res.status == 200) {
                toast.success(t("users.successsave"), {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setTimeout(function () {
                    history.push("/packages");
                }, 2000);
            }
        })
    }

    function showError(message) {
        toast.error(message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function handleClose(e) {
        history.push("/packages")
    }

    function handleDelete(e) {
        if(params.id) {
            axios.post(API_URL + `/package/delete/${params.id}`, {})
            .then(res => {
                if(res.status == 200) {
                    toast.success(t("users.successdelete"), {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setTimeout(function () {
                        history.push("/packages");
                    }, 2000);
                }
            })
        } else {
            toast.error(t('promo-codes.deleteerror'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const handleInit = () => {
        console.log('FilePond instance has initialised');
    }

    function handleRename() {
        return `package_${getTimestamp()}.jpeg`;
    }

    function getTimestamp() {
        const date = new Date();
        const timestamp = date.getTime();
    
        return timestamp;
    }

    return (
        <div className="inner">
            <LoggedInTopMenu />
            <div className={classes.content}>
                <Grid
                    justify="space-between"
                    container
                >
                    <Hidden smDown>
                        <div className={classes.sideMenu}>
                            <SideMenu />
                        </div>
                    </Hidden>
                    <ToastContainer />
                    <div className={classes.mainContent}>
                        <div className={classes.article}>
                            <div className={classes.topFieldsContainer}>
                                <div className={classes.header}>
                                    <div className={classes.title}>{mode == 'edit' ? t('packages.editapackage') : t('packages.addapackage')}</div>
                                    <div><CloseIcon className={classes.close} onClick={handleClose} /></div>
                                </div>
                                <div className={classes.mainFlex}>
                                    <div className={classes.leftContainer}>
                                        <div style={{marginTop:30,marginBottom:15}}>{t('packages.packageimage')}</div>
                                        {packageImage &&
                                            <div style={{textAlign:'center'}}>
                                                <img 
                                                    style={{width:'50%',marginBottom:15}} 
                                                    src={packageImage} 
                                                    alt="Carcare" />
                                            </div>
                                        }
                                        <FilePond
                                            files={files}
                                            fileRenameFunction={file => handleRename()}
                                            oninit={() => handleInit()}
                                            server="https://appcarcare.com/api/package/temp-upload"
                                            onupdatefiles={(fileItems) => {
                                                fileItems && fileItems.map((fileItem) => {
                                                    if(fileItem?.file?.name) {
                                                        localStorage.setItem('filename', fileItem?.file?.name) 
                                                    }
                                                })
                                            }}
                                        ></FilePond>
                                        <div style={{display:'flex',justifyContent:'center'}}>
                                            <div 
                                                className={classes.saveDiv}
                                                onClick={handleClick}
                                            >
                                                {t('promo-codes.save')}
                                            </div>
                                            {mode == 'edit' &&
                                                <div 
                                                    className={classes.deleteDiv}
                                                    onClick={handleDelete}
                                                >
                                                    {t('promo-codes.delete')}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className={classes.rightContainer}>
                                        <div className={classes.fieldsContainer}>
                                            <form>
                                                <div style={{display:'flex',justifyContent:'space-between'}}>
                                                    <div style={{width:'100%'}}>
                                                        <Select
                                                            placeholder={t('packages.selecttype')}
                                                            value={selectedPackageTypeObject}
                                                            onChange={handlePackageTypeChange}
                                                            options={packageTypes}
                                                            name="packagetype"
                                                            required
                                                        />
                                                    </div>
                                                    {selectedPackageType == 1 &&
                                                        <div style={{width:'100%',marginLeft:'4%'}}>
                                                            <Select
                                                                placeholder={t('packages.selecttype')}
                                                                value={selectedPackageSubscriptionTypeObject}
                                                                onChange={handlePackageSubscriptionTypeChange}
                                                                options={packageSubscriptionTypes}
                                                                name="packagesubscriptiontype"
                                                                required
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                                {selectedPackageType == 1 &&
                                                    <div className={classes.flexContainer} style={{gap:25,marginTop:15,alignItems:'center'}}>
                                                        <div className={classes.halfDiv}>
                                                            <TextField 
                                                                type="number"
                                                                id="frequency"
                                                                label={t('packages.nooftimes')}
                                                                className={classes.input}
                                                                placeholder={t('packages.nooftimes')}
                                                                value={frequency || ''}
                                                                onChange={(event) => handleFieldChange(event, 'frequency')}
                                                                error={formSubmitted && !frequency ? true : false}
                                                                required
                                                            />
                                                        </div>
                                                        <div className={classes.halfDiv}>
                                                            <FormControl fullWidth variant="outlined" className={classes.formControl}>
                                                                <NativeSelect
                                                                    labelId="hours-select-outlined-label"
                                                                    id="hours-select-outlined"
                                                                    value={frequencyUnit}
                                                                    onChange={handleFrequencyUnitChange}
                                                                    className={classes.selectDuration}
                                                                    displayEmpty
                                                                    style={{marginTop:10}}
                                                                >
                                                                    <MenuItem value=""></MenuItem>
                                                                    <MenuItem value="week">week</MenuItem>
                                                                    <MenuItem value="month">month</MenuItem>
                                                                </NativeSelect>
                                                            </FormControl>
                                                        </div>
                                                    </div>
                                                }
                                                <div style={{marginTop:25}}>
                                                    <Select
                                                        placeholder={t('users.selectbuilding')}
                                                        value={selectedBuildingObject}
                                                        onChange={handleBuildingChange}
                                                        options={buildings}
                                                        isMulti
                                                    />
                                                </div>
                                                <div className={classes.flexContainer} style={{gap:25,marginTop:15,alignItems:'center'}}>
                                                    <div className={classes.halfDiv}>
                                                        <TextField 
                                                            id="name"
                                                            label={t('buildings.name')} 
                                                            className={classes.input}
                                                            placeholder={t('buildings.name')}
                                                            required
                                                            error={formSubmitted && !name ? true : false}
                                                            value={name || ''}
                                                            onChange={(event) => handleFieldChange(event, 'name')}
                                                        />
                                                    </div>
                                                    <div className={classes.halfDiv}>
                                                        <TextField 
                                                            id="nameAr"
                                                            label={t('buildings.namear')}
                                                            className={classes.input}
                                                            placeholder={t('buildings.namear')}
                                                            required
                                                            error={formSubmitted && !nameAr ? true : false}
                                                            value={nameAr || ''}
                                                            onChange={(event) => handleFieldChange(event, 'nameAr')}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={classes.priceFlex}>
                                                    <TextField 
                                                        id="smallprice"
                                                        label={t('services.pricesmall')}
                                                        className={classes.flexInput}
                                                        placeholder={t('services.pricesmall')}
                                                        required
                                                        error={formSubmitted && !smallPrice ? true : false}
                                                        value={smallPrice || ''}
                                                        onChange={(event) => handleFieldChange(event, 'smallPrice')}
                                                        
                                                    />
                                                    <TextField 
                                                        id="mediumprice"
                                                        label={t('services.pricemedium')}
                                                        className={classes.flexInput}
                                                        placeholder={t('services.pricemedium')}
                                                        required
                                                        error={formSubmitted && !mediumPrice ? true : false}
                                                        value={mediumPrice || ''}
                                                        onChange={(event) => handleFieldChange(event, 'mediumPrice')}
                                                    />
                                                    <TextField 
                                                        id="largeprice"
                                                        label={t('services.pricelarge')}
                                                        className={classes.flexInputLast}
                                                        placeholder={t('services.pricelarge')}
                                                        required
                                                        error={formSubmitted && !largePrice ? true : false}
                                                        value={largePrice || ''}
                                                        onChange={(event) => handleFieldChange(event, 'largePrice')}
                                                    />
                                                </div>
                                                <div className={classes.inputContainerDesc}>
                                                    <TextField 
                                                        id="description"
                                                        label={t('services.description')}
                                                        multiline
                                                        className={classes.input}
                                                        placeholder={t('services.description')}
                                                        value={description || ''}
                                                        onChange={(event) => handleFieldChange(event, 'description')}
                                                    />
                                                </div>
                                                <div className={classes.inputContainerDesc}>
                                                    <TextField 
                                                        id="description"
                                                        label={t('services.descriptionar')}
                                                        multiline
                                                        className={classes.input}
                                                        placeholder={t('services.descriptionar')}
                                                        value={descriptionAr || ''}
                                                        onChange={(event) => handleFieldChange(event, 'descriptionAr')}
                                                    />
                                                </div>
                                                <div className={classes.durationContainer}>
                                                    <div className={classes.inputContainer}>
                                                        <div className={classes.durationLabel}>{t('services.durationfrom')}</div>
                                                        <div className={classes.durationContainer}>
                                                            <div className={classes.minusHalfDiv}>
                                                                <FormControl fullWidth variant="outlined" className={classes.formControl}>
                                                                    <NativeSelect
                                                                        labelId="hours-select-outlined-label"
                                                                        id="hours-select-outlined"
                                                                        value={hours}
                                                                        onChange={handleHoursChange}
                                                                        className={classes.selectDuration}
                                                                        displayEmpty
                                                                    >
                                                                        <MenuItem value="">0 hour</MenuItem>
                                                                        <MenuItem value={1}>1 hour</MenuItem>
                                                                        <MenuItem value={2}>2 hours</MenuItem>
                                                                        <MenuItem value={3}>3 hours</MenuItem>
                                                                        <MenuItem value={4}>4 hours</MenuItem>
                                                                        <MenuItem value={5}>5 hours</MenuItem>
                                                                        <MenuItem value={6}>6 hours</MenuItem>
                                                                        <MenuItem value={7}>7 hours</MenuItem>
                                                                    </NativeSelect>
                                                                </FormControl>
                                                            </div>
                                                            <div className={classes.minusHalfDiv}>
                                                                <FormControl fullWidth variant="outlined" className={classes.formControl}>
                                                                    <NativeSelect
                                                                        labelId="minutes-select-outlined-label"
                                                                        id="minutes-select-outlined"
                                                                        value={minutes}
                                                                        onChange={handleMinutesChange}
                                                                        className={classes.selectDuration}
                                                                        displayEmpty
                                                                        style={{marginRight:9}}
                                                                    >
                                                                        <MenuItem value="">0 minute</MenuItem>
                                                                        <MenuItem value={15}>15 minutes</MenuItem>
                                                                        <MenuItem value={30}>30 minutes</MenuItem>
                                                                        <MenuItem value={45}>45 minutes</MenuItem>
                                                                    </NativeSelect>
                                                                </FormControl>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={classes.inputContainerDurationTo}>
                                                        <div className={classes.durationLabel}>{t('services.durationto')}</div>
                                                        <div className={classes.durationContainer}>
                                                            <div className={classes.minusHalfDiv}>
                                                                <FormControl fullWidth variant="outlined" className={classes.formControl}>
                                                                    <NativeSelect
                                                                        labelId="hours-select-outlined-label"
                                                                        id="hours-select-outlined"
                                                                        value={hoursTo}
                                                                        onChange={handleHoursToChange}
                                                                        className={classes.selectDuration}
                                                                        displayEmpty
                                                                    >
                                                                        <MenuItem value="">0 hour</MenuItem>
                                                                        <MenuItem value={1}>1 hour</MenuItem>
                                                                        <MenuItem value={2}>2 hours</MenuItem>
                                                                        <MenuItem value={3}>3 hours</MenuItem>
                                                                        <MenuItem value={4}>4 hours</MenuItem>
                                                                        <MenuItem value={5}>5 hours</MenuItem>
                                                                        <MenuItem value={6}>6 hours</MenuItem>
                                                                        <MenuItem value={7}>7 hours</MenuItem>
                                                                    </NativeSelect>
                                                                </FormControl>
                                                            </div>
                                                            <div className={classes.minusHalfDiv}>
                                                                <FormControl fullWidth variant="outlined" className={classes.formControl}>
                                                                    <NativeSelect
                                                                        labelId="minutes-select-outlined-label"
                                                                        id="minutes-select-outlined"
                                                                        value={minutesTo}
                                                                        onChange={handleMinutesToChange}
                                                                        className={classes.selectDuration}
                                                                        displayEmpty
                                                                    >
                                                                        <MenuItem value="">0 minute</MenuItem>
                                                                        <MenuItem value={15}>15 minutes</MenuItem>
                                                                        <MenuItem value={30}>30 minutes</MenuItem>
                                                                        <MenuItem value={45}>45 minutes</MenuItem>
                                                                    </NativeSelect>
                                                                </FormControl>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Grid>
            </div>
        </div>
    );
};
  
export default ManagePackage;
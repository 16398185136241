import React, { useRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { API_URL, PUSHER_APP_KEY } from '../constants';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import logoimage from '../images/logo-image.png'; 
import logotext from '../images/logo-text.png'; 
import Navbar from '../components/Side/Navbar';
import Hidden from '@material-ui/core/Hidden';
import bahrainimage from '../images/bahrain.png'; 
import usimage from '../images/us.png'; 
import NotificationsIcon from '@material-ui/icons/Notifications';
import Badge from '@material-ui/core/Badge';
import { Icon, Popup, Header, Placeholder } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import Pusher from 'pusher-js';
import axios from 'axios';
import useSound from 'use-sound';
import soundFile from '../assets/alert.mp3';
import { ToastContainer, toast } from 'react-toastify';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import washimage from '../images/wash.png'; 
import calendarimage from '../images/calendar.png'; 
import pinimage from '../images/pin.png'; 
import washmodalimage from '../images/wash-modal.png'; 
import pinmodalimage from '../images/pin-modal.png'; 
import calendarmodalimage from '../images/calendar-modal.png'; 
import Calendar from 'react-calendar-multiday';
import CancelIcon from '@material-ui/icons/Cancel';
import MapIcon from '@material-ui/icons/Map';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import EmailIcon from '@material-ui/icons/Email';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ReceiptIcon from '@material-ui/icons/Receipt';
import moment from 'moment';

const drawerWidth = 0;

const useStyles = makeStyles(theme => ({
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    backgroundColor: `#fff` ,
    boxShadow: 'none'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontSize: 18
  },
  logoImage: {
    width: 40
  },
  logoText: {
    width: 104,
    position: 'absolute',
    top: 34,
    left: 102,
    ['@media (max-width:959px)']: { 
      top: 54,
      left: 77
    }
  },
  toolbar: {
    paddingLeft: 55,
    paddingRight: 32,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: 20,
    ['@media (max-width:959px)']: { 
      paddingLeft: 32,
      paddingTop: 35
    }
  },
  rightMenu: {
    paddingRight: 38
  },
  userImageContainer: {
    width: 48,
    height: 48,
    display: 'inline-block',
    marginRight: 15
  },
  userImage: {
    width: 48,
    height: 48,
    objectFit: 'cover',
    borderRadius: 10
  },
  nameContainer: {
    fontSize: 14,
    color: '#000',
    display: 'inline-block',
    position: 'relative',
    marginTop: 15,
    float: 'right'
  },
  arabic: {
    width: 25,
    height: 25,
    borderRadius: '50%',
    border: 'solid 1px #9F0000',
    position: 'absolute',
    marginLeft: -75,
    marginTop: 11,
    "&:hover": {
        cursor: "pointer"
    }
  },
  english: {
    width: 25,
    height: 25,
    borderRadius: '50%',
    border: 'solid 1px #9F0000',
    position: 'absolute',
    marginLeft: -42,
    marginTop: 11,
    "&:hover": {
        cursor: "pointer"
    }
  },
  notificationsContainer: {
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 10,
    borderBottom: 'solid 1px rgba(0,0,0,0.1)'
  },
  notificationImage: {
    width: 36,
    height: 36,
    borderRadius: 12,
    objectFit: 'cover'
  },
  notificationDesc: {
    marginLeft: 15,
    color: '#000',
    "&:hover": {
        cursor: "pointer"
    }
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 7
  },
  dialogTitle: {
    fontSize: 20,
    fontWeight: 'bold'
  },
  close: {
    fontSize: 24,
    "&:hover": {
        cursor: "pointer"
    }
  },
  dialogContent: {
    width: 480,
    marginTop: 50,
    ['@media (max-width:959px)']: { 
      width: '100%'
    }
  },
  serviceInfoDate: {
    fontSize: 14,
    color: '#000',
    opacity: 0.25,
    marginTop: -10
  },
  dialogUserContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '70%',
    marginLeft: 'auto',
    marginRight: 'auto',
    ['@media (max-width:959px)']: { 
      width: '100%'
    }
  },
  dialogUserImage: {
    width: 80,
    height: 80,
    borderRadius: 18,
    objectFit: 'cover',
    marginBottom: 25
  },
  dialogCarImage: {
    width: 80,
    height: 80,
    borderRadius: 18,
    objectFit: 'cover',
    marginBottom: 25
  },
  dialogUser: {
    textAlign: 'center'
  },
  dialogCar: {
    textAlign: 'center'
  },
  dialogFlexContainer: {
    display: 'flex'
  },
  dialogRow: {
    marginTop: 60
  },
  modalIconImage: {
    width: 36,
    height: 36
  },
  dialogRightText: {
    marginLeft: 20,
    paddingTop: 6,
    marginLeft: 20,
    paddingTop: 6,
    width: '85%'
  },
  dialogFlexEndContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  dialogRight: {
    position: 'absolute',
    right: 64,
    fontWeight: 'bold'
  },
  dialogRightLast: {
    position: 'absolute',
    right: 64,
    marginTop: 25,
    fontWeight: 'bold'
  },
  dialogBottomRow: {
    marginTop: 25
  },
  cancelButton: {
    marginTop: 20,
    color: '#fff',
    backgroundColor: '#9f0000',
    borderRadius: 5,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: '#9f0000',
      color: '#fff'
    }
  },
  timeslot: {
    marginTop: -35
  },
  mapIcon: {
    color: '#9F0000',
    marginBottom: -5,
    marginRight: 5,
    marginLeft: 5
  },
  whatsappIcon: {
    color: '#075e54',
    marginBottom: -5,
    marginRight: 5,
    marginLeft: 5
  },
  emailIcon: {
    color: '#9F0000',
    marginBottom: -5,
    marginRight: 5,
    marginLeft: 5
  },
  share: {
    "&:hover": {
        cursor: "pointer"
    }
  },
}));

function LoggedInTopMenu(props) {
  const classes = useStyles();
  const image = localStorage.getItem('image');
  const userName = localStorage.getItem('name');
  const userAvatar = API_URL + `/profile/image/` + image;
  const id = localStorage.getItem('id');
  const roleId = localStorage.getItem('roleId');
  const [data, setData] = React.useState(null);
  const [homeUrl, setHomeUrl] = React.useState('');
  const [notifications, setNotifications] = React.useState(null);
  const [recentNotifications, setRecentNotifications] = React.useState(null);
  const { t, i18n } = useTranslation();
  const timer = React.useRef();
  const [play] = useSound(soundFile);
  const inputRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('body');
  const [bookingDetails, setBookingDetails] = React.useState([]);
  const [bookingTitle, setBookingTitle] = React.useState('');
  let [language, setLanguage] = useState('');
  let [notification, setNotification] = useState(null);
  let [bookingCarTypes, setBookingCarTypes] = useState([]);
  let [buildinglocation, setBuildinglocation] = useState('');
  let [whatsapplink, setWhatsapplink] = useState('');
  let [mailto, setMailto] = useState('');
  let [building, setBuilding] = useState(null);
  let [bookingSchedulesHtml, setBookingSchedulesHtml] = useState('');
  let [bookingShift, setBookingShift] = useState('');
  let [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const language = localStorage.getItem('language');
    const userId = localStorage.getItem('userId');
    if(language) {
      i18n.changeLanguage(language);
    }
    setLanguage(language);

    if(userId > 0) {
      setHomeUrl('/dashboard');
    } else {
      setHomeUrl('/');
    }

    // Get notifications
    let role = 'Admin'
    if(roleId == 5) { role = 'Property' }
    axios.get(API_URL + `/bookings/notifications/user/${userId}/${role}`)
    .then(response => {
      let notifs = response.data.notifications;
      let filtered_notifs = notifs?.filter(n => {
        return n.read == 0
      })
      setNotifications(filtered_notifs);

      if(filtered_notifs && filtered_notifs.length > 5) {
        setRecentNotifications(filtered_notifs.slice(0, 5));
      } else {
        setRecentNotifications(filtered_notifs);
      }
    });
  }, [])

  const changeLanguage = code => {
    i18n.changeLanguage(code);
    localStorage.setItem('language', code);
  };

  const markRead = (msg) => {
    const language = localStorage.getItem('language');
    let booking_car_types = []
    setNotification(msg)

    let filtered_notifs = notifications?.filter(n => {
      return n.id != msg.id
    })
    setNotifications(filtered_notifs);
    if(filtered_notifs && filtered_notifs.length > 5) {
      setRecentNotifications(filtered_notifs.slice(0, 5));
    } else {
      setRecentNotifications(filtered_notifs);
    }

    axios.get(API_URL + `/booking/${msg.booking_id}`)
    .then(response => {
      let booking = response.data.booking
      if(booking?.booking_items?.length > 0) {
        let package_id = booking?.booking_items[0].package_id
        if(package_id > 0) {
          if(booking?.packages?.length > 0) {
            let pack = booking?.packages?.filter(bp => {
              return bp.id == package_id
            })
            if(pack?.length > 0) {
              if(language == 'ar') {
                setBookingTitle(pack[0].name_ar)
              } else {
                setBookingTitle(pack[0].name)
              }
            }
          }
        }

        booking.booking_items.forEach(bi => {
          let car_type = booking?.car_types?.filter(ct => {
            return ct.id == bi.car_type_id
          })

          booking_car_types.push({
            type: car_type && car_type.length > 0 ? car_type[0].type : 'Small',
            image: car_type && car_type.length > 0 ? car_type[0].image : 'small.png',
            car_type_id: bi.car_type_id,
            quantity: bi.quantity,
            plate_number: bi.plate_number
          })
          setBookingCarTypes(booking_car_types)
        })
      }

      if(booking?.property_id > 0 && booking?.properties?.length > 0) {
        let property = booking?.properties?.filter(pr => {
          return pr.id == booking.property_i
        })

        if(property?.length > 0) {
          setBuilding(property[0])
          let address = language == 'ar' ? property[0].address_ar : property[0].address
          let mapLink = `https://www.google.com.sa/maps/search/${encodeURI(language)}`;
          let whatsappLink = 'https://api.whatsapp.com/send?text=' + 'Building Location: ' + address + ' ' + encodeURI(mapLink);
          let mailLink = `mailto:?Subject=Building Location&body=` + 'Building Location: ' + address + ' ' + encodeURI(mapLink);
          setBuildinglocation(mapLink);
          setWhatsapplink(whatsappLink);
          setMailto(mailLink);
        }
      }

      if(booking?.booking_schedules?.length > 0) {
        let shift_id = booking?.booking_schedules[0].booking_shift_id
        if(shift_id > 0) {
          if(booking?.shifts?.length > 0) {
            let shift = booking?.shifts?.filter(bp => {
              return bp.id == shift_id
            })
            if(shift?.length > 0) {
              setBookingShift(shift[0])
            }
          }
        }

        let scheduled_days_html = ''
	      let scheduled_days_array = []

        booking.booking_schedules.forEach(day => {
          if(scheduled_days_html == '') {
            scheduled_days_array.push({ date: day.booking_date })
            scheduled_days_html += `${moment(day.booking_date).format("MMM D")}`
          } else {
            if(scheduled_days_html.indexOf(moment(day.booking_date).format("MMM")) >= 0) {
              let match = false 
              if(scheduled_days_array && scheduled_days_array.length > 0) {
                match = scheduled_days_array.some(a => a.date === day.booking_date)
              }
              if(!match) {
                scheduled_days_array.push({ date: day.booking_date })
                scheduled_days_html += `, ${moment(day.booking_date).format("D")}`
              }
            } else {
              let match = false 
              if(scheduled_days_array && scheduled_days_array.length > 0) {
                match = scheduled_days_array.some(a => a.date === day.booking_date)
              }
              if(!match) {
                scheduled_days_array.push({ date: day.booking_date })
                scheduled_days_html += `, ${moment(day.booking_date).format("MMM D")}`
              }
            }
          }
        })

        setBookingSchedulesHtml(scheduled_days_html)
      }

      setBookingDetails(booking)

      let params = {
        id: msg.id,
        status: 1
      }
      axios.post(API_URL + `/notification/status/edit`, params)
      .then(res => {
          if(res.status == 200) {
            toast.success(t("users.successsave"), {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setOpen(true);
            setScroll('body');
          }
      })
    });
  }

  // notifications
  const seeAllNotifications = (mode) => {
    const userId = localStorage.getItem('userId');
    let params = {
      user_id: userId,
      notifications
    }

    if(notifications?.length > 0) {
      axios.post(API_URL + `/notifications/see-all`, params)
      .then(res => {
        if(res.status == 200) {
          if(mode == 'bellclicked') {
            setNotifications([])
          } else {
            window.open(
              `/notifications`,
              '_self'
            );
          }
        }
      })
    } else {
      window.open(
        `/notifications`,
        '_self'
      );
    }
  }

  const offset = ({ placement, popper }) => {
    if (placement === 'bottom') {
      return [0, 10]
    }

    return []
  }

  const handleClose = () => {
    setOpen(false);
  };

  const viewInvoice = (row) => {
    axios.get(API_URL + `/invoice/generate/${row.booking_id}`)
    .then(response => {
        setTimeout(function () {
            window.open(
                `${API_URL}/invoice/${row.transaction_no}.pdf`,
                '_blank'
            );
        }, 2000);
    })
  }

  return (
    <AppBar position='fixed' className={classes.appBar}>
      <div ref={inputRef} onClick={play}>CarCare</div>
      <Toolbar className={classes.toolbar}>
        <Grid
          justify="space-between"
          container 
          spacing={24}
        >
          <Grid item>
            <a href={homeUrl}>
              <img 
                  className={classes.logoImage} 
                  src={logoimage} 
                  alt="Carcare" />
              <img 
                  className={classes.logoText} 
                  src={logotext} 
                  alt="Carcare" />
            </a>
          </Grid>
          <Grid 
            item
            className={classes.rightMenu} 
          >
            <Hidden mdUp>
              <Navbar />
            </Hidden>
            <Hidden smDown>
              <img 
                className={classes.arabic} 
                src={bahrainimage} 
                alt="Arabic" 
                onClick={() => changeLanguage('ar')}
              />
              <img 
                className={classes.english} 
                src={usimage} 
                alt="English" 
                onClick={() => changeLanguage('en')}
              />
              <>
                <Popup
                  trigger={
                    <Badge 
                      style={{
                        marginRight: 30,
                        marginTop: -39,
                        marginLeft: 15
                      }}
                      badgeContent={notifications && notifications.length} 
                      color="primary"
                    >
                      <Icon size='large' name='bell' />
                    </Badge>
                  }
                  content='Has a half width offset'
                  offset={offset}
                  position='bottom center'
                  style={{
                    color: '#9F0000'
                  }}
                  wide
                  // open={modalOpen}
                  on='click'
                  // closeOnDimmerClick={false}
                  onClose={() => {
                    // setRecentNotifications(null)
                    // setModalOpen(false)
                    clearTimeout(timer.current)
                  }}
                  onOpen={() => {
                    seeAllNotifications('bellclicked')
                    setRecentNotifications(null)
                    // setModalOpen(true)

                    timer.current = setTimeout(() => {
                      setRecentNotifications(recentNotifications)
                    }, 100)
                  }}
                  popperDependencies={[!!data]}
                >
                  {recentNotifications === null ? (
                    <Placeholder style={{ minWidth: '200px' }}>
                      <Placeholder.Header>
                        <Placeholder.Line />
                        <Placeholder.Line />
                      </Placeholder.Header>
                      <Placeholder.Paragraph>
                        <Placeholder.Line length='medium' />
                        <Placeholder.Line length='short' />
                      </Placeholder.Paragraph>
                    </Placeholder>
                  ) : (
                    <>
                      {recentNotifications && recentNotifications.map((item, index) => (
                        <div
                          className={classes.notificationsContainer}
                        >
                          <img
                            className={classes.notificationImage}
                            src={API_URL + '/profile/image/' + (item.customer_image ? item.customer_image : 'default.png')} 
                          />
                          <div className={classes.notificationDesc}>
                            <a style={{ color: '#000' }} onClick={() => markRead(item)}>
                              { item.booking_date } [{ item.customer_first_name } { item.customer_last_name }] { item.action == 'new' ? t('notifications.new') : (item.action == 'edit' ? t('notifications.edit') : (item.action == 'cancel' ? t('notifications.cancel') : (item.action == 'paused' ? t('notifications.paused') : ''))) } {item.transaction_no}
                            </a>
                          </div>
                        </div>
                      ))}
                      <div
                        style={{
                          textAlign: 'center',
                          marginTop: 11
                        }}
                      >
                        <a 
                          style={{
                            color: '#9F0000'
                          }}
                          href="#"
                          onClick={() => seeAllNotifications('')}
                        >
                          {t('notifications.seeall')}
                        </a>
                      </div>
                    </>
                  )}
                </Popup>
                <a href="/profile">
                  <div className={classes.userImageContainer}>
                    <img 
                      className={classes.userImage} 
                      src={userAvatar}
                      alt="Carcare"
                    />
                  </div>
                  <div className={classes.nameContainer}>
                    {userName}
                  </div>
                </a>
              </>
            </Hidden>
            <Dialog
              open={open}
              onClose={handleClose}
              scroll={scroll}
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
              maxWidth = {'sm'}
            >
              <DialogTitle id="scroll-dialog-title">
                <div className={classes.titleContainer}>
                  <div className={classes.dialogTitle}>{bookingTitle}</div>
                  <div><CloseIcon className={classes.close} onClick={handleClose} /></div>
                </div>
              </DialogTitle>
              <DialogContent dividers={scroll === 'paper'}>
                <div className={classes.dialogContent}>
                  <div className={classes.dialogUserContainer}>
                    <div className={classes.dialogUser}>
                      <div>
                        <img
                          className={classes.dialogUserImage}
                          src={API_URL + '/profile/image/' + (bookingDetails?.customer?.image ? bookingDetails?.customer?.image : 'default.png')} 
                        />
                      </div>  
                      <div>
                        {bookingDetails?.customer?.first_name} {bookingDetails?.customer?.last_name}
                      </div>
                      <div>
                        {bookingDetails?.customer?.phone_number}
                      </div>
                    </div>
                    {bookingCarTypes && bookingCarTypes.map((cartype, index) => (
                      <div className={classes.dialogCar}>
                        <div>
                          <img
                            className={classes.dialogCarImage}
                            src={API_URL + '/car/type/image/' + cartype.image} 
                          />
                        </div>
                        <div>
                        {cartype.quantity}x {cartype.type}
                        </div>
                        <div>
                          {t('addbooking.plateno')} {cartype.plate_number}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className={classes.dialogRow}>
                    <div className={classes.dialogFlexContainer}>
                      <img
                        className={classes.modalIconImage}
                        src={washmodalimage} 
                      />
                      <div className={classes.dialogRightText}> 
                        {bookingTitle}
                      </div>
                    </div>
                  </div>
                  <div className={classes.dialogRow}>
                    <div className={classes.dialogFlexContainer}>
                      <div
                        style={{
                          width: 36,
                          height: 36,
                          borderRadius: 10,
                          backgroundColor: '#F7F7F7'
                        }}
                      >
                          <AttachMoneyIcon style={{
                          fontSize: 23,
                          color: '#9F0000',
                          marginLeft: 7,
                          marginTop: 6
                        }} />
                      </div>
                      <div className={classes.dialogRightText}> 
                        BD {bookingDetails.amount} {bookingDetails?.payment_method_id == 1 ? 'Cash' : (bookingDetails?.payment_method_id == 2 ? 'Card' : bookingDetails?.paid_option)} 
                        <div
                          style={{
                            width: '104%',
                            marginTop: 10
                          }}
                        ><b>{t('dashboard.referencenumber')}: </b>{bookingDetails?.transaction_no}</div>
                      </div>
                    </div>
                    <div style={{marginLeft:70}}>
                      <Button 
                        aria-label="cancel"
                        className={classes.cancelButton}
                        onClick={(e) => viewInvoice(notification)}
                      >
                        <ReceiptIcon className={classes.cancelIcon} />
                        {t('statement.viewinvoice')}
                      </Button>
                    </div>
                  </div>
                  <div className={classes.dialogRow}>
                    <div className={classes.dialogFlexContainer}>
                      <img
                        className={classes.modalIconImage}
                        src={pinmodalimage} 
                      />
                      <div className={classes.dialogRightText}> 
                        <>
                          <span>{language == 'ar' ? building?.name_ar : building?.name}, {t('dashboard.flat')} {bookingDetails?.flat_number}</span>
                          <div>{language == 'ar' ? building?.address_ar : building?.address}</div>
                          <div
                            style={{
                              marginTop: 10,
                              marginLeft: -5
                            }}
                          >
                            <a href={buildinglocation} target="_blank">
                              <MapIcon className={classes.mapIcon} />
                            </a>
                            <a href={whatsapplink} target="_blank">
                              <WhatsAppIcon className={classes.whatsappIcon} />
                            </a>
                            <a href={mailto}>
                              <EmailIcon className={classes.emailIcon} />
                            </a>
                          </div>
                        </>
                      </div>
                    </div>
                  </div>
                  <div className={classes.dialogRow}>
                    <div className={classes.dialogFlexContainer}>
                      <img
                        className={classes.modalIconImage}
                        src={calendarmodalimage} 
                      />
                      <div className={classes.dialogRightText}> 
                        <div className={classes.dialogFlexEndContainer}>
                          <div>{t('statement.scheduleddate')}</div>
                        </div>
                        {bookingDetails?.starting_date &&
                          <div
                            style={{
                              width: '104%',
                              marginTop: 10
                            }}
                          >
                            <b>{t('packages.startingdate')}: </b>{moment(bookingDetails?.starting_date).format('ddd MMM D')}{bookingShift?.id > 0 ? (', ' + (bookingShift.name + ' ' + moment(moment().format('YYYY-MM-DD') + ' ' + bookingShift.start_time).format('h A') + ' - ' + moment(moment().format('YYYY-MM-DD') + ' ' + bookingShift.end_time).format('h A'))) : ''}
                          </div>
                        }
                        {bookingSchedulesHtml &&
                          <div
                            style={{
                              width: '104%',
                              marginTop: 10
                            }}
                          >
                            {bookingSchedulesHtml}
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </DialogContent>
            </Dialog>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default LoggedInTopMenu;
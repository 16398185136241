import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { API_URL } from '../constants';
import { useHistory } from "react-router-dom";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';

const TapResponseSandbox = () => {
    const { t, i18n } = useTranslation();
    const search = useLocation().search;
    let [tapChargeId, setTapChargeId] = useState('');
    let [tapResponse, setTapResponse] = useState('');
    const history = useHistory();

    useEffect(() => {
        const language = localStorage.getItem('language');
        if(language) {
            i18n.changeLanguage(language);
        }
        
        const id = new URLSearchParams(search).get("tap_id");
        setTapChargeId(id);

        if(id) {
            getTapChargeDetails(id)
        }
    }, []);

    const getTapChargeDetails = (id) => {
        axios.get(API_URL + `/v2/tap/charge-sandbox/${id}`)
        .then(response => {
            console.log(response);
            let status = response?.data?.body?.status;
            setTapResponse(status);
            history.replace({ pathname: 'tap-responsesandbox', search: `?tap_id=${id}&status=${status}` });
        })
    }

    return (
        <div style={{
            display:'flex',
            flexDirection: 'column',
            alignItems:'center',
            justifyContent:'center',
            height:'100%',
            margin:'10%',
            padding:0
        }}>
            <div>SANDBOX MODE</div>
            {tapResponse &&
                <div style={{
                    marginTop: '15%',
                    marginBottom: 10,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center'
                }}>
                    <div>
                        {t('booking.status')}: 
                    </div>
                    <div style={{
                        fontWeight: 'bold',
                        marginLeft: 5,
                        fontSize: 24,
                    }}>
                        {tapResponse}
                    </div>
                </div>
            }
            {tapResponse == 'CAPTURED' &&
                <CheckCircleIcon 
                    style={{
                        color: '#22bb33',
                        fontSize: 60,
                    }}
                />
            }
            {(tapResponse && tapResponse != 'CAPTURED') &&
                <ReportProblemIcon 
                    style={{
                        color: '#f0ad4e',
                        fontSize: 60,
                    }}
                />
            }
            {tapResponse &&
                <div style={{
                    marginTop: 20,
                    fontSize: 16,
                }}>
                    {t('landing.redirect')}
                </div>
            }
        </div>
    );
};
  
export default TapResponseSandbox;
import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useHistory, useParams } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { Grid } from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';
import { API_URL } from '../constants';
import { useTranslation } from 'react-i18next';
import LoggedInTopMenu from '../components/LoggedInTopMenu';
import SideMenu from '../components/SideMenu';
import Hidden from '@material-ui/core/Hidden';
import arrowrightwhiteimage from '../images/arrow-right-white.png';
import axios from 'axios';
import CloseIcon from '@material-ui/icons/Close';
import Select from 'react-select';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles(theme => ({
    content: {
      paddingTop: 130,
      marginLeft: '-6%'
    },
    sideMenu: {
      backgroundColor: '#9F0000',
      width: 256,
      height: `calc(100vh - 130px)`,
      borderTopRightRadius: 75,
      color: '#fff',
      paddingTop: 70
    },
    mainContent: {
      width: `calc(100% - 256px)`,
      textAlign: 'left',
      ['@media (max-width:959px)']: { 
        width: '95%'
      }
    },
    article: {
      paddingTop: 20,
      paddingLeft: 50,
      ['@media (max-width:959px)']: { 
        paddingLeft: 0
      }
    },
    center: {
        position: 'absolute',
        top: '50%',
        left: '55%',
        width: 580,
        transform: 'translateX(-50%) translateY(-50%)',
        ['@media (max-width:959px)']: { 
            top: '50%',
            left: '0%',
            position: 'initial',
            transform: 'none',
            width: '100%'
        }
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        ['@media (max-width:959px)']: { 
            width: '90%',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        paddingBottom: 25
    },
    close: {
        fontSize: 24,
        "&:hover": {
            cursor: "pointer"
        }
    },
    fieldsContainer: {
        paddingTop: 35,
        width: 580,
        marginLeft: 'auto',
        marginRight: 'auto',
        ['@media (max-width:959px)']: { 
          width: '90%'
        }
    },
    inputContainer: {
        width: '100%',
        height: 72
    },
    inputContainerDesc: {
        width: '100%',
        marginTop: 15,
    },
    inputFlexContainer: {
        height: 72,
        display: 'flex',
        justifyContent: 'space-between',
        ['@media (max-width:959px)']: { 
            width: '100%',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    },
    input: {
        width: '100%',
        fontSize: 16
    },
    flexLastInput: {
        width: '100%',
        fontSize: 16,
        marginLeft: 20
    },
    saveContainer: {
        paddingTop: 16,
        float: 'right',
        marginTop: 65,
        ['@media (max-width:959px)']: { 
            marginRight: '3%',
            marginTop: '25%'
        }
    },
    saveText: {
        color: '#9F0000',
        fontSize: 16,
        fontWeight: 500,
        display: 'inline-block',
        position: 'absolute',
        marginTop: 7,
        marginLeft: -45,
        width: 44
    },
    saveBtnContainer: {
        width: 36,
        height: 36,
        borderRadius: 12,
        backgroundColor: '#9F0000',
        display: 'inline-block',
        "&:hover": {
            cursor: 'pointer'
        },
    },
    saveBtn: {
        width: 21,
        height: 21,
        position: 'absolute',
        marginTop: 6,
        marginLeft: 8
    },
    flexInput: {
        fontSize: 16,
        width: '25%',
        paddingRight: 20
    },
    flexInputLast: {
        fontSize: 16,
        width: '25%'
    },
    select: {
        width: '100%',
        fontFamily: 'Nunito',
        ['@media (max-width:959px)']: { 
            width: 310,
            marginBottom: 20
        }
    },
    serviceLabel: {
        paddingBottom: 20,
        fontSize: 16,
        fontFamily: 'Nunito',
        ['@media (max-width:959px)']: { 
            paddingTop: 65
        }
    },
    flexContainer: {
        display: 'flex',
        ['@media (max-width:959px)']: { 
            display: 'block'
        }
    },
    halfDiv: {
        width: '50%',
        ['@media (max-width:959px)']: { 
            width: '100%'
        }
    },
    deleteContainer: {
        marginTop: 90,
        float: 'left',
        color: '#9F0000',
        "&:hover": {
            cursor: 'pointer'
        },
        ['@media (max-width:959px)']: { 
            marginTop: '22%',
            marginLeft: 15
        }
    },
}));

const EditAssignedStaff = () => {
    const classes = useStyles();
    const userId = localStorage.getItem('userId');
    const history = useHistory();
    const params = useParams();
    const roleId = localStorage.getItem('roleId');
    const { t, i18n } = useTranslation();
    let [language, setLanguage] = useState('');
    let [mode, setMode] = useState('');
    let [formSubmitted, setFormSubmitted] = useState(false);
    let [staffs, setStaffs] = useState([]);
    let [selectedStaff, setSelectedStaff] = useState(null);
    let [selectedStaffObject, setSelectedStaffObject] = useState(null);
    let [booking, setBooking] = useState(null);

    useEffect(() => {
        const language = localStorage.getItem('language');
        if(language) {
            i18n.changeLanguage(language);
        }
        setLanguage(language);

        initializeData();
        setMode(params.id > 0 ? 'edit' : 'add');
    }, [])

    if (!userId) {
        return <Redirect to={'/'} />;
    }

    const initializeData = () => {
        if(params.id > 0) {
            axios.get(API_URL + `/booking/${params.id}`)
            .then(response3 => {
                let bkng = response3.data.booking;
                if(bkng) {
                    setBooking(bkng);

                    axios.get(API_URL + `/building-staffs/${bkng.property_id}`)
                    .then(response => {
                        let staffs = response.data.staffs;
                        staffs = formatSelectData(staffs, 'staffs');
                        setSelectedStaff(bkng.assigned_staff_id);
                        if(bkng.assigned_staff_id) { setSelectedStaffObject(valueFromId(staffs, bkng.assigned_staff_id)); }
                    })
                }
            })
        }
    }

    const valueFromId = (opts, id) => opts.find(o => o.value === id);

    const formatSelectData = (array, mode) => {
        let new_array = []
        if(array.length > 0) {
            array.forEach(item => {
                new_array.push({
                    value: item.id,
                    label: item.first_name + ' ' + item.last_name
                })
            })
        }

        if(mode == 'staffs') { setStaffs(new_array); }

        return new_array;
    }

    const handleStaffChange = (data) => {
        setSelectedStaffObject(data);
        setSelectedStaff(data.value);
    };

    function handleClick(e) {
        setFormSubmitted(true)
        if(!selectedStaff) {
            showError(t('promo-codes.fillup'))
        } else {
            editAssignedStaff();
        }
    }

    function showError(message) {
        toast.error(message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function editAssignedStaff() {
        axios.post(API_URL + `/assigned-staff/edit/${params.id}`, {
            assigned_staff_id: selectedStaff,
        })
        .then(res => {
            if(res.status == 200) {
                toast.success(t("users.successsave"), {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setTimeout(function () {
                    history.push("/bookings");
                }, 2000);
            }
        })
    }

    function handleClose(e) {
        history.push("/bookings")
    }

    function handleDelete(e) {
        if(params.id) {
            axios.post(API_URL + `/notification-message/delete/${params.id}`, {})
            .then(res => {
                if(res.status == 200) {
                    toast.success(t("users.successdelete"), {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setTimeout(function () {
                        history.push("/notificationmessages");
                    }, 2000);
                }
            })
        } else {
            toast.error(t('promo-codes.deleteerror'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    return (
        <div className="inner">
            <LoggedInTopMenu />
            <div className={classes.content}>
                <Grid
                    justify="space-between"
                    container
                >
                    <Hidden smDown>
                        <div className={classes.sideMenu}>
                            <SideMenu />
                        </div>
                    </Hidden>
                    <ToastContainer />
                    <div className={classes.mainContent}>
                        <div className={classes.article}>
                            <div className={classes.fieldsContainer}>
                                <div className={classes.header}>
                                    <div className={classes.title}>{t('dashboard.edit')} {t('booking.assignedstaff')}</div>
                                    <div><CloseIcon className={classes.close} onClick={handleClose} /></div>
                                </div>
                                <div className={classes.fieldsContainer}>
                                    <form>
                                        <div>
                                            <Select
                                                placeholder={t('booking.assignedstaff')}
                                                value={selectedStaffObject}
                                                onChange={handleStaffChange}
                                                options={staffs}
                                                name="staff"
                                                required
                                            />
                                        </div>
                                    </form>
                                </div>
                                <div>
                                    <div className={classes.saveContainer}>
                                        <div className={classes.saveText}>{t('promo-codes.save')}</div>
                                        <div 
                                            onClick={handleClick}
                                            className={classes.saveBtnContainer} 
                                        >
                                            <img 
                                                className={classes.saveBtn} 
                                                src={arrowrightwhiteimage} 
                                                alt="Carcare" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Grid>
            </div>
        </div>
    );
};
  
export default EditAssignedStaff;